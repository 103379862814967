import React from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import BodyView from './Components/BodyView';
import MenuBar from './Components/MenuBar';
import LeftSidebar from './Components/LeftSidebar';

import { getAllowedRoutes } from './Utils/index';

import Login from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import BillPayments from './Pages/BillPayments/index';
import Reports from './Pages/Reports';
import Billers from './Pages/Billers';
import Users from './Pages/Users';
import Commissions from './Pages/Commissions';
import Complaints from './Pages/Complaints';
import Settings from './Pages/Settings';
import PWA from './Pages/BillPayments/pwa';
import { MENU_SLUG } from './Constants/constants';
import { ROLES } from './Constants/roles';

import { BillerProvider } from './Contexts/billerCategories';
import RequireAuth from './Auth/RequireAuth';
import PersistLogin from './Auth/PersistLogin';
import PwaAuth from './Auth/PwaAuth';
import { useAuth } from './Hooks/useAuth';
import { CheckPermissions } from './Utils/permissions';
import history from './Utils/history';

import './App.css';

const App = () => {

  const { auth } = useAuth();

  const pages = [
    {
      name: 'dashboard',
      title: 'Dashboard',
      link: `/${MENU_SLUG.dashboard}`,
      path: `/${MENU_SLUG.dashboard}`,
      component: Dashboard,
      show_tab: CheckPermissions('dashboard_permissions', 'View'),
    },
    {
      name: 'bill-payments',
      title: 'Bill Payments',
      link: `/${MENU_SLUG.bill_payments}`,
      path: `/${MENU_SLUG.bill_payments}/*`,
      component: BillPayments,
      show_tab: CheckPermissions('bill_payment_permissions', 'View'),
    },
    {
      name: 'reports',
      title: 'Reports',
      link: CheckPermissions('reports_permissions', 'Transaction') ? `/${MENU_SLUG.reports}` : `/${MENU_SLUG.reports}/commission`,
      path: `/${MENU_SLUG.reports}/*`,
      component: Reports,
      show_tab: CheckPermissions('reports_permissions', 'Transaction') || CheckPermissions('reports_permissions', 'Commission'),
      children: [
        {
          name: 'transaction',
          title: 'Transaction',
          link: `/${MENU_SLUG.reports}`,
          show_tab: CheckPermissions('reports_permissions', 'Transaction'),
        },
        {
          name: 'commission',
          title: 'Commission',
          link: `/${MENU_SLUG.reports}/commission`,
          show_tab: CheckPermissions('reports_permissions', 'Commission'),
        },
        // {
        //   name: 'upi',
        //   title: 'UPI',
        //   link: `/${MENU_SLUG.reports}/upi`,
        //   show_tab: CheckPermissions('reports_permissions', 'UPI'),
        // },
        {
          name: 'icici',
          title: 'ICICI',
          link: `/${MENU_SLUG.reports}/icici`,
          show_tab: CheckPermissions('reports_permissions', 'ICICI'),
        },
      ]
    },
    {
      name: 'billers',
      title: 'Billers',
      link: `/${MENU_SLUG.billers}`,
      path: `/${MENU_SLUG.billers}/*`,
      component: Billers,
      show_tab: CheckPermissions('billers_permissions', 'View'),
    },
    {
      name: 'users',
      title: 'Users',
      link: `/${MENU_SLUG.users}`,
      path: `/${MENU_SLUG.users}/*`,
      component: Users,
      show_tab: CheckPermissions('users_permissions', 'View'),
    },
    {
      name: 'commissions',
      title: 'Commissions',
      link: `/${MENU_SLUG.commissions}`,
      path: `/${MENU_SLUG.commissions}/*`,
      component: Commissions,
      show_tab: CheckPermissions('commissions_permissions', 'View'),
    },
    {
      name: 'complaints',
      title: 'Complaints',
      link: `/${MENU_SLUG.complaints}`,
      path: `/${MENU_SLUG.complaints}/*`,
      component: Complaints,
      show_tab: CheckPermissions('complaints_permissions', 'View'),
    },
    {
      name: 'settings',
      title: 'Settings',
      link: `/${MENU_SLUG.settings}`,
      path: `/${MENU_SLUG.settings}/*`,
      component: Settings,
      show_tab: true,
    }];

  const allowedRoutes = getAllowedRoutes(pages);

  const lastHistory = history.location.pathname;

  return (
    <Routes>
      <Route element={<PwaAuth />}>
        <Route exact path={'/pwa/*'} element={<BillerProvider><PWA /></BillerProvider>} />
      </Route>

      <Route element={<PersistLogin />}>

        <Route exact path={'/'} element={
          auth && auth.token && auth.role[0] !== 'PWA' ? <Navigate to={lastHistory === '/' ? '/dashboard' : lastHistory} replace /> :
            <Box component="div" className={"BBPApp BBPLP"}>
              <Box component="div" className={"BBPAInner"}>
                <BodyView>
                  <Login />
                </BodyView>
              </Box>
            </Box>
        } />

        <Route element={<RequireAuth allowedRoles={[ROLES.admin, ROLES.subUser]} />}>

          <Route path={'/'} element={
            <BillerProvider>
              <Box component="div" className={"BBPApp"}>
                <Box component="div" className={"BBPAInner"}>
                  <MenuBar />
                  <BodyView>
                    <LeftSidebar pages={allowedRoutes} />
                    <Outlet />
                  </BodyView>
                </Box>
              </Box>
            </BillerProvider>
          }>

            {// eslint-disable-next-line array-callback-return
              allowedRoutes.map((route) => {
                if (route.show_tab) {
                  const { path, component: Component, children, title, permission, ...rest } = route;
                  return (
                    <Route {...rest} key={path} path={`${path}`} element={<Component />} />
                  )
                }
              })}
          </Route>

        </Route>

      </Route>
      <Route path='*' element={
        auth && auth.token ? <Navigate to="/dashboard" replace /> : <Navigate to="/" replace />
      } />
    </Routes>
  );
}

export default App;