import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useAxiosPrivate } from '../../../Hooks/useAxiosPrivate';
import { customizeValidator } from "@rjsf/validator-ajv6";
import Form from "@rjsf/core";
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import BillDetails from './billDetails';
import PaymentPage from './paymentPage';
import { fetchIp, inputValueString } from '../../../Utils/index';
import NoData from '../../../Components/NoData';
import STWWImg from '../../../Assets/Images/stww.png';
import SomethingWentWrong from '../../../Components/SomethingWentWrong';
import PoweredBy from '../../../Components/PoweredBy';
import BhartBillPay from '../../../Assets/Images/bhartbillpay.png';
import MonthYear from '../../../Components/FormFields/MonthYear';
import YearField from '../../../Components/FormFields/YearField';
import CustomDropdown from '../../../Components/FormFields/CustomDropdown';

import './style.css';

const ConsumerBill = () => {

    const axiosPrivate = useAxiosPrivate();
    let { provider_id } = useParams();
    let navigate = useNavigate();
    let location = useLocation();

    const mobile_number = location.state && location.state.mobile_number;
    const homeLink = location.state && location.state.homeLink;
    const [pageView, setPageView] = useState(false);
    const [loading, setLoading] = useState(true);
    const [billDetailLoading, setBillDetailLoading] = useState(false);
    const [billDetail, setBillDetail] = useState({});
    const [errorDialog, setErrorDialog] = useState(false);
    const [providerDetails, setProviderDetails] = useState({});
    const [formSchema, setFormSchema] = useState({});
    const [formData, setFormData] = useState({});
    const [mobileNumber, setMobileNumber] = useState('');
    const [fetchErrMsg, setFetchErrMsg] = useState('');
    const [liveValidErr, setLiveValidErr] = useState(false);

    const [uiSchema, setUiSchema] = useState({
        "ui:submitButtonOptions": {
            "props": {
                "className": "BBPFBtn"
            }
        },
        "mobile_num": mobile_number ? { "ui:widget": "hidden" } : {},
    });

    const [customFormats] = useState({
        'Number': /^[0-9]+$/,
        "NUMERIC": /^[0-9]+$/,
    });

    let widgets = {
        monthView: MonthYear,
        yearView: YearField,
        customDropdown: CustomDropdown,
    }

    let yourForm;
    const onSubmitNew = () => {
        yourForm.formElement.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    };

    const onFormSubmit = (formData) => {
        let tempData = { ...formData };
        setFormData(formData);
        setFetchErrMsg('');
        setLiveValidErr(false);
        if (formData.mobile_num) {
            setMobileNumber(formData.mobile_num);
        }
        delete tempData.mobile_num;
        handleFetchBillDetails(tempData, provider_id);
    };

    const onFormChange = (formData) => {
        setFormData(formData);
        setBillDetail({});
    };

    const onError = () => {
        setLiveValidErr(true);
    };

    const handlePageView = useCallback(() => {
        setPageView(true);
    }, []);

    const handleParams = useCallback(async (id, api_provider) => {
        try {
            setLoading(true);
            setBillDetailLoading(true);
            let url = `bbps/GetCustomerParamsByBillerId/${id}`;
            let options = {
                method: 'GET',
                url
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    let tempUiSchema = { ...uiSchema };
                    let tempSchema = {
                        "type": "object",
                        "required": ['mobile_num'],
                        "properties": {
                            "mobile_num": {
                                "title": "Mobile Number For DB",
                                "type": "string",
                                "minLength": 10,
                                "maxLength": 10,
                                "default": mobile_number ? mobile_number : '',
                                "pattern": '^[0-9]+$'
                            },
                        }
                    };
                    // eslint-disable-next-line
                    response.data.data.map((item) => {
                        tempSchema.required.push(item.IsMandatory ? item.name : '');
                        if (api_provider === 'Finacus') {
                            return (
                                tempSchema.properties[item.name] = {
                                    "title": item.name,
                                    "type": "string",
                                    "format": item.FieldType,
                                    "minLength": item.MinLength,
                                    "maxLength": item.MaxLength,
                                    "pattern": item.Regex
                                }
                            )
                        }
                        if (api_provider === 'Mobikwik') {
                            if (item.FieldType === 'monthYear') {
                                tempUiSchema[item.name] = {
                                    "ui:widget": "monthView",
                                    "ui:options": {
                                        dateformat: item.format,
                                    }
                                };
                                setUiSchema(tempUiSchema);
                                return (
                                    tempSchema.properties[item.name] = {
                                        "title": item.title,
                                        "type": "string",
                                        "minLength": item.MinLength,
                                        "maxLength": item.MaxLength,
                                        "pattern": item.Regex
                                    }
                                );
                            } else if (item.FieldType === 'year') {
                                tempUiSchema[item.name] = {
                                    "ui:widget": "yearView",
                                    "ui:options": {
                                        yearFormat: item.format,
                                    }
                                };
                                setUiSchema(tempUiSchema);
                                return (
                                    tempSchema.properties[item.name] = {
                                        "title": item.title,
                                        "type": "string",
                                        "minLength": item.MinLength,
                                        "maxLength": item.MaxLength,
                                        "pattern": item.Regex
                                    }
                                );
                            } else if (item.FieldType === 'dropdown') {
                                tempUiSchema[item.name] = {
                                    "ui:widget": "customDropdown",
                                    "ui:options": {
                                        dropdownOptions: item.options,
                                    }
                                };
                                setUiSchema(tempUiSchema);
                                return (
                                    tempSchema.properties[item.name] = {
                                        "title": item.title,
                                        "type": "string",
                                        "minLength": item.MinLength,
                                        "maxLength": item.MaxLength,
                                        "pattern": item.Regex
                                    }
                                );
                            } else {
                                if (item.FieldType === 'string') {
                                    return (
                                        tempSchema.properties[item.name] = {
                                            "title": item.title,
                                            "type": "string",
                                            "minLength": item.MinLength,
                                            "maxLength": item.MaxLength,
                                            "pattern": item.Regex
                                        }
                                    )
                                } else {
                                    return (
                                        tempSchema.properties[item.name] = {
                                            "title": item.title,
                                            "type": "string",
                                            "format": item.FieldType,
                                            "minLength": item.MinLength,
                                            "maxLength": item.MaxLength,
                                            "pattern": item.Regex
                                        }
                                    )
                                }
                            }

                        }
                    });
                    setFormSchema(tempSchema);
                    setBillDetailLoading(false);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setBillDetailLoading(false);
                }
            }).catch(err => {
                if (err.response) {
                    setLoading(false);
                    setBillDetailLoading(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setLoading(false);
            setBillDetailLoading(false);
            setErrorDialog(true);
            // console.error('error', error);
        }
        // eslint-disable-next-line
    }, [mobile_number]);

    function transformErrors(errors) {
        return errors.map(error => {
            let property;
            let matcheSB = (/\[(.*?)\]/).test(error.property);
            let matcheDot = /\./.test(error.property);
            if (matcheDot) {
                property = error.property.replaceAll('.', '');
            }
            if (matcheSB) {
                // eslint-disable-next-line
                property = error.property.replace(/[\[\]']+/g, '');
            }
            if (error.name === 'minLength') {
                error.message = `‘${formSchema.properties[property].title}’ should NOT be shorter than 10 characters.`;
            } else if (error.name === 'maxLength') {
                error.message = `‘${formSchema.properties[property].title}’ should NOT be longer than 10 characters.`;
            } else if (error.name === 'pattern') {
                error.message = `‘${formSchema.properties[property].title}’ is not valid.`;
            } else {
                error.message = `‘${formSchema.properties[property].title}’ is mandatory.`;
            }
            return error;
        });
    }

    const validator = customizeValidator({ customFormats });

    const fetchBillerValue = useCallback(async () => {
        try {
            setBillDetailLoading(true);
            let url = `billers/provider/${provider_id}`;
            let options = {
                method: 'GET',
                url
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setProviderDetails(response.data.data);
                    handleParams(provider_id, response.data.data.api_provider);
                } else {
                    setLoading(false);
                    setBillDetailLoading(false);
                }
            }).catch(err => {
                if (err.response) {
                    setLoading(false);
                    setBillDetailLoading(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data)
                }
            });
        } catch (error) {
            setLoading(false);
            setBillDetailLoading(false);
            setErrorDialog(true);
            // console.error('error', error)
        }
        // eslint-disable-next-line
    }, [handleParams, provider_id]);

    const handleFetchBillDetails = useCallback(async (params, id) => {
        try {
            setBillDetailLoading(true);
            let data;
            if (providerDetails.api_provider === 'Finacus') {
                let custPar = inputValueString(params);
                let ip = await fetchIp();
                data = JSON.stringify({
                    "mobileNumber": `${process.env.REACT_APP_BBPS_MOBILENUMBER}`,
                    "customerParams": `${custPar}`,
                    "ip": `${ip}`
                });
            }
            if (providerDetails.api_provider === 'Mobikwik') {
                data = params
            }
            let url = `bbps/SendBillFetchRequest/${id}`;
            let options = {
                method: 'POST',
                url,
                data,
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    if (providerDetails.api_provider === 'Finacus') {
                        setBillDetail(response.data.data);
                    }
                    if (providerDetails.api_provider === 'Mobikwik') {
                        setBillDetail(response.data.data.data[0]);
                    }
                } else {
                    setFetchErrMsg(response.data.message);
                }
                setBillDetailLoading(false);
            }).catch(err => {
                if (err.response) {
                    setBillDetailLoading(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data)
                }
            });
        } catch (error) {
            setBillDetailLoading(false);
            setErrorDialog(true);
            // console.error('error', error);
        }
        // eslint-disable-next-line
    }, [providerDetails.biller_id]);

    const handleBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    useEffect(() => {
        fetchBillerValue();
        // eslint-disable-next-line
    }, []);


    return (
        <Fragment>
            <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
            {pageView ?
                <PaymentPage
                    billDetail={billDetail}
                    providerDetails={providerDetails}
                    mobileNumber={mobileNumber}
                    homeLink={homeLink}
                    formData={formData}
                />
                :
                <Box component="div" className={'BBPBPConsumerBill'}>
                    {Object.keys(providerDetails).length > 0 &&
                        <Box component="div" className={'BBPBPCBIInfo'}>
                            <Box component="div" className={'BBPBPCBIIIcon'}>
                                {providerDetails.image &&
                                    <img src={`${providerDetails.image.location}`} alt={providerDetails.name} />}
                            </Box>
                            <Box component="div" className={'BBPBPCBIITitle'}>
                                {providerDetails.name}
                            </Box>
                            <Box component="div" className={'BBPBPCBIILogo'}>
                                <img src={BhartBillPay} alt={'Bharat Bill Payment'} />
                            </Box>
                        </Box>}
                    <Box component="div" className={'BBPBPCBIView'}>
                        {loading ?
                            <Box component="div" className={'BBPBPCBIVFields'}>
                                {Array.from(Array(4).keys()).map((item) => (
                                    <Box component="div" key={item} className={'BBPBPCBIVField'}>
                                        <Stack spacing={2}>
                                            <Skeleton variant="rectangular" height={32} />
                                            <Skeleton variant="rectangular" height={68} />
                                        </Stack>
                                    </Box>
                                ))}
                            </Box>
                            :
                            <Fragment>
                                {formSchema && formSchema.properties ?
                                    <Box component="div" className={'BBPBPCBIVFields'}>
                                        <Box component='div' className={'BBPForm'}>
                                            <Form
                                                schema={formSchema}
                                                uiSchema={uiSchema}
                                                formData={formData}
                                                validator={validator}
                                                liveValidate={liveValidErr}
                                                showErrorList={false}
                                                omitExtraData={true}
                                                widgets={widgets}
                                                transformErrors={transformErrors}
                                                onSubmit={({ formData }) => {
                                                    onFormSubmit(formData);
                                                }}
                                                onChange={({ formData }) => {
                                                    onFormChange(formData);
                                                }}
                                                onError={onError}
                                                ref={(form) => { yourForm = form; }}
                                            />
                                        </Box>
                                    </Box>
                                    :
                                    <NoData
                                        image={STWWImg}
                                        title={'Something went wrong'}
                                        description={'Reference site about Lorem Ipsum, giving information on its origins, as well as a random Ipsum generator.'}
                                    />
                                }
                            </Fragment>
                        }
                        <BillDetails
                            providerDetails={providerDetails}
                            loading={billDetailLoading}
                            billDetail={billDetail}
                        />
                    </Box>
                    {fetchErrMsg &&
                        <Box component="div" className={'BBPBPCBIErr'}>
                            {fetchErrMsg}
                        </Box>}
                    <PoweredBy isFinacus={true} isMobiKwik={true} />
                    <Box component="div" className={'BBPBPCBIBtn'}>
                        <Button variant="contained" className={'BBPButton'} disabled={billDetailLoading} onClick={handleBack}>Back</Button>
                        <Fragment>

                            {Object.keys(billDetail).length > 0 ?
                                Number(billDetail.billAmount || billDetail.BillAmount) > 0 ?
                                    <Button variant="contained" className={'BBPButton'} onClick={handlePageView}>Pay Bill</Button> : ''
                                :
                                <Button variant="contained" className={'BBPButton'} disabled={billDetailLoading} onClick={onSubmitNew}>Fetch Bill</Button>
                            }
                        </Fragment>
                    </Box>
                </Box>
            }
        </Fragment>
    );
};

export default ConsumerBill;
