import React, { Fragment, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const progressEnd = parseInt(5) * 60 + parseInt(0);
const degTravel = 360 / progressEnd;

let secRem = '00';
let minRem = '00';

const PaymentProgress = ({ handleCancelPayment, paymentProgress, checkPayStatus, progressTime, setProgressTime, setPaymentSuccess, handleViewPayStatus, transactionId }) => {

    const [progressDeg, setProgressDeg] = useState(0);

    useEffect(() => {
        if (paymentProgress) {
            let timer = setTimeout(() => {
                setProgressDeg(progressTime * degTravel);
                setProgressTime((time) => time + 1);
            }, 1000);

            if (progressDeg % 12 === 0) {
                checkPayStatus();
            }

            if (progressDeg === 360) {
                setPaymentSuccess(true);
                handleViewPayStatus(transactionId);
                clearTimeout(timer);
            }

            secRem = Math.floor((progressEnd - progressTime) % 60);
            minRem = Math.floor((progressEnd - progressTime) / 60);
        }
        // eslint-disable-next-line
    }, [paymentProgress, progressDeg, progressTime]);

    useEffect(() => {
        if (paymentProgress) {
            let pwaBtns = document.querySelector('.BBPBPPTILPWA .BBPBPPTVBtmNav');
            if (pwaBtns) {
                pwaBtns.classList.add("BBPBPPTILPWADisable");
            }
        }
    }, [paymentProgress]);

    return (
        <Fragment>
            <Box component="div" className={'BBPBPPPTPBU'}>
                <Box component="div" className={'BBPBPPPTPBUPP'}>
                    <Box component="div" className={'BBPBPPPTPBUPPInfo'}>
                        <Box component="div" className={'BBPBPPPTPBUPPIClock'}>
                            <Box component="div" className={'CircleProgress'}>
                                <Box component="div" className={'CPInner'} style={{ background: `conic-gradient(rgb(255 255 255) ${progressDeg}deg, rgb(0, 170, 81) ${progressDeg}deg)` }}>
                                    <Box component="div" className={'CPITimer'}>
                                        {minRem.toString().length === 2 ? minRem : `0${minRem}`} : {secRem.toString().length === 2 ? secRem : `0${secRem}`} <br /> mins remaining
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box component="div" className={'BBPBPPPTPBUPPITitle'}>
                            Open your UPI app to approve the<br /> payment request before the timer runs out.
                        </Box>
                    </Box>
                    <Box component="div" className={'BBPBPPPTPBUPPFooter'}>
                        <Box component="div" className={'BBPBPPPTPBUPPFText'}>
                            Note: Do not hit back button or close this tab until the transaction is completed.
                        </Box>
                        <Box component="div" className={'BBPBPPPTPBUPPFBtn'}>
                            <Button variant="text" onClick={handleCancelPayment}>Cancel Payment</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Fragment>
    );
};
export default PaymentProgress; 