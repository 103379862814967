import React, { Fragment } from 'react';
import { format } from 'date-fns'
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ClearIcon from '@mui/icons-material/Clear';
import useMediaQuery from '@mui/material/useMediaQuery';
import './style.css';

const ViewComplaint = ({ open, onClose, info }) => {

    const matches = useMediaQuery('(max-width:767px)');

    return (
        <Dialog
            fullWidth={true}
            fullScreen={matches ? true : false}
            maxWidth={'sm'}
            open={open}
            onClose={onClose}
        >
            <Box component="div" className={'BBPRCDialog'}>
                <Box component="div" className={'BBPRCDHead'}>
                    <Box component="div" className={'BBPRCDHTitle'}>
                        Complaint Details
                    </Box>
                    <IconButton onClick={onClose}>
                        <ClearIcon />
                    </IconButton>
                </Box>
                <Box component="div" className={'BBPRCDView'}>
                    {info.status &&
                        <Box component="div" className={`BBPRCDVStatus ${info.status.replaceAll(/\s/g, '')}`}>
                            {info.status}
                        </Box>
                    }
                    <Box component="div" className={'BBPRCDVRow'}>
                        <Box component="div" className={'BBPRCDVRCol'}>
                            <Box component="div" className={'BBPRCDVRCTitle'}>
                                Date
                            </Box>
                            <Box component="div" className={'BBPRCDVRCSTitle'}>
                                {info.date ? format(new Date(info.date), "dd-MM-yyyy") : '-'}
                            </Box>
                        </Box>
                        <Box component="div" className={'BBPRCDVRCol'}>
                            <Box component="div" className={'BBPRCDVRCTitle'}>
                                Mobile Number
                            </Box>
                            <Box component="div" className={'BBPRCDVRCSTitle'}>
                                {info.mobile_num ? info.mobile_num : '-'}
                            </Box>
                        </Box>
                        <Box component="div" className={'BBPRCDVRCol'}>
                            <Box component="div" className={'BBPRCDVRCTitle'}>
                                Transaction Provider
                            </Box>
                            <Box component="div" className={'BBPRCDVRCSTitle'}>
                                {info.transaction_provider ? info.transaction_provider : '-'}
                            </Box>
                        </Box>
                        <Box component="div" className={'BBPRCDVRCol BBPRCDVRCFull'}>
                            <Box component="div" className={'BBPRCDVRCTitle'}>
                                Reason
                            </Box>
                            <Box component="div" className={'BBPRCDVRCSTitle'}>
                                {info.reason ? info.reason : '-'}
                            </Box>
                        </Box> <Box component="div" className={'BBPRCDVRCol BBPRCDVRCFull'}>
                            <Box component="div" className={'BBPRCDVRCTitle'}>
                                Description
                            </Box>
                            <Box component="div" className={'BBPRCDVRCSTitle'}>
                                {info.description ? info.description : '-'}
                            </Box>
                        </Box>
                        <Box component="div" className={'BBPRCDVRCol BBPRCDVRCFull'}>
                            <Box component="div" className={'BBPRCDVRCTitle'}>
                                Feedback
                            </Box>
                            <Box component="div" className={'BBPRCDVRCSTitle'}>
                                {info.feedback ? info.feedback : '-'}
                            </Box>
                        </Box>
                    </Box>
                    {info.transaction_provider === 'Finacus' ?
                        <Fragment>
                            <Box component="div" className={'BBPRCDVTTitle'}>
                                Finacus Details
                            </Box>
                            <Box component="div" className={'BBPRCDVRow'}>
                                <Box component="div" className={'BBPRCDVRCol'}>
                                    <Box component="div" className={'BBPRCDVRCTitle'}>
                                        Complaint Status
                                    </Box>
                                    <Box component="div" className={'BBPRCDVRCSTitle BBPRCDVRCFS'}>
                                        {info.hasOwnProperty('finacus_data') && info.finacus_data.ComplaintStatus ? <Box component="div"  >{info.finacus_data.ComplaintStatus}</Box> : '-'}
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPRCDVRCol'}>
                                    <Box component="div" className={'BBPRCDVRCTitle'}>
                                        Remarks
                                    </Box>
                                    <Box component="div" className={'BBPRCDVRCSTitle'}>
                                        {info.hasOwnProperty('finacus_data') && info.finacus_data.Remarks ? info.finacus_data.Remarks : '-'}
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPRCDVRCol BBPRCDVRCFull'}>
                                    <Box component="div" className={'BBPRCDVRCTitle'}>
                                        Description
                                    </Box>
                                    <Box component="div" className={'BBPRCDVRCSTitle'}>
                                        {info.hasOwnProperty('finacus_data') && info.finacus_data.Description ? info.finacus_data.Description : '-'}
                                    </Box>
                                </Box>
                            </Box>
                        </Fragment> : ''}
                    <Box component="div" className={'BBPRCDVTTitle'}>
                        Transaction Details
                    </Box>
                    {info.trans &&
                        <Fragment>
                            {info.trans.status &&
                                <Box component="div" className={'BBPRCDVTStatus ' + info.trans.status}>
                                    {info.trans.status}
                                </Box>
                            }
                            <Box component="div" className={'BBPRCDVRow'}>
                                <Box component="div" className={'BBPRCDVRCol'}>
                                    <Box component="div" className={'BBPRCDVRCTitle'}>
                                        Biller Name
                                    </Box>
                                    <Box component="div" className={'BBPRCDVRCSTitle'}>
                                        {info.trans.biller ? info.trans.biller : '-'}
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPRCDVRCol'}>
                                    <Box component="div" className={'BBPRCDVRCTitle'}>
                                        Bill Category
                                    </Box>
                                    <Box component="div" className={'BBPRCDVRCSTitle'} style={{ textTransform: 'capitalize' }}>
                                        {info.trans.main_cat && info.trans.sub_cat ? `${info.trans.main_cat} - ${info.trans.sub_cat}` : '-'}
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPRCDVRCol'}>
                                    <Box component="div" className={'BBPRCDVRCTitle'}>
                                        Bill Period
                                    </Box>
                                    <Box component="div" className={'BBPRCDVRCSTitle'}>
                                        {info.trans.billPeriod ? info.trans.billPeriod : '-'}
                                    </Box>
                                </Box>  <Box component="div" className={'BBPRCDVRCol'}>
                                    <Box component="div" className={'BBPRCDVRCTitle'}>
                                        Bill Date
                                    </Box>
                                    <Box component="div" className={'BBPRCDVRCSTitle'}>
                                        {info.trans.billDate ? info.trans.billDate : '-'}
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPRCDVRCol'}>
                                    <Box component="div" className={'BBPRCDVRCTitle'}>
                                        Due Date
                                    </Box>
                                    <Box component="div" className={'BBPRCDVRCSTitle'}>
                                        {info.trans.billDueDate ? info.trans.billDueDate : '-'}
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPRCDVRCol'}>
                                    <Box component="div" className={'BBPRCDVRCTitle'}>
                                        Bill Payment Date
                                    </Box>
                                    <Box component="div" className={'BBPRCDVRCSTitle'}>
                                        {info.trans.createdAt ? format(new Date(info.trans.createdAt), "dd-MM-yyyy") : ''}
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPRCDVRCol'}>
                                    <Box component="div" className={'BBPRCDVRCTitle'}>
                                        Bill Amount
                                    </Box>
                                    <Box component="div" className={'BBPRCDVRCSTitle'}>
                                        {info.trans.totalBillAmount ? info.trans.totalBillAmount : '-'}
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPRCDVRCol'}>
                                    <Box component="div" className={'BBPRCDVRCTitle'}>
                                        Bill Number
                                    </Box>
                                    <Box component="div" className={'BBPRCDVRCSTitle'}>
                                        {info.trans.billnumber ? info.trans.billnumber : '-'}
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPRCDVRCol'}>
                                    <Box component="div" className={'BBPRCDVRCTitle'}>
                                        Conv. Fee
                                    </Box>
                                    <Box component="div" className={'BBPRCDVRCSTitle'}>
                                        {info.trans.convFee ? info.trans.convFee : '-'}
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPRCDVRCol'}>
                                    <Box component="div" className={'BBPRCDVRCTitle'}>
                                        Biller ID
                                    </Box>
                                    <Box component="div" className={'BBPRCDVRCSTitle'}>
                                        {info.trans.billerId ? info.trans.billerId : '-'}
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPRCDVRCol'}>
                                    <Box component="div" className={'BBPRCDVRCTitle'}>
                                        Payment Channel
                                    </Box>
                                    <Box component="div" className={'BBPRCDVRCSTitle'}>
                                        {info.trans.paymentChannel ? info.trans.paymentChannel : '-'}
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPRCDVRCol'}>
                                    <Box component="div" className={'BBPRCDVRCTitle'}>
                                        Payment Mode
                                    </Box>
                                    <Box component="div" className={'BBPRCDVRCSTitle'}>
                                        {info.trans.paymentMode ? info.trans.paymentMode : '-'}
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPRCDVRCol'}>
                                    <Box component="div" className={'BBPRCDVRCTitle'}>
                                        Customer Name
                                    </Box>
                                    <Box component="div" className={'BBPRCDVRCSTitle'}>
                                        {info.trans.customerName ? info.trans.customerName : '-'}
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPRCDVRCol'}>
                                    <Box component="div" className={'BBPRCDVRCTitle'}>
                                        Customer Mobile Number
                                    </Box>
                                    <Box component="div" className={'BBPRCDVRCSTitle'}>
                                        {info.trans.customerMobnum ? info.trans.customerMobnum : '-'}
                                    </Box>
                                </Box>
                                {info.trans.customerParams &&
                                    info.trans.customerParams.map((item, i) =>
                                        <Box component="div" key={i} className={'BBPRCDVRCol'}>
                                            <Box component="div" className={'BBPRCDVRCTitle'}>
                                                {item.Name}
                                            </Box>
                                            <Box component="div" className={'BBPRCDVRCSTitle'}>
                                                {item.Value}
                                            </Box>
                                        </Box>
                                    )
                                }
                                <Fragment>
                                    <Box component="div" className={'BBPRCDVRCol BBPRCDVRCPD BBPRCDVRCFull'}>
                                        Payment Details:
                                    </Box>
                                    <Box component="div" className={'BBPRCDVRCPDRow'}>
                                        <Box component="div" className={'BBPRCDVRCPDRCTitle'}>
                                            Transaction Ref ID
                                        </Box>
                                        <Box component="div" className={'BBPRCDVRCPDRCSTitle'}>
                                            {info.trans.transRefID ? info.trans.transRefID : '-'}
                                        </Box>
                                    </Box>
                                    <Box component="div" className={'BBPRCDVRCPDRow'}>
                                        <Box component="div" className={'BBPRCDVRCPDRCTitle'}>
                                            Transaction ID
                                        </Box>
                                        <Box component="div" className={'BBPRCDVRCPDRCSTitle'}>
                                            {info.trans.transID ? info.trans.transID : '-'}
                                        </Box>
                                    </Box>
                                    <Box component="div" className={'BBPRCDVRCPDRow'}>
                                        <Box component="div" className={'BBPRCDVRCPDRCTitle'}>
                                            Pay Ref ID
                                        </Box>
                                        <Box component="div" className={'BBPRCDVRCPDRCSTitle'}>
                                            {info.trans.payRefId ? info.trans.payRefId : '-'}
                                        </Box>
                                    </Box>
                                    <Box component="div" className={'BBPRCDVRCPDRow'}>
                                        <Box component="div" className={'BBPRCDVRCPDRCTitle'}>
                                            UPI Ref ID
                                        </Box>
                                        <Box component="div" className={'BBPRCDVRCPDRCSTitle'}>
                                            {info.trans.upiRefId ? info.trans.upiRefId : '-'}
                                        </Box>
                                    </Box>
                                </Fragment>
                            </Box>
                        </Fragment>
                    }
                </Box>
                <Box component="div" className={'BBPRCDBtn'}>
                    <Button variant="contained" className={'BBPButton BBPOButton'} onClick={onClose}>Close</Button>
                </Box>
            </Box>
        </Dialog>
    );
};
export default ViewComplaint;
