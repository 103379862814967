import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function LandlineIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 64 64" fontSize="inherit">
            <g transform="translate(9.1 10.855)">
                <path d="M9.005,11.212v.562H5.145V45.73H9.005v.976A3.4,3.4,0,0,0,12.4,50.1h9.553a3.4,3.4,0,0,0,3.394-3.394V45.73H50.94V11.774H25.347v-.562a3.4,3.4,0,0,0-3.394-3.394H12.4A3.4,3.4,0,0,0,9.005,11.212Zm0,31.891H7.772V14.4H9.005V43.1ZM25.347,14.4H48.312V43.1H25.347Zm-2.628-3.189V46.707a.767.767,0,0,1-.766.766H12.4a.767.767,0,0,1-.767-.766V11.212a.767.767,0,0,1,.767-.766h9.553A.767.767,0,0,1,22.719,11.212Z" transform="translate(-5.145 -7.818)" fill="#175783" />
                <path d="M39.98,29.366H57.82V20.641H39.98Zm2.628-6.1H55.193v3.469H42.608Z" transform="translate(-17.096 -12.217)" fill="#175783" />
                <rect width="3.084" height="2.628" transform="translate(36.906 20.331)" fill="#175783" />
                <rect width="3.084" height="2.628" transform="translate(30.255 20.331)" fill="#175783" />
                <rect width="3.084" height="2.628" transform="translate(23.603 20.331)" fill="#175783" />
                <rect width="3.084" height="2.628" transform="translate(36.906 25.565)" fill="#175783" />
                <rect width="3.084" height="2.628" transform="translate(30.255 25.565)" fill="#175783" />
                <rect width="3.084" height="2.628" transform="translate(23.603 25.565)" fill="#175783" />
                <rect width="3.084" height="2.628" transform="translate(36.906 30.8)" fill="#175783" />
                <rect width="3.084" height="2.628" transform="translate(30.255 30.8)" fill="#175783" />
                <rect width="3.084" height="2.628" transform="translate(23.603 30.8)" fill="#175783" />
            </g>
        </SvgIcon>
    );
}