import React, { Fragment, useState } from 'react';
import { format } from 'date-fns';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import WestIcon from '@mui/icons-material/West';
import Slide from '@mui/material/Slide';
import Avatar from '@mui/material/Avatar';
import AddchartIcon from '@mui/icons-material/Addchart';
import ReceiptIcon from '@mui/icons-material/Receipt';
import BAssured from '../../../Assets/Images/bassured.png';
import PaymentReceipt from '../../../Components/PaymentReceipt';
import RegisterComplaint from '../../../Components/RVEComplaint/registerComplaint';
import './style.css';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TransactionsInfo = ({ open, handleClose, info }) => {

    const [viewReceipt, setViewReceipt] = useState(false);

    const openViewReceipt = () => {
        setViewReceipt(true);
    };

    const closeViewReceipt = () => {
        setViewReceipt(false);
    };

    const [registerComplaint, setRegisterComplaint] = useState(false);

    const openRegisterComplaint = () => {
        setRegisterComplaint(true);
    };

    const closeRegisterComplaint = () => {
        setRegisterComplaint(false);
    };

    return (
        <Fragment>
            <PaymentReceipt
                open={viewReceipt}
                transactionId={info.orderId}
                onClose={closeViewReceipt}
                handleCancel={closeViewReceipt}
            />
            <RegisterComplaint
                transactionId={info.orderId}
                open={registerComplaint}
                onClose={closeRegisterComplaint}
            />
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <Box component="div" className={"BBPPWATransInfo"}>
                    <Box component="div" className={"BBPPWATTHead"}>
                        <IconButton onClick={handleClose}>
                            <WestIcon fontSize="inherit" />
                        </IconButton>
                        <Box component="div" className={"BBPPWATTHTitle"}>
                            Back to Transactions
                        </Box>
                    </Box>
                    <Box component="div" className={"BBPPWATBody"}>
                        <Box component="div" className={`BBPPWASucTitle ${info.status}`}>
                            Bill Payment {info.status} {`₹${info.billAmount}`}
                        </Box>
                        <Box component="div" className={"BBPPWABiller"}>
                            <Box component="div" className={"BBPPWABIcon"}>
                                <Avatar>{(info.biller[0]).toUpperCase()}</Avatar>
                            </Box>
                            <Box component="div" className={"BBPPWABInfo"}>
                                <Box component="div" className={"BBPPWABITitle"}>
                                    {info.biller}
                                </Box>
                                <Box component="div" className={"BBPPWABIDate"}>
                                    {format(new Date(info.createdAt), "dd-MM-yyyy")}
                                </Box>
                            </Box>
                        </Box>
                        <Box component="div" className={"BBPPWATtD"}>
                            <Box component="div" className={"BBPPWATtDKName"}>Order ID</Box>
                            <Box component="div" className={"BBPPWATtDTitle"}>{info.orderId}</Box>
                        </Box>
                        {info.customerParamsDetails.map((item, index) =>
                            <Box component="div" className={"BBPPWARow"} key={index}>
                                <Box component="div" className={"BBPPWARCol1"}>{item.Name}</Box>
                                <Box component="div" className={"BBPPWARCol2"}>{item.Value}</Box>
                            </Box>
                        )}
                        <Box component="div" className={"BBPPWATPD"}>
                            Payment Details
                        </Box>
                        <Box component="div" className={"BBPPWATPDRow"}>
                            <Box component="div" className={"BBPPWATPDRInner"}>
                                <Box component="div" className={"BBPPWATPDRName"}>
                                    {info.biller}
                                </Box>
                                <Box component="div" className={"BBPPWATPDRMo"}>
                                    {`₹${info.billAmount}`}
                                </Box>
                            </Box>
                        </Box>
                        {info.status !== 'Failed' && info.status !== 'Canceled' &&
                            <Fragment>
                                <Box component="div" className={"BBPPWATPD"}>
                                    Payment Mode
                                </Box>
                                <Box component="div" className={"BBPPWATPDRow"}>
                                    <Box component="div" className={"BBPPWATPDRInner"}>
                                        <Box component="div" className={"BBPPWATPDRInfo"}>
                                            <Box component="div" className={"BBPPWATPDRIBank"}>
                                                {`${info.payment.mode} -> ${info.payment.provider}`}
                                            </Box>
                                            <Box component="div" className={"BBPPWATPDRITiD"}>
                                                Transaction ID: {info.payment.transId}
                                            </Box>
                                        </Box>
                                        <Box component="div" className={"BBPPWATPDRMo"}>
                                            {`₹${info.billAmount}`}
                                        </Box>
                                    </Box>
                                </Box>
                                {info.status !== 'Pending' &&
                                    <Fragment>
                                        <Box component="div" className={"BBPPWATRF"}>
                                            <Box component="div" className={"BBPPWATRFInfo"}>
                                                <Box component="div" className={"BBPPWATRFITitle"}>
                                                    BBPS Reference Number
                                                </Box>
                                                <Box component="div" className={"BBPPWATPDRITiD"}>
                                                    {info.billerRefId}
                                                </Box>
                                            </Box>
                                            <Box component="div" className={"BBPPWATRFIcon"}>
                                                <img src={BAssured} alt="BBPS" />
                                            </Box>
                                        </Box>
                                        <Box component="div" className={"BBPPWATBtn"}>
                                            <Button
                                                variant="contained"
                                                startIcon={<ReceiptIcon />}
                                                fullWidth
                                                onClick={openViewReceipt}
                                            >
                                                View Invoice
                                            </Button>
                                        </Box>
                                    </Fragment>}
                            </Fragment>}
                        <Box component="div" className={"BBPPWATBtn"}>
                            <Button
                                variant="outlined"
                                startIcon={<AddchartIcon />}
                                fullWidth
                                onClick={openRegisterComplaint}
                                className={"BBPPWATBRC"}
                            >
                                Register Compliant
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </Fragment>
    );
};
export default TransactionsInfo; 