import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { CategoryIcon } from '../../../Utils/categoryIcon';
import { MENU_SLUG } from '../../../Constants/constants';
import './style.css';

const CategoryBox = ({ item, mobile_number, homeLink }) => {
    let location = useLocation();
    return (
        <NavLink
            to={`/${location.pathname === `/${MENU_SLUG.pwa}` ? MENU_SLUG.pwa : MENU_SLUG.bill_payments}/${item._id}`}
            className={'BBPBPBCLBox'}
            state={{ mobile_number: mobile_number, homeLink: homeLink }}
        >
            <Box component="div" className={'BBPBPBCLBIcon'}>
                {CategoryIcon(item.icon)}
            </Box>
            <Box component="div" className={'BBPBPBCLBTitle'}>
                {item.name}
            </Box>
        </NavLink>
    );
};
export default CategoryBox;