import React, { useState } from 'react';
import { Route, Routes, Outlet, useLocation } from 'react-router-dom';
import RightSidePanel from '../../Components/RightSidePanel';
import ComplaintTable from './complaintTable';
import { CheckPermissions } from '../../Utils/permissions';

import './style.css';

const Complaints = () => {

    let location = useLocation();

    const background = location.state ? location.state.background : location;

    const [pages] = useState([
        {
            path: '/',
            component: ComplaintTable,
            enable_route: CheckPermissions('complaints_permissions', 'View')
        },
    ]);

    return (
        <RightSidePanel removeBg>
            <Routes location={background}>
                <Route path={'/'} element={<Outlet />}>
                    {
                        // eslint-disable-next-line 
                        pages.map((route) => {
                            if (route.enable_route) {
                                const { path, component: Component, children, title, permission, ...rest } = route;
                                return (
                                    <Route {...rest} key={path} path={`${path}`} element={<Component />} />
                                )
                            }
                        })}
                </Route>
            </Routes>
        </RightSidePanel>
    );
};

export default Complaints;
