import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function CheckCircleOutlineIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 100 100" fontSize="inherit">
            <g transform="translate(-925 -257)">
                <g transform="translate(925 257)" fill="#fff" stroke="#21d59b" strokeWidth="5">
                    <circle cx="50" cy="50" r="50" stroke="none" />
                    <circle cx="50" cy="50" r="47.5" fill="none" />
                </g>
                <path d="M-8532.537-20535.811l17.723,17.721,41.39-41.391" transform="translate(9477.98 20845.785)" fill="none" stroke="#21d59b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6" />
            </g>
        </SvgIcon>
    );
}