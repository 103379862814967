import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SearchBox from '../../Components/SearchBox';
import FileAddIcon from '../../Components/Icons/FileAddIcon';
import FileUploadIcon from '../../Components/Icons/FileUploadIcon';
import DevTable from '../../Components/DevTable';
import AlertDialog from '../../Components/AlertDialog';
import { MENU_SLUG } from '../../Constants/constants';
import Backdrops from '../../Components/Backdrops';
import Tooltip from '@mui/material/Tooltip';
import { CheckPermissions } from '../../Utils/permissions';
import SomethingWentWrong from '../../Components/SomethingWentWrong';
import './style.css';

const CommissionTable = () => {

    let navigate = useNavigate();
    let location = useLocation();

    let editPermissions = CheckPermissions('commissions_permissions', 'Edit');
    let deletePermissions = CheckPermissions('commissions_permissions', 'Delete');
    let addPermissions = CheckPermissions('commissions_permissions', 'Add');
    let uploadPermissions = CheckPermissions('commissions_permissions', 'Bulk Upload');

    const axiosPrivate = useAxiosPrivate();

    const [errorDialog, setErrorDialog] = useState(false);

    const [loading, setLoading] = useState(true);
    const [onDeleteLoading, setOnDeleteLoading] = useState(false);
    const [deleteItem, setDeleteItem] = useState({});
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [totalData, setTotalData] = useState(0);
    const [selectedPage, setSelectedPage] = useState(1);
    const [limitData] = useState(20);
    const [rowData, setRowData] = useState([]);

    const [apiParams] = useState({ limit: limitData });

    const [mainColumns, setMainColumns] = useState({
        columns: [
            {
                name: 'commission_for',
                title: 'Commission For',
                width: 100,
                sorting: true,
                direction: ''
            },
            {
                name: 'category',
                title: 'Category Name',
                width: 200,
                sorting: true,
                direction: ''
            },
            {
                name: 'biller',
                title: 'Biller Name',
                width: 200,
                sorting: true,
                direction: ''
            },
            {
                name: 'range',
                title: 'Range',
                width: 100,
                sorting: true,
                direction: ''
            },
            {
                name: 'value',
                title: 'Value',
                width: 250,
                sorting: false,
                direction: ''
            },
            {
                name: 'action',
                title: 'Actions',
                width: 150,
                sorting: false,
                direction: ''
            },
        ]
    });

    const fetchCommissions = useCallback(async (params) => {
        try {
            let urlParams = '';
            if (params) {
                Object.keys(params).forEach(function (key, index) {
                    urlParams += (index === 0 ? '?' : '&') + key + '=' + params[key];
                });
            }
            let url = `commissions${urlParams}`;
            let options = {
                method: 'GET',
                url
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setRowData(response.data.data);
                    setTotalData(response.data.total);
                    setLoading(false);
                } else {
                    setRowData([]);
                    setTotalData(0);
                    setLoading(false);
                }
            }).catch(err => {
                if (err.response) {
                    setLoading(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setLoading(false);
            setErrorDialog(true);
            // console.error('error', error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearch = useCallback((value) => {
        setLoading(true);
        let prm = apiParams;
        setSelectedPage(1);
        prm.page = 1;
        if (value.length > 0) {
            prm.search = value;
        } else {
            delete prm.search;
        }
        fetchCommissions(prm);
    }, [apiParams, fetchCommissions])

    const handlePaginate = (value) => {
        let prm = apiParams;
        prm.page = value;
        setSelectedPage(value);
        setLoading(true);
        fetchCommissions(prm);
    };

    const handlePreviewCommission = (item) => {
        navigate(`/${MENU_SLUG.commissions}/view/${item._id}`, { state: { background: location } });
    }

    const handleEditCommission = (item) => {
        navigate(`/${MENU_SLUG.commissions}/edit/${item._id}`, { replace: true });
    }

    const handleSorting = (name, index) => {
        let prm = apiParams;
        let tempCol = mainColumns.columns;
        if (tempCol[index].direction === '') {
            tempCol.map((column) => (column.name === name ? column.direction = 'ASC' : column.direction = ''));
            prm.sort_by = name;
            prm.order = 1;
            prm.page = 1;
        } else if (tempCol[index].direction === 'ASC') {
            tempCol.map((column) => (column.name === name ? column.direction = 'DSC' : column.direction = ''));
            prm.sort_by = name;
            prm.order = -1;
            prm.page = 1;
        } else if (tempCol[index].direction === 'DSC') {
            tempCol.map((column) => column.direction = '');
            prm.page = 1;
            delete prm.sort_by;
            delete prm.order;
        }
        setLoading(true);
        setMainColumns({ columns: tempCol });
        setSelectedPage(1);
        fetchCommissions(prm);
    }

    const handleSetDeleteCommission = (item) => {
        setDeleteItem(item);
        setDeleteAlert(true);
    }

    const handleDeleteAlertClose = () => {
        setDeleteAlert(false);
        setDeleteItem({});
    }

    const handleDeleteCommission = useCallback(async () => {
        try {
            setDeleteAlert(false);
            setOnDeleteLoading(true);
            let url = `commissions/${deleteItem._id}`;
            let options = {
                method: 'DELETE',
                url
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setDeleteItem({});
                    setLoading(false);
                    setOnDeleteLoading(false);
                    fetchCommissions(apiParams);
                } else {
                    // console.error('err.res', response);
                    setLoading(false);
                }
            }).catch(err => {
                if (err.response) {
                    setLoading(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setLoading(false);
            setErrorDialog(true);
            // console.error('error', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteItem, fetchCommissions]);

    function generateRows(tempArray) {
        const tempRowArray = [];
        if (tempArray) {
            tempArray.map((item) =>
                tempRowArray.push({
                    commission_for: (<Box component="div" className="BBPDTSText">{item.commission_for}</Box>),
                    category: (<Box component="div" className="BBPDTSText">{item.cat.name}</Box>),
                    biller: (<Box component="div" className="BBPDTSText">{`${item.commission_for === 'Provider' ? item.prov ? item.prov.name : '-' : '-'}`}</Box>),
                    range: (<Box component="div" className="BBPDTSText">{item.range ? 'Yes' : 'No'}</Box>),
                    value: (<Fragment>
                        {item.range ?
                            <Box component="div" className="BBPDTSGroup">
                                {item.range_val.length > 0 &&
                                    item.range_val.map((value, index) =>
                                        <Box component="div" key={index} className="BBPDTSGItem">
                                            {`${value.from} to ${value.to} = ${value.comm_val}${value.comm_type === 'Percentage' ? '%' : ''}`}
                                        </Box>
                                    )}
                            </Box>
                            :
                            <Box component="div" className="BBPDTSText">
                                {`${item.comm_val}${item.comm_type === 'Percentage' ? '%' : ''}`}
                            </Box>
                        }
                    </Fragment>),
                    action: (<Box component="div" className="BBPDTIBtns">
                        <Tooltip
                            placement="top"
                            classes={{
                                popper: 'BBPTPopper',
                                tooltip: 'BBPTooltip'
                            }}
                            title={'Commission Details'}
                        >
                            <IconButton size="small" className="BBPDTIBIcon" onClick={() => { handlePreviewCommission(item) }}>
                                <RemoveRedEyeOutlinedIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                        {editPermissions ?
                            <Tooltip
                                placement="top"
                                classes={{
                                    popper: 'BBPTPopper',
                                    tooltip: 'BBPTooltip'
                                }}
                                title={'Edit Commission'}
                            >
                                <IconButton size="small" className="BBPDTIBIcon" onClick={() => { handleEditCommission(item) }}>
                                    <EditOutlinedIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                            : ''}
                        {deletePermissions ?
                            <Tooltip
                                placement="top"
                                classes={{
                                    popper: 'BBPTPopper',
                                    tooltip: 'BBPTooltip'
                                }}
                                title={'Delete Commission'}
                            >
                                <IconButton size="small" className="BBPDTIBIcon" onClick={() => { handleSetDeleteCommission(item) }}>
                                    <DeleteOutlinedIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip> : ''}
                    </Box>),
                }),
            );
        }
        return tempRowArray;
    }

    useEffect(() => {
        fetchCommissions(apiParams);
        // eslint-disable-next-line  
    }, [])

    return (
        <Fragment>
            <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
            <Backdrops
                open={onDeleteLoading}
                title={'Deleting'}
            />
            <AlertDialog
                open={deleteAlert}
                onClose={handleDeleteAlertClose}
                title={'Are you sure ?'}
                message={'Do you really want to delete these record ?'}
                buttons={
                    <Fragment>
                        <Button variant="contained" onClick={handleDeleteAlertClose}>Cancel</Button>
                        <Button variant="contained" className={'BBPADBDelete'} onClick={handleDeleteCommission}>Delete</Button>
                    </Fragment>
                }
            />
            <Box component='div' className={'BBPCommission'}>
                <Box component='div' className={'BBPCHead'}>
                    <SearchBox
                        onSearchChange={handleSearch}
                        placeholder={'Search Commission'}
                        searchTooltip={'Searching from Category Name, Provide Name'}
                    />
                    <Box component='div' className={'BBPCHBtn'}>
                        {addPermissions ?
                            <Link to={`/${MENU_SLUG.commissions}/add`} className={'BBPTableBtn'}>
                                <Box component='span'><FileAddIcon /></Box>
                                Add
                            </Link> : ''}
                        {uploadPermissions ?
                            <Link to={`/${MENU_SLUG.commissions}/upload`} className={'BBPTableBtn'}>
                                <Box component='span'><FileUploadIcon /></Box>
                                Upload
                            </Link>
                            : ''}
                    </Box>
                </Box>
                <Box component='div' className={'BBPBBody'}>
                    <DevTable
                        rows={generateRows(rowData)}
                        columns={mainColumns.columns}
                        selectedPage={selectedPage}
                        handlePagination={handlePaginate}
                        loading={loading}
                        handleSort={handleSorting}
                        limitData={limitData}
                        totalData={totalData}
                        noDataTitle={'No Commission Found'}
                        noDataDes={' '}
                    />
                </Box>
            </Box>
        </Fragment>
    );
};

export default CommissionTable;
