import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { usePapaParse } from 'react-papaparse';
import { format } from 'date-fns';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import AddchartIcon from '@mui/icons-material/Addchart';
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import SearchBox from '../../Components/SearchBox';
import DevTable from '../../Components/DevTable';
import DateRangePicker from '../../Components/DateRangePicker';
import SomethingWentWrong from '../../Components/SomethingWentWrong';
import Backdrops from '../../Components/Backdrops';
import BhartBillPay from '../../Assets/Images/bhartbillpay.png';
import PaymentReceipt from '../../Components/PaymentReceipt';
import RefundDialog from '../../Components/RefundDialog';
import RegisterComplaint from '../../Components/RVEComplaint/registerComplaint';
import { dateFormatter } from '../../Utils/index';

import './style.css';

const TransactionTable = () => {

    const { jsonToCSV } = usePapaParse();

    const axiosPrivate = useAxiosPrivate();

    const [errorDialog, setErrorDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [dataStartDate, setDataStartDate] = useState();
    const [totalData, setTotalData] = useState(0);
    const [selectedPage, setSelectedPage] = useState(1);
    const [limitData] = useState(20);
    const [onDownloadLoading, setOnDownloadLoading] = useState(false);
    const [viewReceipt, setViewReceipt] = useState(false);
    const [transactionID, setTransactionID] = useState('');
    const [transactionAmt, setTransactionAmt] = useState('');
    const [registerCompliant, setRegisterCompliant] = useState(false);
    const [refundDialog, setRefundDialog] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [apiParams] = useState({ limit: limitData });

    const [mainColumns, setMainColumns] = useState({
        columns: [
            {
                name: 'transDate',
                title: 'Transaction Date',
                width: 150,
                sorting: true,
                direction: ''
            },
            {
                name: 'billAmount',
                title: 'Bill Amount',
                width: 100,
                sorting: false,
                direction: ''
            },
            {
                name: 'sub_cat',
                title: 'Category',
                width: 100,
                sorting: true,
                direction: ''
            },
            {
                name: 'biller',
                title: 'Biller Name',
                width: 200,
                sorting: true,
                direction: ''
            },
            {
                name: 'mobile_num',
                title: 'Mobile Number',
                width: 150,
                sorting: true,
                direction: ''
            },
            {
                name: 'customerName',
                title: 'Customer Name',
                width: 150,
                sorting: false,
                direction: ''
            },
            {
                name: 'billDate',
                title: 'Bill Date',
                width: 100,
                sorting: false,
                direction: ''
            },
            {
                name: 'paymentType',
                title: 'Payment Mode',
                width: 150,
                sorting: false,
                direction: ''
            },
            {
                name: 'transRefID',
                title: 'Transaction Ref ID',
                width: 150,
                sorting: false,
                direction: ''
            },
            {
                name: 'paymentRefId',
                title: 'UPI Ref ID',
                width: 100,
                sorting: false,
                direction: ''
            },
            {
                name: 'paymentExtRefId',
                title: 'Ext Ref ID',
                width: 100,
                sorting: false,
                direction: ''
            },
            {
                name: 'partner',
                title: 'Partner',
                width: 100,
                sorting: false,
                direction: ''
            },
            {
                name: 'paymentStatus',
                title: 'Payment Status',
                width: 100,
                sorting: true,
                direction: ''
            },
            {
                name: 'status',
                title: 'Bill Status',
                width: 100,
                sorting: true,
                direction: ''
            },
            {
                name: 'refundAmt',
                title: 'Refund Amount',
                width: 100,
                sorting: true,
                direction: ''
            },
            {
                name: 'refundStatus',
                title: 'Refund Status',
                width: 100,
                sorting: true,
                direction: ''
            },
            {
                name: 'action',
                title: 'Action',
                width: 150,
                sorting: false,
                direction: ''
            }
        ]
    });

    const fetchTransaction = useCallback(async (params) => {
        try {
            let urlParams = '';
            if (params) {
                Object.keys(params).forEach(function (key, index) {
                    urlParams += (index === 0 ? '?' : '&') + key + '=' + params[key];
                });
            }
            let url = `reports/transactions${urlParams}`;
            let options = {
                method: 'GET',
                url
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setRowData(response.data.data);
                    setTotalData(response.data.total);
                    setLoading(false);
                } else {
                    setRowData([]);
                    setTotalData(0);
                    setLoading(false);
                }
                setDataStartDate(new Date(response.data.firstDate));
            }).catch(err => {
                if (err.response) {
                    setLoading(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setLoading(false);
            setErrorDialog(true);
            // console.error('error', error);
        }
        // eslint-disable-next-line 
    }, []);

    const handleSearch = useCallback((value) => {
        setLoading(true);
        let prm = apiParams;
        setSelectedPage(1);
        prm.page = 1;
        if (value.length > 0) {
            prm.search = value;
        } else {
            delete prm.search;
        }
        fetchTransaction(prm);
    }, [apiParams, fetchTransaction])

    const handlePaginate = (value) => {
        let prm = apiParams;
        prm.page = value;
        setSelectedPage(value);
        setLoading(true);
        fetchTransaction(prm);
    };

    const handleSorting = (name, index) => {
        let prm = apiParams;
        let tempCol = mainColumns.columns;
        if (tempCol[index].direction === '') {
            tempCol.map((column) => (column.name === name ? column.direction = 'ASC' : column.direction = ''));
            prm.sort_by = name;
            prm.order = 1;
            prm.page = 1;
        } else if (tempCol[index].direction === 'ASC') {
            tempCol.map((column) => (column.name === name ? column.direction = 'DSC' : column.direction = ''));
            prm.sort_by = name;
            prm.order = -1;
            prm.page = 1;
        } else if (tempCol[index].direction === 'DSC') {
            tempCol.map((column) => column.direction = '');
            prm.page = 1;
            delete prm.sort_by;
            delete prm.order;
        }
        setLoading(true);
        setMainColumns({ columns: tempCol });
        setSelectedPage(1);
        fetchTransaction(prm);
    }

    const openViewReceipt = (id) => {
        setTransactionID(id);
        setViewReceipt(true);
    };

    const closeViewReceipt = () => {
        setTransactionID('');
        setViewReceipt(false);
    };

    const openRegisterCompliant = (id) => {
        setTransactionID(id);
        setRegisterCompliant(true);
    };

    const closeRegisterCompliant = () => {
        setTransactionID('');
        setRegisterCompliant(false);
        setViewReceipt(false);
    };

    const handleRefundDialogOpen = (id, amount) => {
        setTransactionID(id);
        setTransactionAmt(amount);
        setRefundDialog(true);
    };

    const handleRefundDialogClose = () => {
        setTransactionID('');
        setTransactionAmt('');
        setRefundDialog(false);
    };

    function generateRows(tempArray) {
        const tempRowArray = [];
        if (tempArray) {
            tempArray.map((item) =>
                tempRowArray.push({
                    transDate: (<Box component="div" className="BBPDTSText">{dateFormatter(item.transDate)}</Box>),
                    billAmount: (<Box component="div" className="BBPDTSText">{item.billAmount}</Box>),
                    sub_cat: (<Box component="div" className="BBPDTSText">{`${item.main_cat} - ${item.sub_cat}`}</Box>),
                    biller: (<Box component="div" className="BBPDTSText">{item.biller}</Box>),
                    mobile_num: (<Box component="div" className="BBPDTSText">{item.mobile_num}</Box>),
                    customerName: (<Box component="div" className="BBPDTSText">{item.customerName}</Box>),
                    billDate: (<Box component="div" className="BBPDTSText">{dateFormatter(item.billDate)}</Box>),
                    paymentType: (<Box component="div" className="BBPDTSText">{item.paymentType}</Box>),
                    transRefID: (<Box component="div" className="BBPDTSText">{item.transRefID}</Box>),
                    paymentRefId: (<Box component="div" className="BBPDTSText">{item.paymentRefId}</Box>),
                    paymentExtRefId: (<Box component="div" className="BBPDTSText">{item.paymentExtRefId}</Box>),
                    partner: (<Box component="div" className="BBPDTSText">{item.partner}</Box>),
                    paymentStatus: (<Box component="div" className="BBPDTChips"><Box component="div" className={"BBPDTCChip " + item.paymentStatus}>{item.paymentStatus}</Box></Box>),
                    status: (<Box component="div" className="BBPDTChips"><Box component="div" className={"BBPDTCChip " + item.status}>{item.status}</Box></Box>),
                    refundAmt: (<Box component="div" className="BBPDTSText">{item.refundAmt}</Box>),
                    refundStatus: (<Box component="div" className="BBPDTChips"><Box component="div" className={"BBPDTCChip " + item.refundStatus}>{item.refundStatus}</Box></Box>),
                    action: (<Box component="div" className="BBPDTIBtns">
                        {item.status !== "Success" ?
                            <Tooltip
                                placement="top"
                                classes={{
                                    popper: 'BBPTPopper',
                                    tooltip: 'BBPTooltip'
                                }}
                                title={`Bill Status - ${item.status}`}
                            >
                                <IconButton
                                    size="small"
                                    className="BBPDTIBIcon BBPDTIBDes"
                                >
                                    <RemoveRedEyeOutlinedIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                            :
                            <Tooltip
                                placement="top"
                                classes={{
                                    popper: 'BBPTPopper',
                                    tooltip: 'BBPTooltip'
                                }}
                                title={'View Receipt'}
                            >
                                <IconButton
                                    size="small"
                                    className="BBPDTIBIcon"
                                    onClick={() => openViewReceipt(item.id)}
                                >
                                    <RemoveRedEyeOutlinedIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                        }
                        <Tooltip
                            placement="top"
                            classes={{
                                popper: 'BBPTPopper',
                                tooltip: 'BBPTooltip'
                            }}
                            title={'Register Compliant'}
                        >
                            <IconButton
                                size="small"
                                className="BBPDTIBIcon"
                                onClick={() => openRegisterCompliant(item.id)}
                            >
                                <AddchartIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                        {item.status === "Failed" && item.paymentStatus === "Success" ?
                            <Tooltip
                                placement="top"
                                classes={{
                                    popper: 'BBPTPopper',
                                    tooltip: 'BBPTooltip'
                                }}
                                title={'Amount Refund'}
                            >
                                <IconButton
                                    size="small"
                                    className="BBPDTIBIcon"
                                    onClick={() => handleRefundDialogOpen(item.id, item.billAmount)}
                                >
                                    <CurrencyExchangeIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                            :
                            <Tooltip
                                placement="top"
                                classes={{
                                    popper: 'BBPTPopper',
                                    tooltip: 'BBPTooltip'
                                }}
                                title={`Payment Status - ${item.paymentStatus} and Bill Status - ${item.status}`}
                            >
                                <IconButton
                                    size="small"
                                    className="BBPDTIBIcon BBPDTIBDes"
                                >
                                    <CurrencyExchangeIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                        }
                    </Box>),
                }),
            );
        }
        return tempRowArray;
    }

    const handleCSVDownload = useCallback(async () => {
        try {
            setOnDownloadLoading(true);
            let urlParams = '';
            if (apiParams) {
                delete apiParams.page;
                Object.keys(apiParams).forEach(function (key, index) {
                    urlParams += (index === 0 ? '?' : '&') + key + '=' + (key === 'limit' ? totalData : apiParams[key]);
                });
            }
            let url = `reports/transactions${urlParams}`;
            let options = {
                method: 'GET',
                url
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setRowData(response.data.data);
                    setTotalData(response.data.total);
                    const tempDownloadArr = [];
                    const tempHeader = {
                        transDate: 'Transaction Date',
                        billAmount: 'Bill Amount',
                        convFee: 'Conv Fee',
                        main_cat: 'Main Category',
                        sub_cat: 'Sub Category',
                        biller: 'Biller Name',
                        billerId: 'Biller ID',
                        billnumber: 'Biller Number',
                        mobile_num: 'Mobile Number',
                        customerName: 'Customer Name',
                        customerMobnum: 'Customer Mobile Number',
                        customerParams: 'Customer Params',
                        billDate: 'Bill Date',
                        billDueDate: 'Bill Due Date',
                        billPeriod: 'Bill Period',
                        paymentType: 'Payment Mode',
                        paymentChannel: 'Payment Channel',
                        payRefId: 'Pay Ref ID',
                        transID: 'Transaction ID',
                        transRefID: 'Transaction Ref ID',
                        paymentId: 'Payment ID',
                        paymentRefId: 'UPI Ref ID',
                        paymentExtRefId: 'Ext Ref ID',
                        partner: 'Partner',
                        paymentStatus: 'Payment Status',
                        status: 'Bill Status',
                    };
                    // eslint-disable-next-line 
                    response.data.data.map((item) => {
                        let custPrm = '';
                        item.customerParams.map((item, index) =>
                            custPrm += index !== 0 ? `, ${item.Name}:${item.Value}` : `${item.Name}:${item.Value}`
                        )
                        tempDownloadArr.push({
                            transDate: format(new Date(item.transDate), "dd-MM-yyyy"),
                            billAmount: item.billAmount,
                            convFee: item.convFee,
                            main_cat: item.main_cat,
                            sub_cat: item.sub_cat,
                            biller: item.biller,
                            billerId: item.billerId,
                            billnumber: item.billnumber,
                            mobile_num: item.mobile_num,
                            customerName: item.customerName,
                            customerMobnum: item.customerMobnum,
                            customerParams: custPrm,
                            billDate: item.billDate,
                            billDueDate: item.billDueDate,
                            billPeriod: item.billPeriod,
                            paymentType: item.paymentType,
                            paymentChannel: item.paymentChannel,
                            transactionRefId: item.TransactionRefId,
                            payRefId: item.payRefId,
                            transID: item.transID,
                            transRefID: item.transRefID,
                            paymentId: item.paymentId,
                            paymentRefId: item.paymentRefId,
                            paymentExtRefId: item.paymentExtRefId,
                            partner: item.partner,
                            paymentStatus: item.paymentStatus,
                            status: item.status,
                        })
                    });
                    tempDownloadArr.unshift(tempHeader);
                    const csv = jsonToCSV(tempDownloadArr, { header: false });
                    let filename = `${'transactions'}-${format(new Date(), 'yyyy-MM-dd-HH:mm:ss')}.csv`;
                    var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                    var csvURL = null;
                    if (navigator.msSaveBlob) {
                        csvURL = navigator.msSaveBlob(csvData, filename);
                    } else {
                        csvURL = window.URL.createObjectURL(csvData);
                    }
                    var tempLink = document.createElement('a');
                    tempLink.href = csvURL;
                    tempLink.setAttribute('download', filename);
                    tempLink.click();
                    setOnDownloadLoading(false);
                } else {
                    setRowData([]);
                    setTotalData(0);
                    setOnDownloadLoading(false);
                }
            }).catch(err => {
                if (err.response) {
                    setErrorDialog(true);
                    setOnDownloadLoading(false);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setErrorDialog(true);
            setOnDownloadLoading(false);
            // console.error('error', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalData]);

    const handleDateFilter = (date) => {
        let prm = apiParams;
        prm.startDate = format(date.startDate, "yyyy-MM-dd");
        prm.endDate = format(date.endDate, "yyyy-MM-dd");
        setLoading(true);
        fetchTransaction(prm);
    };

    const handleRestDateFilter = () => {
        let prm = apiParams;
        delete prm.startDate;
        delete prm.endDate;
        setLoading(true);
        fetchTransaction(prm);
    };

    useEffect(() => {
        fetchTransaction(apiParams);
        // eslint-disable-next-line
    }, []);


    return (
        <Fragment>
            <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
            <Backdrops
                open={onDownloadLoading}
                title={'Downloading'}
            />
            <PaymentReceipt
                open={viewReceipt}
                transactionId={transactionID}
                onClose={closeViewReceipt}
                handleCancel={closeViewReceipt}
                handleCompliant={openRegisterCompliant}
            />
            <RegisterComplaint
                transactionId={transactionID}
                open={registerCompliant}
                onClose={closeRegisterCompliant}
            />
            <RefundDialog
                title={'Refund'}
                transactionId={transactionID}
                transactionAmt={transactionAmt}
                open={refundDialog}
                onClose={handleRefundDialogClose}
            />
            <Box component='div' className={'BBPTransaction'}>
                <Box component='div' className={'BBPTHead'}>
                    <SearchBox
                        onSearchChange={handleSearch}
                        placeholder={'Search Transaction'}
                        searchTooltip={'Searching from Category, Biller Name, Customer Name and Transaction Ref ID'}
                    />
                    <Box component='div' className={'BBPTHBtn'}>
                        <Box component="div" className={'BBPTHIcon'}>
                            <img src={BhartBillPay} alt={'Bharat Bill Payment'} />
                        </Box>
                        <DateRangePicker
                            title={'Period'}
                            buttonTitle={'Apply'}
                            dataStartDate={dataStartDate}
                            onChange={handleDateFilter}
                            onReset={handleRestDateFilter}
                        />
                        <Button variant="contained" className={'BBPTHBD'} onClick={handleCSVDownload} disabled={loading || totalData === 0}>Download</Button>
                    </Box>
                </Box>
                <Box component='div' className={'BBPTBody'}>
                    <DevTable
                        rows={generateRows(rowData)}
                        columns={mainColumns.columns}
                        selectedPage={selectedPage}
                        handlePagination={handlePaginate}
                        loading={loading}
                        handleSort={handleSorting}
                        limitData={limitData}
                        totalData={totalData}
                        noDataTitle={'No data found'}
                        noDataDes={'Please retry'}
                    />
                </Box>
            </Box>
        </Fragment>
    );
};

export default TransactionTable;
