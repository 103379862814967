import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function PersonalIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 64 64" fontSize="inherit">
            <g transform="translate(6.721 9.332)">
                <path d="M46.122,18.2l2.439,4.878c-1.109.665-2.439,1.552-3.769,2.439L33.484,31.5l-1.33.665a7.317,7.317,0,0,1-4.878-.222,51.33,51.33,0,0,0-8.2-2.882c-2.439-.887-4.656-1.774-7.1-2.439a4.275,4.275,0,0,0-2.882,0,54.743,54.743,0,0,0-6.43,2.217c-.222,0-.222.222-.443.443A.217.217,0,0,1,2,29.059v-15.3c2.439-.665,4.878-1.109,7.1-1.774.222,0,.443-.222.665-.222,1.109.222,2-.443,3.1-.665a12.869,12.869,0,0,1,3.769,1.33,95.769,95.769,0,0,0,9.977,4.213c2,.665,3.769,2.882,3.769,4.656a2.666,2.666,0,0,1-.443,1.774c-.443.887-.665,1.774-1.109,2.882.887.887,2,.665,3.1,0,2.661-1.552,5.321-2.882,8.2-4.213A24.838,24.838,0,0,1,46.122,18.2ZM5.991,22.851a14.129,14.129,0,0,0,1.552-.443c2.882-1.109,3.548-1.33,5.765-.222,3.1,1.552,6.43,2.217,9.756,3.548,1.552.443,1.552.443,2.217-1.109.443-1.33,1.109-2.661,1.552-3.769L16.19,16.2c-1.552-.665-3.1-1.33-4.656-.887a23.388,23.388,0,0,1-4.213,1.109c-.887,0-1.109.665-1.109,1.552A31.306,31.306,0,0,0,5.991,22.851Z" transform="translate(0 9.859)" fill="#175783" />
                <path d="M34.289,13.864A10.557,10.557,0,0,1,23.646,24.729C17.66,24.507,13,20.738,13,13.864,12.782,7.213,17.882,3,23.868,3A10.514,10.514,0,0,1,34.289,13.864ZM23.646,20.516a6.8,6.8,0,0,0,6.873-6.208A6.057,6.057,0,0,0,28.746,9.43a5.727,5.727,0,0,0-4.656-2c-1.774.222-3.548.222-5.1,1.774a6.717,6.717,0,0,0-2.217,4.878A6.833,6.833,0,0,0,23.646,20.516Z" transform="translate(13.385 0)" fill="#175783" />
                <path d="M18.274,10.634l2.661,2.661c0,.222-.222.222-.222.443a1.661,1.661,0,0,1-1.109-.222,40.972,40.972,0,0,1-3.1-3.1c1.33-1.109,2.882.222,3.548-1.552H16.722c.665-1.774,2.217-.665,3.326-1.33C19.382,5.978,17.83,7.087,16.5,6.2h5.1L20.491,7.309c.443.222.665.665,1.33.887C20.934,9.526,20.048,10.634,18.274,10.634Z" transform="translate(17.649 3.895)" fill="#175783" />
            </g>
        </SvgIcon>
    );
}