import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { useNavigate, useParams, } from "react-router-dom";
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import ClearIcon from '@mui/icons-material/Clear';
import Dialog from '@mui/material/Dialog';
import SomethingWentWrong from '../../Components/SomethingWentWrong';
import './style.css';

const ViewCommissionModel = () => {
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    let { commission_id } = useParams();

    const [loading, setLoading] = useState(false);
    const [providerValue, setProviderValue] = useState({});
    const [errorDialog, setErrorDialog] = useState(false);

    const handleCloseModal = () => {
        navigate(-1);
    };

    const fetchCommissionValue = useCallback(async () => {
        try {
            setLoading(true);
            let url = `commissions/${commission_id}`;
            let options = {
                method: 'GET',
                url
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setProviderValue(response.data.data);
                } else {
                    // console.error('err.res', response)
                }
                setLoading(false);
            }).catch(err => {
                if (err.response) {
                    setLoading(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setLoading(false);
            setErrorDialog(true);
            // console.error('error', error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commission_id]);

    useEffect(() => {
        fetchCommissionValue();
    }, [fetchCommissionValue])

    return (
        <Fragment>
            <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={true}
                onClose={handleCloseModal}
            >
                <Box component="div" className={'BBPVCMDialog'}>
                    <Box component="div" className={'BBPVCMDHead'}>
                        <Box component="div" className={'BBPVCMDHTitle'}>
                            Commissions Details
                        </Box>
                        <IconButton onClick={handleCloseModal}>
                            <ClearIcon />
                        </IconButton>
                    </Box>
                    {loading ?
                        <Box component="div" className={'BBPVCMDInfo'}>
                            <Box component="div" className={'BBPVCMDIRow'}>
                                <Box component="div" className={'BBPVCMDIRCol'}>
                                    <Box component="div" className={'BBPVCMDIRCLabel'}>
                                        Biller Category
                                    </Box>
                                    <Box component="div" className={'BBPVCMDIRCTitle'}>
                                        <Skeleton variant="rounded" height={22} />
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPVCMDIRCol'}>
                                    <Box component="div" className={'BBPVCMDIRCLabel'}>
                                        Biller Name
                                    </Box>
                                    <Box component="div" className={'BBPVCMDIRCTitle'}>
                                        <Skeleton variant="rounded" height={22} />
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPVCMDIRCol'}>
                                    <Box component="div" className={'BBPVCMDIRCLabel'}>
                                        Commission For
                                    </Box>
                                    <Box component="div" className={'BBPVCMDIRCTitle'}>
                                        <Skeleton variant="rounded" height={22} />
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPVCMDIRCol'}>
                                    <Box component="div" className={'BBPVCMDIRCLabel'}>
                                        Range
                                    </Box>
                                    <Box component="div" className={'BBPVCMDIRCTitle'}>
                                        <Skeleton variant="rounded" height={22} />
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPVCMDIRCol'}>
                                    <Box component="div" className={'BBPVCMDIRCLabel'}>
                                        Range Value
                                    </Box>
                                    <Box component="div" className={'BBPVCMDIRCTitle'}>
                                        <Skeleton variant="rounded" height={22} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        :
                        <Box component="div" className={'BBPVCMDInfo'}>
                            <Box component="div" className={'BBPVCMDIRow'}>
                                <Box component="div" className={'BBPVCMDIRCol'}>
                                    <Box component="div" className={'BBPVCMDIRCLabel'}>
                                        Biller Category
                                    </Box>
                                    <Box component="div" className={'BBPVCMDIRCTitle'}>
                                        {providerValue.cat ? providerValue.cat.name : '- - -'}
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPVCMDIRCol'}>
                                    <Box component="div" className={'BBPVCMDIRCLabel'}>
                                        Biller Name
                                    </Box>
                                    <Box component="div" className={'BBPVCMDIRCTitle'}>
                                        {providerValue.prov ? providerValue.prov.name : '- - -'}
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPVCMDIRCol'}>
                                    <Box component="div" className={'BBPVCMDIRCLabel'}>
                                        Commission For
                                    </Box>
                                    <Box component="div" className={'BBPVCMDIRCTitle'}>
                                        {providerValue.commission_for}
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPVCMDIRCol'}>
                                    <Box component="div" className={'BBPVCMDIRCLabel'}>
                                        Range
                                    </Box>
                                    <Box component="div" className={'BBPVCMDIRCTitle'}>
                                        {providerValue.range ? 'Yes' : 'No'}
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPVCMDIRCol BBPVCMDIRCFull'}>
                                    <Box component="div" className={'BBPVCMDIRCLabel'}>
                                        Range Value
                                    </Box>
                                    <Box component="div" className={'BBPVCMDIRCTitle'}>
                                        {providerValue.range ?
                                            <Fragment>
                                                {providerValue.range_val.map((item, index) =>
                                                    <Box component="div" className={'BBPVCMDIRCTText'} key={index}>
                                                        {`${item.from} to ${item.to} = ${item.comm_val}${item.comm_type === 'Percentage' ? '%' : ''}`}
                                                    </Box>
                                                )}
                                            </Fragment>
                                            :
                                            `${providerValue.comm_val}${providerValue.comm_type === 'Percentage' ? '%' : ''}`
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    }
                    <Box component="div" className={'BBPVCMDBtn'}>
                        <Button variant="contained" onClick={handleCloseModal}>Close</Button>
                    </Box>
                </Box>
            </Dialog>
        </Fragment>
    );
};
export default ViewCommissionModel;
