import React from 'react';
import Box from '@mui/material/Box';
import TJSBLogo from '../../Assets/Images/tjsb.jpg';
import Mobi from '../../Assets/Images/mobi.png';
import './style.css';

const PoweredBy = ({ isMobiKwik, isFinacus }) => {

    return (
        <Box component="div" className={'BBPBPPoweredBy'}>
            <Box component="div" className={'BBPBPPBTitle'}>
                Powered by
            </Box>
            <Box component="div" className={'BBPBPPBLogo'}>
                {isFinacus && <img src={TJSBLogo} alt={'TJSB'} />}
                {isMobiKwik && <img src={Mobi} alt={'MobiKwik'} />}
            </Box>
        </Box>
    );
};
export default PoweredBy;  