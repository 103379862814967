import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function VehicleIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 64 64" fontSize="inherit">
            <g transform="translate(9.578 12.184)">
                <path d="M9.08,33.064v3.194a8.134,8.134,0,0,1-3.006.188c-1.691,0-2.067-.752-2.067-2.443V15.026c-.94-.94-1.879-2.067-3.006-3.194L3.443,9.389c.94.752,1.691,1.5,2.819,2.443C7.389,9.577,8.7,7.51,9.831,5.255A3.773,3.773,0,0,1,13.589,3c6.389.188,12.777,0,18.978,0a3.306,3.306,0,0,1,3.194,1.879c.752,1.5,1.5,3.194,2.443,4.7a10.189,10.189,0,0,1,1.127,2.067,28.9,28.9,0,0,0,2.443-2.255,28.112,28.112,0,0,1,2.067,2.443c-.564.564-1.315,1.5-2.067,2.443a3.111,3.111,0,0,0-.94,2.443V34.756a1.854,1.854,0,0,1-1.691,1.879H35.762V32.877H9.831C9.644,32.689,9.268,32.877,9.08,33.064ZM7.389,28.931c3.194.376,28.373.188,30.064-.188v-10.9c-.376,0-.564-.188-.752-.188H7.765a.184.184,0,0,0-.188.188l-.188.188Zm7.7-14.844c-1.879-1.691-2.255-3.382-.94-4.7a2.4,2.4,0,0,1,3.758.376c1.127,1.315.564,2.819-1.315,4.134,2.255.376,9.771.376,11.086,0-.376-.564-.94-1.127-1.315-1.691-.564-.94,0-1.879.564-2.819a2.05,2.05,0,0,1,2.443-.564,2.647,2.647,0,0,1,1.879,2.443c0,1.315-.752,2.067-2.067,2.819h6.952C34.822,11.456,33.7,9.2,32.568,6.758H12.65L8.7,14.086Z" fill="#175783" />
                <path d="M8.479,16.47c-.94-.376-1.879-.564-1.879-1.691A2,2,0,0,1,8.291,12.9C10.358,14.215,10.358,14.779,8.479,16.47Z" transform="translate(4.923 8.702)" fill="#175783" />
                <path d="M18.379,12.9c1.879,1.5,1.879,1.879,0,3.57-.752-.376-1.879-.376-1.879-1.691A2.013,2.013,0,0,1,18.379,12.9Z" transform="translate(13.625 8.702)" fill="#175783" />
            </g>
        </SvgIcon>
    );
}