import React, { Fragment, useState, useRef, useCallback, useLayoutEffect, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { format } from 'date-fns';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import Skeleton from '@mui/material/Skeleton';
import { useAxiosPrivate } from '../../../Hooks/useAxiosPrivate';
import SomethingWentWrong from '../../../Components/SomethingWentWrong';
import NoData from '../../../Components/NoData';
import NoDataImg from '../../../Assets/Images/no-data.png';
import ViewCompliant from '../../../Components/RVEComplaint/viewComplaint';
import './style.css';

const ComplaintsTab = () => {

    let location = useLocation();
    const axiosPrivate = useAxiosPrivate();

    const mobile_number = location.state && location.state.mobile_number;

    const boxHeightRef = useRef(null);
    const [allComplaints, setAllComplaints] = useState([]);
    const [totalData, setSetTotalData] = useState(0);
    const [selectedPage, setSelectedPage] = useState(1);
    const [limitData] = useState(20);
    const [apiParams] = useState({ limit: limitData });
    const [hasMoreData, setHasMoreData] = useState(true);
    const [loading, setLoading] = useState(true);
    const [boxHeigh, setBoxHeigh] = useState(null);
    const [errorDialog, setErrorDialog] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [viewCompliant, setViewCompliant] = useState(false);
    const [compliantInfo, setCompliantInfo] = useState({});

    const ViewCompliantOpen = (info) => {
        setCompliantInfo(info);
        setViewCompliant(true);
    };

    const ViewCompliantClose = () => {
        setCompliantInfo({});
        setViewCompliant(false);
    };

    const fetchComplaints = useCallback(async (params) => {
        try {
            let urlParams = '';
            if (params) {
                Object.keys(params).forEach(function (key, index) {
                    urlParams += (index === 0 ? '?' : '&') + key + '=' + params[key];
                });
            }
            let url = `complaints/${mobile_number}/${urlParams}`;
            let options = {
                method: 'GET',
                url
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setAllComplaints(allComplaints.concat(Array.from(response.data.data)));
                    setSelectedPage(parseInt(params.page + 1));
                    setSetTotalData(response.data.total);
                } else {
                    setAllComplaints([]);
                }
                setLoading(false);
            }).catch(err => {
                if (err.response) {
                    setLoading(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setLoading(false);
            setErrorDialog(true);
            // console.error('error', error)
        }
        // eslint-disable-next-line 
    }, [allComplaints]);

    const handleFetch = useCallback(() => {
        let prm = apiParams;
        prm.page = selectedPage;
        if (allComplaints.length !== totalData) {
            fetchComplaints(prm, false);
        } else {
            setHasMoreData(false);
        }
    }, [allComplaints.length, apiParams, fetchComplaints, selectedPage, totalData]);

    useEffect(() => {
        if (firstLoad) {
            fetchComplaints({ limit: limitData, page: selectedPage });
            setFirstLoad(false);
        }
    }, [fetchComplaints, firstLoad, limitData, selectedPage]);

    useLayoutEffect(() => {
        /**
         * To measure box height, Using ref [useRef hooks] object to measure height and
         * setting it into states which is being used to set height of infinite scroll bar
         */
        function updateSize() {
            if (boxHeightRef.current) {
                const height = boxHeightRef.current.getBoundingClientRect().height;
                setBoxHeigh(height);
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [boxHeightRef, boxHeigh]);


    return (
        <Fragment>
            <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
            <ViewCompliant
                open={viewCompliant}
                onClose={ViewCompliantClose}
                info={compliantInfo}
            />
            <Box component="div" className={"BBPPWAComplTab"}>
                <Box component="div" className={"BBPPWACTTitle"}>
                    All Complaints
                </Box>
                <Box component="div" className={"BBPPWACBody"} ref={boxHeightRef}>
                    {loading ?
                        <Box component="div" className={"BBPPWACBList"}>
                            {Array.from(Array(15).keys()).map((item) => (
                                <Box component="div" className={'BBPPWACBLItem'} key={item} >
                                    <Box component="div" className={'BBPPWACBLIInner'}>
                                        <Box component="div" className={'BBPPWACBLIInfo'}>
                                            <Box component="div" className={'BBPPWACBLIITitle'}>
                                                <Box component="div" className={'BBPPWACBLIIText'}>
                                                    <Skeleton component="div" variant="rounded" height={22} />
                                                </Box>
                                                <Box className={'BBPPWACBLIIPrice'}>
                                                    <Skeleton component="div" variant="rounded" height={22} />
                                                </Box>
                                            </Box>
                                            <Box component="div" className={'BBPPWACBLIIDV'}>
                                                <Box component="div" className={'BBPPWACBLIIDate'}>
                                                    <Skeleton component="div" variant="rounded" height={22} />
                                                </Box>
                                                <Box component="div" className={'BBPPWACBLIIStatus'}>
                                                    <Skeleton component="div" variant="rounded" height={22} />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                        :
                        <Fragment>
                            {allComplaints.length > 0 ?
                                <InfiniteScroll
                                    className={'BBPPWACBList'}
                                    dataLength={allComplaints.length}
                                    next={handleFetch}
                                    hasMore={hasMoreData}
                                    loader={
                                        <Fragment>
                                            {Array.from(Array(15).keys()).map((item) => (
                                                <Box component="div" className={'BBPPWACBLItem'} key={item} >
                                                    <Box component="div" className={'BBPPWACBLIInner'}>
                                                        <Box component="div" className={'BBPPWACBLIInfo'}>
                                                            <Box component="div" className={'BBPPWACBLIITitle'}>
                                                                <Box component="div" className={'BBPPWACBLIIText'}>
                                                                    <Skeleton component="div" variant="rounded" height={22} />
                                                                </Box>
                                                                <Box className={'BBPPWACBLIIPrice'}>
                                                                    <Skeleton component="div" variant="rounded" height={22} />
                                                                </Box>
                                                            </Box>
                                                            <Box component="div" className={'BBPPWACBLIIDV'}>
                                                                <Box component="div" className={'BBPPWACBLIIDate'}>
                                                                    <Skeleton component="div" variant="rounded" height={22} />
                                                                </Box>
                                                                <Box component="div" className={'BBPPWACBLIIStatus'}>
                                                                    <Skeleton component="div" variant="rounded" height={22} />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Fragment>
                                    }
                                    height={boxHeigh ? boxHeigh : 400}
                                >
                                    {allComplaints.map((item, index) =>
                                        <ListItemButton className={`BBPPWACBLItem ${item.status.replaceAll(/\s/g, '')}`} key={index} onClick={() => { ViewCompliantOpen(item); }}>
                                            <Box component="div" className={"BBPPWACBLIInner"}>
                                                <Box component="div" className={"BBPPWACBLIInfo"}>
                                                    <Box component="div" className={"BBPPWACBLIITitle"}>
                                                        <Box component="div" className={"BBPPWACBLIIText"}>{item.reason}</Box>
                                                    </Box>
                                                    <Box component="div" className={"BBPPWACBLIIDV"}>
                                                        <Box component="div" className={"BBPPWACBLIIDate"}>{format(new Date(item.date), "dd-MM-yyyy")}</Box>
                                                        <Box component="div" className={"BBPPWACBLIIStatus"}>{item.status}</Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </ListItemButton>
                                    )}
                                </InfiniteScroll>
                                :
                                <Box component="div" className={'BBPPWACNot'}>
                                    <NoData
                                        image={NoDataImg}
                                        title={'No Complaints Raised.'}
                                        description={' '}
                                    />
                                </Box>
                            }
                        </Fragment>
                    }
                </Box>
            </Box>
        </Fragment>
    );
};
export default ComplaintsTab; 