import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import FormTextField from '../../../../Components/FormFields/TextField';
import { dateFormatter } from '../../../../Utils/index';

const UpiStatus = ({ providerDetails, billDetail, allOpt, upiAddress, handleUPIAddress, upiError, paymentFailMassage, handleBackToHome, payLoader, handleAddTransactions }) => {

    const viewBillDetails = (provider) => {
        switch (provider) {
            case 'Finacus':
                return <FinacusBillDetails
                    providerDetails={providerDetails}
                    billDetail={billDetail}
                    allOpt={allOpt}
                />
            case 'Mobikwik':
                return <MobikwikBillDetails
                    providerDetails={providerDetails}
                    billDetail={billDetail}
                    allOpt={allOpt}
                />
            default:
                break;
        }
    }

    return (
        <Fragment>
            <Box component="div" className={'BBPBPPPTPBU'}>
                <Box component="div" className={'BBPBPPPTPBUInfo'}>
                    <Box component="div" className={'BBPBPPPTPBUInput'}>
                        <Box component="div" className={'BBPBPPPTPBUIInner'}>
                            <FormTextField
                                placeholder={'Enter VPA / UPI ID'}
                                classes={{ root: 'BBPBPPPTPBUIField' }}
                                value={upiAddress}
                                onChange={handleUPIAddress}
                                error={upiError}
                                disabled={payLoader}
                            />
                        </Box>
                    </Box>
                </Box>
                {paymentFailMassage.length > 0 ?
                    <Box component="div" className={'BBPBPPPTPPError'}>
                        {paymentFailMassage}
                    </Box>
                    : ''}
                <Box component="div" className={'BBPBPPPTPPBtn'}>
                    <Button variant="contained" className={'BBPButton'} onClick={handleBackToHome}>Cancel</Button>
                    <LoadingButton
                        variant="contained"
                        className={'BBPButton'}
                        disabled={upiAddress.length === 0 || payLoader}
                        loading={payLoader}
                        onClick={handleAddTransactions}
                    >
                        Pay
                    </LoadingButton>
                </Box>
            </Box>
            <Box component="div" className={'BBPBPPPTBD'}>
                {viewBillDetails(providerDetails.api_provider)}
            </Box>
        </Fragment>
    );
};
export default UpiStatus;


const FinacusBillDetails = ({ billDetail, allOpt, providerDetails }) => {
    return (
        <Box component="div" className={'BBPBPPPTBDetails'}>
            {billDetail.BillAmount ?
                <Box component="div" className={'BBPBPPPTBDBAmt'}>
                    <Box component="div" className={'BBPBPPPTBDBAInner'}>
                        <Box component="div" className={'BBPBPPPTBDBAIAmt'}>
                            {billDetail.BillAmount}
                        </Box>
                        <Box component="div" className={'BBPBPPPTBDBAIATitle'}>
                            Total Due Amount
                        </Box>
                    </Box>
                </Box> : ''}
            {providerDetails ?
                <Box component="div" className={'BBPBPPPTBDIBox'}>
                    <Box component="div" className={'BBPBPPPTBDIBTitle'}>
                        Bill Category
                    </Box>
                    <Box component="div" className={'BBPBPPPTBDIBSTitle'}>
                        {allOpt ? allOpt[0].name : ''}
                    </Box>
                </Box> : ''}
            {providerDetails ?
                <Box component="div" className={'BBPBPPPTBDIBox'}>
                    <Box component="div" className={'BBPBPPPTBDIBTitle'}>
                        Biller Name
                    </Box>
                    <Box component="div" className={'BBPBPPPTBDIBSTitle'}>
                        {providerDetails.name}
                    </Box>
                </Box> : ''}
            {billDetail.BillPeriod ?
                <Box component="div" className={'BBPBPPPTBDIBox'}>
                    <Box component="div" className={'BBPBPPPTBDIBTitle'}>
                        Bill Period
                    </Box>
                    <Box component="div" className={'BBPBPPPTBDIBSTitle'}>
                        {billDetail.BillPeriod}
                    </Box>
                </Box> : ''}
            {billDetail.BillDate ?
                <Box component="div" className={'BBPBPPPTBDIBox'}>
                    <Box component="div" className={'BBPBPPPTBDIBTitle'}>
                        Bill Date
                    </Box>
                    <Box component="div" className={'BBPBPPPTBDIBSTitle'}>
                        {dateFormatter(billDetail.BillDate)}
                    </Box>
                </Box> : ''}
            {billDetail.BillDueDate ?
                <Box component="div" className={'BBPBPPPTBDIBox'}>
                    <Box component="div" className={'BBPBPPPTBDIBTitle'}>
                        Due Date
                    </Box>
                    <Box component="div" className={'BBPBPPPTBDIBSTitle'}>
                        {dateFormatter(billDetail.BillDueDate)}
                    </Box>
                </Box> : ''}
            {billDetail.BillNumber ?
                <Box component="div" className={'BBPBPPPTBDIBox'}>
                    <Box component="div" className={'BBPBPPPTBDIBTitle'}>
                        Bill Number
                    </Box>
                    <Box component="div" className={'BBPBPPPTBDIBSTitle'}>
                        {billDetail.BillNumber}
                    </Box>
                </Box> : ''}
            {billDetail.AccountNumber ?
                <Box component="div" className={'BBPBPPPTBDIBox'}>
                    <Box component="div" className={'BBPBPPPTBDIBTitle'}>
                        Account Number
                    </Box>
                    <Box component="div" className={'BBPBPPPTBDIBSTitle'}>
                        {billDetail.AccountNumber}
                    </Box>
                </Box> : ''}
            {billDetail.CustomerName ?
                <Box component="div" className={'BBPBPPPTBDIBox'}>
                    <Box component="div" className={'BBPBPPPTBDIBTitle'}>
                        Account Holder
                    </Box>
                    <Box component="div" className={'BBPBPPPTBDIBSTitle'}>
                        {billDetail.CustomerName}
                    </Box>
                </Box> : ''}
        </Box>
    );
}

const MobikwikBillDetails = ({ billDetail, allOpt, providerDetails }) => {
    return (
        <Box component="div" className={'BBPBPPPTBDetails'}>
            {billDetail.billAmount ?
                <Box component="div" className={'BBPBPPPTBDBAmt'}>
                    <Box component="div" className={'BBPBPPPTBDBAInner'}>
                        <Box component="div" className={'BBPBPPPTBDBAIAmt'}>
                            {billDetail.billAmount}
                        </Box>
                        <Box component="div" className={'BBPBPPPTBDBAIATitle'}>
                            Total Due Amount
                        </Box>
                    </Box>
                </Box> : ''}
            {providerDetails ?
                <Box component="div" className={'BBPBPPPTBDIBox'}>
                    <Box component="div" className={'BBPBPPPTBDIBTitle'}>
                        Bill Category
                    </Box>
                    <Box component="div" className={'BBPBPPPTBDIBSTitle'}>
                        {allOpt ? allOpt[0].name : ''}
                    </Box>
                </Box> : ''}
            {providerDetails ?
                <Box component="div" className={'BBPBPPPTBDIBox'}>
                    <Box component="div" className={'BBPBPPPTBDIBTitle'}>
                        Biller Name
                    </Box>
                    <Box component="div" className={'BBPBPPPTBDIBSTitle'}>
                        {providerDetails.name}
                    </Box>
                </Box> : ''}
            {billDetail.billNumber ?
                <Box component="div" className={'BBPBPPPTBDIBox'}>
                    <Box component="div" className={'BBPBPPPTBDIBTitle'}>
                        Bill Number
                    </Box>
                    <Box component="div" className={'BBPBPPPTBDIBSTitle'}>
                        {billDetail.billNumber}
                    </Box>
                </Box> : ''}
            {billDetail.billnetamount ?
                <Box component="div" className={'BBPBPPPTBDIBox'}>
                    <Box component="div" className={'BBPBPPPTBDIBTitle'}>
                        Bill Net Amount
                    </Box>
                    <Box component="div" className={'BBPBPPPTBDIBSTitle'}>
                        {billDetail.billnetamount}
                    </Box>
                </Box> : ''}
            {billDetail.dueFrom ?
                <Box component="div" className={'BBPBPPPTBDIBox'}>
                    <Box component="div" className={'BBPBPPPTBDIBTitle'}>
                        Due From
                    </Box>
                    <Box component="div" className={'BBPBPPPTBDIBSTitle'}>
                        {billDetail.dueFrom}
                    </Box>
                </Box> : ''}
            {billDetail.dueTo ?
                <Box component="div" className={'BBPBPPPTBDIBox'}>
                    <Box component="div" className={'BBPBPPPTBDIBTitle'}>
                        Due To
                    </Box>
                    <Box component="div" className={'BBPBPPPTBDIBSTitle'}>
                        {billDetail.dueTo}
                    </Box>
                </Box> : ''}
            {billDetail.billdate ?
                <Box component="div" className={'BBPBPPPTBDIBox'}>
                    <Box component="div" className={'BBPBPPPTBDIBTitle'}>
                        Bill Date
                    </Box>
                    <Box component="div" className={'BBPBPPPTBDIBSTitle'}>
                        {dateFormatter(billDetail.billdate)}
                    </Box>
                </Box> : ''}
            {billDetail.dueDate ?
                <Box component="div" className={'BBPBPPPTBDIBox'}>
                    <Box component="div" className={'BBPBPPPTBDIBTitle'}>
                        Due Date
                    </Box>
                    <Box component="div" className={'BBPBPPPTBDIBSTitle'}>
                        {dateFormatter(billDetail.dueDate)}
                    </Box>
                </Box> : ''}
            {billDetail.userName ?
                <Box component="div" className={'BBPBPPPTBDIBox'}>
                    <Box component="div" className={'BBPBPPPTBDIBTitle'}>
                        User Name
                    </Box>
                    <Box component="div" className={'BBPBPPPTBDIBSTitle'}>
                        {billDetail.userName}
                    </Box>
                </Box> : ''}
            {billDetail.cellNumber ?
                <Box component="div" className={'BBPBPPPTBDIBox'}>
                    <Box component="div" className={'BBPBPPPTBDIBTitle'}>
                        Cell Number
                    </Box>
                    <Box component="div" className={'BBPBPPPTBDIBSTitle'}>
                        {billDetail.cellNumber}
                    </Box>
                </Box> : ''}
        </Box>
    );
}