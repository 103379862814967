import React, { useState, useEffect, useCallback } from "react";
import { Outlet, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Box from '@mui/material/Box';
import axios from '../Api/axios';
import { useAuth } from '../Hooks/useAuth';
import NoData from '../Components/NoData';
import NoDataImg from '../Assets/Images/not-allow.png';

const PwaAuth = () => {

    let location = useLocation();

    const { auth, setAuth } = useAuth();

    const [loading, setLoading] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const handleLogin = useCallback(async () => {
        let data = {
            'email': 'pwa@shulkpay.com',
            'password': '*!xw^wvnc73@vbnhB6@p9@@sS'
        }
        try {
            let url = `auth/login`;
            let options = {
                method: 'POST',
                url,
                data,
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            };
            await axios(options).then(response => {
                if (response.data.status === 1) {
                    let temToken = response.data.token.replace('Bearer', '');
                    let decoded = jwt_decode(temToken);
                    const token = response.data.token;
                    const id = decoded.id;
                    const name = decoded.name;
                    const image = decoded.image;
                    const permissions = decoded.permissions;
                    const role = [decoded.role];
                    setAuth({ name, image, id, permissions, role, token });

                }
            }).catch(err => {
                if (err.response) {
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            // console.error('error', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleAuth = async () => {
        let pwaAuth = new URLSearchParams(location.search).get('auth');
        let pwaMob = new URLSearchParams(location.search).get('mob');
        setLoading(true);
        if (pwaAuth && pwaMob) {
            try {
                let decoded = jwt_decode(pwaAuth);
                if (decoded.pwa && decoded.pwa === `${process.env.REACT_APP_BBPS_PWA_SECERET}`) {
                    await handleLogin();
                    setIsAuthenticated(true);
                } else {
                    setIsAuthenticated(false);
                }
            } catch (error) {
                setIsAuthenticated(false);
            }
        } else {
            setIsAuthenticated(false);
        }
        setLoading(false);
    }

    useEffect(() => {
        handleAuth();
        // eslint-disable-next-line
    }, []);

    return (
        loading ? '' :
            auth && auth.token && isAuthenticated ?
                <Outlet /> :
                <Box component={'div'} className={'BBPPwaAuth'}>
                    <Box component={'div'} className={'BBPPAInner'}>
                        <NoData title={'Not Allowed'} image={NoDataImg} />
                    </Box>
                </Box>

    )
}

export default PwaAuth
