import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import { usePapaParse } from 'react-papaparse';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SearchBox from '../../Components/SearchBox';
import DevTable from '../../Components/DevTable';
import DateRangePicker from '../../Components/DateRangePicker';
import SomethingWentWrong from '../../Components/SomethingWentWrong';
import SelectOption from '../../Components/FormFields/SelectOption';
import Backdrops from '../../Components/Backdrops';
import { format } from 'date-fns';
import { dateFormatter } from '../../Utils/index';

import './style.css';

function parseIcsDate(icsDate) {
    if (icsDate) {
        var year = icsDate.substr(0, 4);
        var month = icsDate.substr(4, 2);
        var day = icsDate.substr(6, 2);

        var hour = icsDate.substr(8, 2);
        var minute = icsDate.substr(10, 2);
        var second = icsDate.substr(12, 2);

        let tempDate = new Date(year, month - 1, day, hour, minute, second);

        let formatDate = format(tempDate, "dd-MM-yyyy");
        return formatDate;
    } else {
        return '';
    }
}

const ICICITable = () => {

    const { jsonToCSV } = usePapaParse();

    const axiosPrivate = useAxiosPrivate();
    const [errorDialog, setErrorDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [dataStartDate, setDataStartDate] = useState();
    const [totalData, setTotalData] = useState(0);
    const [selectedPage, setSelectedPage] = useState(1);
    const [selectedStatus, setSelectedStatus] = useState('all');
    const [limitData] = useState(20);
    const [onDownloadLoading, setOnDownloadLoading] = useState(false);

    const [rowData, setRowData] = useState([]);

    const [apiParams] = useState({ limit: limitData });

    const [mainColumns, setMainColumns] = useState({
        columns: [
            {
                name: 'merchantTranId',
                title: 'Transaction ID',
                width: 150,
                sorting: false,
                direction: ''
            },
            {
                name: 'transDate',
                title: 'Transaction Date',
                width: 150,
                sorting: true,
                direction: ''
            },
            {
                name: 'billNumber',
                title: 'Bill Number',
                width: 150,
                sorting: false,
                direction: ''
            },
            {
                name: 'type',
                title: 'Transaction Type',
                width: 150,
                sorting: false,
                direction: ''
            },
            {
                name: 'payerVa',
                title: 'UPI ID',
                width: 150,
                sorting: false,
                direction: ''
            },
            {
                name: 'amount',
                title: 'Transaction Amount',
                width: 150,
                sorting: false,
                direction: ''
            },
            {
                name: 'collectByDate',
                title: 'Collect By Date',
                width: 150,
                sorting: false,
                direction: ''
            },
            {
                name: 'TxnInitDate',
                title: 'Txn Init Date',
                width: 150,
                sorting: false,
                direction: ''
            },
            {
                name: 'TxnCompletionDate',
                title: 'Txn Completion Date',
                width: 150,
                sorting: false,
                direction: ''
            },
            {
                name: 'status',
                title: 'Status',
                width: 150,
                sorting: true,
                direction: ''
            },
        ]
    });

    const fetchTransactions = useCallback(async (params) => {
        try {
            let urlParams = '';
            if (params) {
                Object.keys(params).forEach(function (key, index) {
                    urlParams += (index === 0 ? '?' : '&') + key + '=' + params[key];
                });
            }
            let url = `reports/getIciciTransactions${urlParams}`;
            let options = {
                method: 'GET',
                url
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setRowData(response.data.data);
                    setTotalData(response.data.total);
                    setLoading(false);
                } else {
                    setRowData([]);
                    setTotalData(0);
                    setLoading(false);
                }
                setDataStartDate(new Date(response.data.firstDate));
            }).catch(err => {
                if (err.response) {
                    setLoading(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setLoading(false);
            setErrorDialog(true);
            // console.error('error', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handledFilterSelect = (val) => {
        setSelectedStatus(val);
        let prm = apiParams;
        prm.status = val;
        fetchTransactions(prm);
    };

    const handleSearch = useCallback((value) => {
        setLoading(true);
        let prm = apiParams;
        setSelectedPage(1);
        prm.page = 1;
        if (value.length > 0) {
            prm.search = value;
        } else {
            delete prm.search;
        }
        fetchTransactions(prm);
    }, [apiParams, fetchTransactions])

    const handlePaginate = (value) => {
        let prm = apiParams;
        prm.page = value;
        setSelectedPage(value);
        setLoading(true);
        fetchTransactions(prm);
    };

    const handleSorting = (name, index) => {
        let prm = apiParams;
        let tempCol = mainColumns.columns;
        if (tempCol[index].direction === '') {
            tempCol.map((column) => (column.name === name ? column.direction = 'ASC' : column.direction = ''));
            prm.sort_by = name;
            prm.order = 1;
            prm.page = 1;
        } else if (tempCol[index].direction === 'ASC') {
            tempCol.map((column) => (column.name === name ? column.direction = 'DSC' : column.direction = ''));
            prm.sort_by = name;
            prm.order = -1;
            prm.page = 1;
        } else if (tempCol[index].direction === 'DSC') {
            tempCol.map((column) => column.direction = '');
            prm.page = 1;
            delete prm.sort_by;
            delete prm.order;
        }
        setLoading(true);
        setMainColumns({ columns: tempCol });
        setSelectedPage(1);
        fetchTransactions(prm);
    }

    function generateRows(tempArray) {
        const tempRowArray = [];
        if (tempArray) {
            tempArray.map((item) =>
                tempRowArray.push({
                    merchantTranId: (<Box component="div" className="BBPDTSText">{item.merchantTranId}</Box>),
                    transDate: (<Box component="div" className="BBPDTSText">{dateFormatter(item.createdAt)}</Box>),
                    billNumber: (<Box component="div" className="BBPDTSText">{item.billNumber}</Box>),
                    type: (<Box component="div" className="BBPDTSText">{item.type}</Box>),
                    payerVa: (<Box component="div" className="BBPDTSText">{item.payerVa}</Box>),
                    amount: (<Box component="div" className="BBPDTSText">{item.amount}</Box>),
                    collectByDate: (<Box component="div" className="BBPDTSText">{dateFormatter(item.collectByDate)}</Box>),
                    TxnInitDate: (<Box component="div" className="BBPDTSText">{parseIcsDate(item.TxnInitDate)}</Box>),
                    TxnCompletionDate: (<Box component="div" className="BBPDTSText">{parseIcsDate(item.TxnInitDate)}</Box>),
                    status: (<Box component="div" className="BBPDTChips"><Box component="div" className={"BBPDTCChip " + item.status}>{item.status}</Box></Box>),
                }),
            );
        }
        return tempRowArray;
    }

    const handleCSVDownload = useCallback(async () => {
        try {
            setOnDownloadLoading(true);
            let urlParams = '';
            if (apiParams) {
                delete apiParams.page;
                Object.keys(apiParams).forEach(function (key, index) {
                    urlParams += (index === 0 ? '?' : '&') + key + '=' + (key === 'limit' ? totalData : apiParams[key]);
                });
            }
            let url = `reports/getIciciTransactions${urlParams}`;
            let options = {
                method: 'GET',
                url
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setRowData(response.data.data);
                    setTotalData(response.data.total);
                    const tempDownloadArr = [];
                    const tempHeader = {};
                    mainColumns.columns.map((item) => tempHeader[item.name] = item.title);
                    response.data.data.map((item) =>
                        tempDownloadArr.push({
                            merchantTranId: item.merchantTranId,
                            transDate: format(new Date(item.createdAt), "dd-MM-yyyy"),
                            billNumber: item.billNumber,
                            type: item.type,
                            payerVa: item.payerVa,
                            amount: item.amount,
                            collectByDate: dateFormatter(item.collectByDate),
                            TxnInitDate: parseIcsDate(item.TxnInitDate),
                            TxnCompletionDate: parseIcsDate(item.TxnInitDate),
                            status: item.status,
                        })
                    );
                    tempDownloadArr.unshift(tempHeader);
                    const csv = jsonToCSV(tempDownloadArr, { header: false });
                    let filename = `${'icici'}-${format(new Date(), 'yyyy-MM-dd-HH:mm:ss')}.csv`;
                    var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                    var csvURL = null;
                    if (navigator.msSaveBlob) {
                        csvURL = navigator.msSaveBlob(csvData, filename);
                    }
                    else {
                        csvURL = window.URL.createObjectURL(csvData);
                    }
                    var tempLink = document.createElement('a');
                    tempLink.href = csvURL;
                    tempLink.setAttribute('download', filename);
                    tempLink.click();
                    setOnDownloadLoading(false);
                } else {
                    setRowData([]);
                    setTotalData(0);
                    setOnDownloadLoading(false);
                }
            }).catch(err => {
                if (err.response) {
                    setErrorDialog(true);
                    setOnDownloadLoading(false);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setErrorDialog(true);
            setOnDownloadLoading(false);
            // console.error('error', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalData]);

    const handleDateFilter = (date) => {
        let prm = apiParams;
        prm.startDate = format(date.startDate, "yyyy-MM-dd");
        prm.endDate = format(date.endDate, "yyyy-MM-dd");
        setLoading(true);
        fetchTransactions(prm);
    };

    const handleRestDateFilter = () => {
        let prm = apiParams;
        delete prm.startDate;
        delete prm.endDate;
        setLoading(true);
        fetchTransactions(prm);
    };

    useEffect(() => {
        fetchTransactions(apiParams);
        // eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
            <Backdrops
                open={onDownloadLoading}
                title={'Downloading'}
            />
            <Box component='div' className={'BBPTransaction'}>
                <Box component='div' className={'BBPTHead'}>
                    <SearchBox
                        onSearchChange={handleSearch}
                        placeholder={'Search Transaction'}
                        searchTooltip={'Searching from Transaction ID, Bill Number and UPI ID'}
                    />
                    <Box component='div' className={'BBPTHBtn'}>
                        <SelectOption
                            customClass={'BBPTHDrop'}
                            selectedOption={selectedStatus}
                            options={['all', 'success', 'pending', 'failed']}
                            handledSelect={handledFilterSelect}
                        />
                        <DateRangePicker
                            title={'Period'}
                            buttonTitle={'Apply'}
                            dataStartDate={dataStartDate}
                            onChange={handleDateFilter}
                            onReset={handleRestDateFilter}
                        />
                        <Button variant="contained" className={'BBPTHBD'} onClick={handleCSVDownload} disabled={loading || totalData === 0}>Download</Button>
                    </Box>
                </Box>
                <Box component='div' className={'BBPTBody'}>
                    <DevTable
                        rows={generateRows(rowData)}
                        columns={mainColumns.columns}
                        selectedPage={selectedPage}
                        handlePagination={handlePaginate}
                        loading={loading}
                        handleSort={handleSorting}
                        limitData={limitData}
                        totalData={totalData}
                    />
                </Box>
            </Box>
        </Fragment>
    );
};

export default ICICITable; 