import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Skeleton from '@mui/material/Skeleton';
import ErrorIcon from '@mui/icons-material/Error';
import BAssuredImg from '../../../../Assets/Images/bassured.png';
import Confetti from '../../../../Components/Confetti';

const PaymentSuccess = ({ transactionDetails, paymentUpdateLoader, openViewReceipt, handleBackToHome }) => {

    const statusIcon = (type) => {
        switch (type) {
            case 'Success':
                return <CheckCircleIcon fontSize='inherit' color="success" />;
            case 'Pending':
                return <ErrorIcon fontSize='inherit' color="warning" />;
            case 'Failed':
                return <CancelIcon fontSize='inherit' color="error" />;
            default:
                break;
        }
    }

    return (
        <Fragment>
            <Box component="div" className={'BBPBPPPTPBMsg'}>
                <Box component="div" className={'BBPBPPPTPBMInner'}>
                    <Box component="div" className={'BBPBPSucMain'}>
                        <Box component="div" className={'BBPBPSMTitle'}>Bill Payment Status</Box>
                        <Box component="div" className={'BBPBPSuc'}>
                            <Box component="div" className={'BBPBPSInfo'}>
                                <Box component="div" className={'BBPBPSITitle'}>
                                    {paymentUpdateLoader ? <Skeleton variant="rounded" height={22} /> : `Payment ${transactionDetails.payStatus}`}
                                </Box>
                                <Box component="div" className={'BBPBPSIDes'}>
                                    {paymentUpdateLoader ? <Skeleton variant="rounded" height={22} /> : transactionDetails.billAmount}
                                </Box>
                            </Box>
                            <Box component="div" className={'BBPBPSIcon'}>
                                {paymentUpdateLoader ? <CircularProgress color="success" /> : statusIcon(transactionDetails.payStatus)}
                            </Box>
                        </Box>
                        <Box component="div" className={'BBPBPSuc'}>
                            <Box component="div" className={'BBPBPSInfo'}>
                                <Box component="div" className={'BBPBPSITitle'}>
                                    {paymentUpdateLoader ? <Skeleton variant="rounded" height={22} /> : `Bill Payment ${transactionDetails.transcStatus}`}
                                </Box>
                                <Box component="div" className={'BBPBPSIDes'}>
                                    {paymentUpdateLoader ? <Skeleton variant="rounded" height={22} /> : `For ${transactionDetails.biller}`}
                                </Box>
                            </Box>
                            <Box component="div" className={'BBPBPSIcon'}>
                                {paymentUpdateLoader ? <CircularProgress color="success" /> : statusIcon(transactionDetails.transcStatus)}
                            </Box>
                        </Box>

                        {transactionDetails.transcRefId &&
                            <Box component="div" className={'BBPBPSuc'}>
                                <Box component="div" className={'BBPBPSInfo'}>
                                    <Box component="div" className={'BBPBPSITitle'}>
                                        Transaction Ref ID
                                    </Box>
                                    <Box component="div" className={'BBPBPSIDes'}>
                                        {transactionDetails.transcRefId}
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPBPSIcon'}>
                                    <img src={BAssuredImg} alt={transactionDetails.transcRefId} />
                                </Box>
                            </Box>
                        }


                        <Box component="div" className={'BBPBPPPTPBMIReceipt'}>
                            <Button variant="contained" className={'BBPButton'} onClick={handleBackToHome}>Back to Home</Button>
                            {transactionDetails.transcStatus === 'Success' &&
                                <Button variant="text" onClick={openViewReceipt}>View Receipt</Button>
                            }
                        </Box>
                    </Box>
                </Box>
                {transactionDetails.transcStatus === 'Success' && <Confetti />}
            </Box>
        </Fragment>
    );
};
export default PaymentSuccess; 