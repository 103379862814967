import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function EducationIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 64 64" fontSize="inherit">
            <g transform="translate(10.713 13.391)">
                <path d="M11.008,34.049H1.953c-.486,0-.649,0-.649-.881v-2.1a.654.654,0,0,0-1.308,0v2.1a1.867,1.867,0,0,0,1.957,2.189h9.056a.654.654,0,0,0,0-1.308ZM.65,29.013a.654.654,0,0,0,.654-.654V6.748l19.788.034v10.9a.654.654,0,0,0,1.308,0V6.782a1.334,1.334,0,0,0-1.321-1.346H1.858l2.99-2.528h19A1.046,1.046,0,0,1,24.9,3.954V17.221a.654.654,0,1,0,1.308,0V3.954A2.354,2.354,0,0,0,23.852,1.6H4.609a.653.653,0,0,0-.422.154L.3,5.044a.973.973,0,0,0-.211.329A.642.642,0,0,0,0,5.7V28.362a.654.654,0,0,0,.654.651Z" transform="translate(0.033 -1.6)" fill="#175783" />
                <path d="M24.452,17.727a.654.654,0,0,0,.654-.654V5.749A1.947,1.947,0,0,0,23.164,3.8L6.823,3.77h0a.654.654,0,1,0,0,1.308l16.342.034a.638.638,0,0,1,.636.637V17.073a.654.654,0,0,0,.65.654Zm9,12.447a.654.654,0,0,0-.654.654v5.436a.785.785,0,0,1-.087.367c-.368.7-2.111,2.994-9.085,2.994s-8.717-2.289-9.09-2.992a.785.785,0,0,1-.087-.366V31.1a.654.654,0,0,0-1.308,0v5.159a2.106,2.106,0,0,0,.234.975c.588,1.11,2.754,3.693,10.251,3.693s9.662-2.58,10.245-3.689a2.092,2.092,0,0,0,.235-.976V30.828a.654.654,0,0,0-.655-.654Z" transform="translate(-0.763 -1.878)" fill="#175783" />
                <path d="M41.949,26.784l-17.3-5.778a.638.638,0,0,0-.407,0L4.761,27.253a.655.655,0,0,0-.01,1.24L22.97,34.651a.663.663,0,0,0,.209.035.655.655,0,0,0,.22-.038l13.373-4.775v3.138a2.2,2.2,0,0,0-1.563,2.1v1.318a2.2,2.2,0,0,0,4.407,0v-1.32a2.2,2.2,0,0,0-1.537-2.092V29.405l3.88-1.385a.654.654,0,0,0-.013-1.236Zm-3.643,9.642a.9.9,0,1,1-1.792,0v-1.32a.9.9,0,1,1,1.792,0Zm-.973-8.142-13.872-1.5a.654.654,0,1,0-.139,1.3l11.183,1.206L23.173,33.342,7.051,27.892,24.44,22.313l15.3,5.111Z" transform="translate(-0.524 -4.085)" fill="#175783" />
            </g>
        </SvgIcon>
    );
}