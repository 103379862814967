import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import { usePapaParse } from 'react-papaparse';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SearchBox from '../../Components/SearchBox';
import DevTable from '../../Components/DevTable';
import DateRangePicker from '../../Components/DateRangePicker';
import SomethingWentWrong from '../../Components/SomethingWentWrong';
import Backdrops from '../../Components/Backdrops';
import { format } from 'date-fns';
import { dateFormatter } from '../../Utils/index';

import './style.css';

const CommissionTable = () => {

    const { jsonToCSV } = usePapaParse();

    const axiosPrivate = useAxiosPrivate();
    const [errorDialog, setErrorDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [dataStartDate, setDataStartDate] = useState();
    const [totalData, setTotalData] = useState(0);
    const [selectedPage, setSelectedPage] = useState(1);
    const [limitData] = useState(20);
    const [onDownloadLoading, setOnDownloadLoading] = useState(false);

    const [rowData, setRowData] = useState([]);

    const [apiParams] = useState({ limit: limitData });

    const [mainColumns, setMainColumns] = useState({
        columns: [
            {
                name: 'date',
                title: 'Transaction Date',
                width: 150,
                sorting: true,
                direction: ''
            },
            {
                name: 'amount',
                title: 'Transaction Amount',
                width: 150,
                sorting: true,
                direction: ''
            },
            {
                name: 'commamt',
                title: 'Commission Amount',
                width: 150,
                sorting: true,
                direction: ''
            },
            {
                name: 'commtype',
                title: 'Commission Type',
                width: 150,
                sorting: true,
                direction: ''
            },
            {
                name: 'main_cat',
                title: 'Category',
                width: 200,
                sorting: true,
                direction: ''
            },
            {
                name: 'sub_cat',
                title: 'Biller Category',
                width: 200,
                sorting: true,
                direction: ''
            },
            {
                name: 'biller',
                title: 'Biller Name',
                width: 200,
                sorting: true,
                direction: ''
            },
            {
                name: 'transaction_provider',
                title: 'Partner',
                width: 200,
                sorting: true,
                direction: ''
            },
        ]
    });

    const fetchCommissions = useCallback(async (params) => {
        try {
            let urlParams = '';
            if (params) {
                Object.keys(params).forEach(function (key, index) {
                    urlParams += (index === 0 ? '?' : '&') + key + '=' + params[key];
                });
            }
            let url = `reports/commissions${urlParams}`;
            let options = {
                method: 'GET',
                url
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setRowData(response.data.data);
                    setTotalData(response.data.total);
                    setLoading(false);
                } else {
                    setRowData([]);
                    setTotalData(0);
                    setLoading(false);
                }
                setDataStartDate(new Date(response.data.firstDate));
            }).catch(err => {
                if (err.response) {
                    setLoading(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setLoading(false);
            setErrorDialog(true);
            // console.error('error', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearch = useCallback((value) => {
        setLoading(true);
        let prm = apiParams;
        setSelectedPage(1);
        prm.page = 1;
        if (value.length > 0) {
            prm.search = value;
        } else {
            delete prm.search;
        }
        fetchCommissions(prm);
    }, [apiParams, fetchCommissions])

    const handlePaginate = (value) => {
        let prm = apiParams;
        prm.page = value;
        setSelectedPage(value);
        setLoading(true);
        fetchCommissions(prm);
    };

    const handleSorting = (name, index) => {
        let prm = apiParams;
        let tempCol = mainColumns.columns;
        if (tempCol[index].direction === '') {
            tempCol.map((column) => (column.name === name ? column.direction = 'ASC' : column.direction = ''));
            prm.sort_by = name;
            prm.order = 1;
            prm.page = 1;
        } else if (tempCol[index].direction === 'ASC') {
            tempCol.map((column) => (column.name === name ? column.direction = 'DSC' : column.direction = ''));
            prm.sort_by = name;
            prm.order = -1;
            prm.page = 1;
        } else if (tempCol[index].direction === 'DSC') {
            tempCol.map((column) => column.direction = '');
            prm.page = 1;
            delete prm.sort_by;
            delete prm.order;
        }
        setLoading(true);
        setMainColumns({ columns: tempCol });
        setSelectedPage(1);
        fetchCommissions(prm);
    }

    function generateRows(tempArray) {
        const tempRowArray = [];
        if (tempArray) {
            tempArray.map((item) =>
                tempRowArray.push({
                    date: (<Box component="div" className="BBPDTSText">{dateFormatter(item.transaction_date)}</Box>),
                    amount: (<Box component="div" className="BBPDTSText">{item.transaction_amt}</Box>),
                    commamt: (<Box component="div" className="BBPDTSText">{item.commission_amt}</Box>),
                    commtype: (<Box component="div" className="BBPDTSText">{item.commission_type}</Box>),
                    main_cat: (<Box component="div" className="BBPDTSText">{item.main_cat}</Box>),
                    sub_cat: (<Box component="div" className="BBPDTSText">{item.sub_cat}</Box>),
                    biller: (<Box component="div" className="BBPDTSText">{item.biller}</Box>),
                    transaction_provider: (<Box component="div" className="BBPDTSText">{item.transaction_provider}</Box>),
                }),
            );
        }
        return tempRowArray;
    }

    const handleCSVDownload = useCallback(async () => {
        try {
            setOnDownloadLoading(true);
            let urlParams = '';
            if (apiParams) {
                delete apiParams.page;
                Object.keys(apiParams).forEach(function (key, index) {
                    urlParams += (index === 0 ? '?' : '&') + key + '=' + (key === 'limit' ? totalData : apiParams[key]);
                });
            }
            let url = `reports/commissions${urlParams}`;
            let options = {
                method: 'GET',
                url
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setRowData(response.data.data);
                    setTotalData(response.data.total);
                    const tempDownloadArr = [];
                    const tempHeader = {};
                    mainColumns.columns.map((item) => tempHeader[item.name] = item.title);
                    response.data.data.map((item) =>
                        tempDownloadArr.push({
                            date: format(new Date(item.transaction_date), "dd-MM-yyyy"),
                            amount: item.transaction_amt,
                            commamt: item.commission_amt,
                            commtype: item.commission_type,
                            main_cat: item.main_cat,
                            sub_cat: item.sub_cat,
                            biller: item.biller,
                            transaction_provider: item.transaction_provider,
                        })
                    );
                    tempDownloadArr.unshift(tempHeader);
                    const csv = jsonToCSV(tempDownloadArr, { header: false });
                    let filename = `${'commissions'}-${format(new Date(), 'yyyy-MM-dd-HH:mm:ss')}.csv`;
                    var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                    var csvURL = null;
                    if (navigator.msSaveBlob) {
                        csvURL = navigator.msSaveBlob(csvData, filename);
                    }
                    else {
                        csvURL = window.URL.createObjectURL(csvData);
                    }
                    var tempLink = document.createElement('a');
                    tempLink.href = csvURL;
                    tempLink.setAttribute('download', filename);
                    tempLink.click();
                    setOnDownloadLoading(false);
                } else {
                    setRowData([]);
                    setTotalData(0);
                    setOnDownloadLoading(false);
                }
            }).catch(err => {
                if (err.response) {
                    setErrorDialog(true);
                    setOnDownloadLoading(false);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setErrorDialog(true);
            setOnDownloadLoading(false);
            // console.error('error', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalData]);

    const handleDateFilter = (date) => {
        let prm = apiParams;
        prm.startDate = format(date.startDate, "yyyy-MM-dd");
        prm.endDate = format(date.endDate, "yyyy-MM-dd");
        setLoading(true);
        fetchCommissions(prm);
    };

    const handleRestDateFilter = () => {
        let prm = apiParams;
        delete prm.startDate;
        delete prm.endDate;
        setLoading(true);
        fetchCommissions(prm);
    };

    useEffect(() => {
        fetchCommissions(apiParams);
        // eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
            <Backdrops
                open={onDownloadLoading}
                title={'Downloading'}
            />
            <Box component='div' className={'BBPTransaction'}>
                <Box component='div' className={'BBPTHead'}>
                    <SearchBox
                        onSearchChange={handleSearch}
                        placeholder={'Search Commission'}
                        searchTooltip={'Searching from Biller Name'}
                    />
                    <Box component='div' className={'BBPTHBtn'}>
                        <DateRangePicker
                            title={'Period'}
                            buttonTitle={'Apply'}
                            dataStartDate={dataStartDate}
                            onChange={handleDateFilter}
                            onReset={handleRestDateFilter}
                        />
                        <Button variant="contained" className={'BBPTHBD'} onClick={handleCSVDownload} disabled={loading || totalData === 0}>Download</Button>
                    </Box>
                </Box>
                <Box component='div' className={'BBPTBody'}>
                    <DevTable
                        rows={generateRows(rowData)}
                        columns={mainColumns.columns}
                        selectedPage={selectedPage}
                        handlePagination={handlePaginate}
                        loading={loading}
                        handleSort={handleSorting}
                        limitData={limitData}
                        totalData={totalData}
                    />
                </Box>
            </Box>
        </Fragment>
    );
};

export default CommissionTable; 