import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function HomeLoanIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 64 64" fontSize="inherit">
            <g transform="translate(5.85 9.656)">
                <path d="M25.838,3a24.928,24.928,0,0,1,2.5,2.288c2.5,2.288,4.992,4.784,7.7,7.071,1.664,1.248,3.12,2.912,4.576,4.16a80.024,80.024,0,0,1,6.24,5.616c.416.416.832.416,1.456.832H41.853V41.477H28.126V29.414H23.551V41.685H9.616V23.175H4c0-.624.624-.832.832-1.248.416-.416.624-.832,1.04-1.04.208-.208.624-.832,1.04-1.04.506-.253.208-.208,1.248-.832.208-.208.439-.245.624-.416,2.7-2.5,5.408-5.2,8.111-7.7,2.08-1.872,4.16-3.952,6.448-5.824A13.19,13.19,0,0,1,25.838,3ZM13.151,37.318h6.656V25.046H31.662c.208,4.16,0,8.111.208,12.271h6.656v-18.3a23.542,23.542,0,0,1-2.7-2.08c-2.5-2.288-4.784-4.576-7.279-6.656a22.029,22.029,0,0,0-2.5-2.08L13.567,19.431C13.151,25.254,13.151,31.286,13.151,37.318Z" fill="#175783" />
            </g>
        </SvgIcon>
    );
}