
import React, { Fragment, useEffect, useState, useCallback, useContext, useRef, useLayoutEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useAxiosPrivate } from '../../../Hooks/useAxiosPrivate';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InfiniteScroll from 'react-infinite-scroll-component';
import BillerBox from './billerBox';
import NoData from '../../../Components/NoData';
import SearchBox from '../../../Components/SearchBox';
import NoDataImg from '../../../Assets/Images/no-data.png';
import { BillerContext } from '../../../Contexts/billerCategories';
import { reformatedOption } from '../../../Utils/autocompleteGroup';
import { CategoryIcon } from '../../../Utils/categoryIcon';
import SomethingWentWrong from '../../../Components/SomethingWentWrong';
import BhartBillPay from '../../../Assets/Images/bhartbillpay.png';
import PoweredBy from '../../../Components/PoweredBy';

import './style.css';

const Billers = () => {

    const axiosPrivate = useAxiosPrivate();
    let { sub_cat_id } = useParams();
    const boxHeightRef = useRef(null);
    const categories = useContext(BillerContext);
    let navigate = useNavigate();
    const location = useLocation();
    const mobile_number = location.state && location.state.mobile_number;
    const homeLink = location.state && location.state.homeLink;
    const [loading, setLoading] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [allProviders, setAllProviders] = useState([]);
    const [boxHeigh, setBoxHeigh] = useState(null);
    const [limitData] = useState(100);
    const [selectedPage, setSelectedPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [totalData, setSetTotalData] = useState(0);
    const [apiParams] = useState({ limit: limitData });

    const [errorDialog, setErrorDialog] = useState(false);

    const fetchBillerProviders = useCallback(async (params, isSearch) => {
        try {
            let urlParams = '';
            if (params) {
                Object.keys(params).forEach(function (key, index) {
                    urlParams += (index === 0 ? '?' : '&') + key + '=' + params[key];
                });
            }
            let url = `billers/providers/${sub_cat_id}/${urlParams}`;
            let options = {
                method: 'GET',
                url
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    if (isSearch) {
                        setAllProviders(response.data.data);
                    } else {
                        setAllProviders(allProviders.concat(Array.from(response.data.data)));
                    }
                    setSelectedPage(parseInt(params.page + 1));
                    setSetTotalData(response.data.total);
                    setLoading(false);
                } else {
                    setAllProviders([]);
                    setLoading(false);
                }
            }).catch(err => {
                if (err.response) {
                    setLoading(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setLoading(false);
            setErrorDialog(true);
            // console.error('error', error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allProviders, sub_cat_id]);

    const handleFetch = useCallback(() => {
        let prm = apiParams;
        prm.page = selectedPage;
        if (allProviders.length !== totalData) {
            fetchBillerProviders(prm, false);
        } else {
            setHasMoreData(false);
        }
    }, [allProviders.length, apiParams, fetchBillerProviders, selectedPage, totalData]);

    const handleSearch = useCallback((value) => {
        setLoading(true);
        setHasMoreData(true);
        setSearchValue(value);
        let prm = apiParams;
        prm.page = 1;
        if (value.length > 0) {
            prm.search = value;
        } else {
            delete prm.search;
        }
        fetchBillerProviders(prm, true);
    }, [apiParams, fetchBillerProviders])

    const handleBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    useEffect(() => {
        if (firstLoad) {
            fetchBillerProviders({ limit: limitData, page: selectedPage });
            setFirstLoad(false);
        }
    }, [fetchBillerProviders, firstLoad, limitData, selectedPage]);


    let allOpt = reformatedOption(categories.allCategories, ['name|type']).filter(provider => provider._id === sub_cat_id)

    useLayoutEffect(() => {
        /**
         * To measure box height, Using ref [useRef hooks] object to measure height and
         * setting it into states which is being used to set height of infinite scroll bar
         */
        function updateSize() {
            if (boxHeightRef.current) {
                const height = boxHeightRef.current.getBoundingClientRect().height;
                setBoxHeigh(height);
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [boxHeightRef, boxHeigh]);


    return (
        <Fragment>
            <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
            <Box component="div" className={'BBPBPBCategories'}>
                <Box component="div" className={'BBPBPBCInner'}>
                    {loading ?
                        <Fragment>
                            <Box component="div" className={'BBPBPBCISearch'}>
                                <Box component="div" className={'BBPBPBCISTitle'}>
                                    <Box component="div" className={'BBPBPBCISTICon'}>{allOpt.length > 0 ? CategoryIcon(allOpt[0].icon) : ''}</Box>
                                    <Box component="div" className={'BBPBPBCISTText'}>{allOpt.length > 0 ? `${allOpt[0].name}` : ''}</Box>
                                </Box>
                                <Box component="div" className={'BBPBPBCISInput'}>
                                    <SearchBox
                                        placeholder={'Search Biller'}
                                        disabled={true}
                                        searchTooltip={'Searching from Biller Name'}
                                    />
                                </Box>
                                <Box component="div" className={'BBPBPBCISIcon'}>
                                    <img src={BhartBillPay} alt={'Bharat Bill Payment'} />
                                </Box>
                            </Box>
                            <Box component="div" className={'BBPBPBCBiller'}>
                                <Box component="div" className={'BBPBPBCICInner'} ref={boxHeightRef}>
                                    <Box component="div" className={'BBPBPBEBList'}>
                                        {Array.from(Array(25).keys()).map((item) => (
                                            <Box className={'BBPBPBBillerBox'} key={item} >
                                                <Box component="div" className={'BBPBPBBBIcon'}>
                                                    <Skeleton variant="circular" width={50} height={50} />
                                                </Box>
                                                <Skeleton variant="rounded" className={'BBPBPBBBTitle'} height={22} />
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            </Box>
                        </Fragment>
                        :
                        <Fragment>
                            {allProviders.length === 0 && searchValue.length === 0 ?
                                <NoData
                                    image={NoDataImg}
                                    title={'Biller not found.'}
                                    description={'Please retry with another biller name.'}
                                />
                                :
                                <Fragment>
                                    <Box component="div" className={'BBPBPBCISearch'}>
                                        <Box component="div" className={'BBPBPBCISTitle'}>
                                            <Box component="div" className={'BBPBPBCISTICon'}>{allOpt.length > 0 ? CategoryIcon(allOpt[0].icon) : ''}</Box>
                                            <Box component="div" className={'BBPBPBCISTText'}>{allOpt.length > 0 ? `${allOpt[0].name}` : ''}</Box>
                                        </Box>
                                        <Box component="div" className={'BBPBPBCISInput'}>
                                            <SearchBox
                                                onSearchChange={handleSearch}
                                                placeholder={'Search Biller'}
                                                disabled={loading}
                                                searchTooltip={'Searching from Biller Name'}
                                            />
                                        </Box>
                                        <Box component="div" className={'BBPBPBCISIcon'}>
                                            <img src={BhartBillPay} alt={'Bharat Bill Payment'} />
                                        </Box>
                                    </Box>

                                    {allProviders.length > 0 ?
                                        <Box component="div" className={'BBPBPBCBiller'}>
                                            <InfiniteScroll
                                                className={'BBPBPBEBList'}
                                                dataLength={allProviders.length}
                                                next={handleFetch}
                                                hasMore={hasMoreData}
                                                loader={
                                                    <Fragment>
                                                        {Array.from(Array(15).keys()).map((item) => (
                                                            <Box className={'BBPBPBBillerBox'} key={item} >
                                                                <Box component="div" className={'BBPBPBBBIcon'}>
                                                                    <Skeleton variant="circular" width={50} height={50} />
                                                                </Box>
                                                                <Skeleton variant="rounded" className={'BBPBPBBBTitle'} height={22} />
                                                            </Box>
                                                        ))}
                                                    </Fragment>
                                                }
                                                height={boxHeigh ? boxHeigh : 400}
                                            >
                                                {allProviders.map((item, index) =>
                                                    <BillerBox
                                                        key={index}
                                                        item={item}
                                                        sub_cat_id={sub_cat_id}
                                                        mobile_number={mobile_number}
                                                        homeLink={homeLink}
                                                    />
                                                )}
                                            </InfiniteScroll>
                                        </Box>
                                        :
                                        <Box component="div" className={'BBPBPBCBiller'}>
                                            <NoData
                                                image={NoDataImg}
                                                title={'Biller not found.'}
                                                description={'Please retry with another biller name.'}
                                            />
                                        </Box>
                                    }
                                </Fragment>
                            }
                        </Fragment>
                    }
                    <PoweredBy isFinacus={true} isMobiKwik={true} />
                    <Box component="div" className={'BBPBPBCISBtn'}>
                        <Button variant="contained" className={'BBPButton'} onClick={handleBack}>Back</Button>
                    </Box>
                </Box>
            </Box>
        </Fragment>
    );
};

export default Billers;
