import React, { useState, useContext, useCallback, Fragment } from 'react';
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import Box from '@mui/material/Box';
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip from '@mui/material/Tooltip';
import UploadCSVDialog from '../../Components/UploadCSV';
import { arrayEquals } from '../../Utils/index';
import { BillerContext } from '../../Contexts/billerCategories';
import { reformatedOption } from '../../Utils/autocompleteGroup';
import SomethingWentWrong from '../../Components/SomethingWentWrong';
import CommissionCSV from '../../Assets/csv/commission.csv';

import './style.css';

const UploadCSV = () => {

    const jsonHeader = ["Commission For", "Main Category", "Biller Category", "Biller", "Commission Type", "Commission Value"];
    const commissionFor = ["Category", "Biller"];
    const commType = ["Percentage", "Flat"];

    let axiosPrivate = useAxiosPrivate();
    const categories = useContext(BillerContext);

    const [fileProgress, setFileProgress] = useState(true);
    const [jsonHeaderError, setJsonHeaderError] = useState({ err: false, success: false, arr: [] });

    const [errorDialog, setErrorDialog] = useState(false);
    const [uploadFile, setUploadFile] = useState();
    const [nextButton, setNextButton] = useState(true);
    const [tableDataErr, setTableDataErr] = useState(0);
    const [tableHeader, setTableHeader] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [apiSendingData, setApiSendingData] = useState([]);
    const [duplicateData, setDuplicateData] = useState(0);
    const [loading, setLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState({});

    const handleValidJson = (json) => {
        setLoading(false);
        if (json.data) {
            if (arrayEquals(jsonHeader, json.data[0])) {
                let tempData = [];
                let tempError = [];
                let tempHeader = [];
                let tempRowArray = [];
                jsonHeader.map((item) => tempHeader.push({
                    name: (item.toLowerCase()).replace(/\s/g, ''),
                    title: item,
                    width: 200,
                    sorting: false,
                    direction: ''
                }));
                setTableHeader(tempHeader);
                let allData = [];
                // eslint-disable-next-line  
                json.data.map((item, index) => {
                    if (index > 0) {
                        let tempObj = {};
                        let error = false;
                        const filterMainCats = categories.mainCategories.filter(element => {
                            return element.name === item[1];
                        });
                        const filterSubCats = categories.subCategories.filter(element => {
                            return element.name === item[2];
                        });
                        if (filterMainCats.length === 0) {
                            error = true;
                        }
                        if (filterSubCats.length === 0) {
                            error = true;
                        }
                        let commForCheck = commissionFor.includes(item[0].trim());
                        if ((item[0].trim()).length === 0 && !commForCheck) {
                            error = true;
                        }
                        if ((item[3].trim()).length === 0) {
                            error = true;
                        }
                        let checkCommType = commType.includes(item[4].trim());
                        if ((item[4].trim()).length === 0 && !checkCommType) {
                            error = true;
                        }
                        let commValCheckNum = (/^\d+$/.test(item[5].trim()));
                        if ((item[5].trim()).length === 0 && !commValCheckNum) {
                            error = true;
                        }
                        tempObj[tempHeader[0].name] = item[0].trim();
                        tempObj[tempHeader[1].name] = item[1].trim();
                        tempObj[tempHeader[2].name] = item[2].trim();
                        tempObj[tempHeader[3].name] = item[3].trim();
                        tempObj[tempHeader[4].name] = item[4].trim();
                        tempObj[tempHeader[5].name] = item[5].trim();
                        tempObj['error'] = error;
                        allData.push(tempObj);
                    }
                });

                let objectCatFound;
                // eslint-disable-next-line  
                const checkCatDuplicateData = allData.reduce((accumulator, object) => {
                    // eslint-disable-next-line  
                    if (object.commissionfor === "Category") {
                        // eslint-disable-next-line
                        if (objectCatFound = accumulator.find(arrItem => arrItem.maincategory === object.maincategory && arrItem.billercategory === object.billercategory && arrItem.error === object.error)) {
                            objectCatFound.times++;
                            accumulator.push(object);
                        } else {
                            object.times = 1;
                            accumulator.push(object);
                        }
                    } else {
                        accumulator.push(object);
                    }
                    return accumulator;
                }, []);
                let objectProvFound;
                // eslint-disable-next-line  
                const checkProvDuplicateData = checkCatDuplicateData.reduce((accumulator, object) => {
                    // eslint-disable-next-line  
                    if (object.commissionfor === "Biller") {
                        // eslint-disable-next-line
                        if (objectProvFound = accumulator.find(arrItem => arrItem.maincategory === object.maincategory && arrItem.billercategory === object.billercategory && arrItem.provider === object.provider && arrItem.error === object.error)) {
                            objectProvFound.times++;
                            accumulator.push(object);
                        } else {
                            object.times = 1;
                            accumulator.push(object);
                        }
                    } else {
                        accumulator.push(object);
                    }
                    return accumulator;
                }, []);
                // eslint-disable-next-line 
                checkProvDuplicateData.map((item) => {
                    let temObj = {};
                    let temTableObj = {};
                    let error = false;
                    let allCats = reformatedOption(categories.allCategories, ['name|type']);
                    const filterMainCats = categories.mainCategories.filter(element => {
                        return element.name === item.maincategory;
                    });
                    const filterSubCats = categories.subCategories.filter(element => {
                        return element.name === item.billercategory;
                    });
                    const filterCats = allCats.filter(element => {
                        return element.type === item.maincategory && element.name === item.billercategory;
                    });
                    let commForCheck = commissionFor.includes(item.commissionfor);
                    if (item.commissionfor.length > 0 && commForCheck) {
                        temObj['commission_for'] = item.commissionfor;
                        temTableObj[tempHeader[0].name] = <Box component="div" className={`BBPDTSText ${!item.hasOwnProperty('times') && !item.error ? 'BBPDTSWarn' : ''}`}>{item.commissionfor}</Box>;
                    } else {
                        error = true;
                        temTableObj[tempHeader[0].name] = <Tooltip
                            placement="top"
                            classes={{
                                popper: 'BBPTPopper',
                                tooltip: 'BBPTooltip'
                            }}
                            title={item.commissionfor.length === 0 ? `${tempHeader[0].title} Not Be Empty` : !commForCheck ? 'Only Enter "Category" and "Biller"' : ''}
                        >
                            <Box component="div" className="BBPDTSText BBPDTSErr">
                                <Box component="div" className="BBPDTSEText">{item.commissionfor.length > 0 ? item.commissionfor : '---'}</Box>
                                <ErrorIcon fontSize="inherit" />
                            </Box>
                        </Tooltip>;
                    }
                    if (filterCats.length > 0) {
                        temObj['cat_id'] = filterCats[0]._id;
                        temTableObj[tempHeader[1].name] = <Box component="div" className={`BBPDTSText ${!item.hasOwnProperty('times') && !item.error ? 'BBPDTSWarn' : ''}`}>{item.maincategory}</Box>;
                        temTableObj[tempHeader[2].name] = <Box component="div" className={`BBPDTSText ${!item.hasOwnProperty('times') && !item.error ? 'BBPDTSWarn' : ''}`}>{item.billercategory}</Box>;
                    } else {
                        error = true;
                        temTableObj[tempHeader[1].name] =
                            filterMainCats.length > 0 ?
                                <Box component="div" className="BBPDTSText">{item.maincategory.length > 0 ? item.maincategory : '---'}</Box> :
                                <Tooltip
                                    placement="top"
                                    classes={{
                                        popper: 'BBPTPopper',
                                        tooltip: 'BBPTooltip'
                                    }}
                                    title={item.maincategory.length === 0 ? `${tempHeader[1].title} Not Be Empty` : 'Please Check Entered Main Category'}
                                >
                                    <Box component="div" className={`BBPDTSText BBPDTSErr`}>
                                        <Box component="div" className="BBPDTSEText">{item.maincategory.length > 0 ? item.maincategory : '---'}</Box>
                                        <ErrorIcon fontSize="inherit" />
                                    </Box>
                                </Tooltip>;

                        temTableObj[tempHeader[2].name] =
                            filterSubCats.length > 0 ?
                                <Box component="div" className="BBPDTSText">{item.billercategory.length > 0 ? item.billercategory : '---'}</Box> :
                                <Tooltip
                                    placement="top"
                                    classes={{
                                        popper: 'BBPTPopper',
                                        tooltip: 'BBPTooltip'
                                    }}
                                    title={item.billercategory.length === 0 ? `${tempHeader[2].title} Not Be Empty` : 'Please Check Entered Biller Category'}
                                >
                                    <Box component="div" className={`BBPDTSText BBPDTSErr`}>
                                        <Box component="div" className="BBPDTSEText">{item.billercategory.length > 0 ? item.billercategory : '---'}</Box>
                                        <ErrorIcon fontSize="inherit" />
                                    </Box>
                                </Tooltip>;
                    }

                    if (item.commissionfor === 'Biller') {
                        if (item.biller.length > 0) {
                            temObj['prov_id'] = item.biller;
                            temTableObj[tempHeader[3].name] = <Box component="div" className={`BBPDTSText ${!item.hasOwnProperty('times') && !item.error ? 'BBPDTSWarn' : ''}`}>{item.biller}</Box>;
                        } else {
                            error = true;
                            temTableObj[tempHeader[3].name] = <Tooltip
                                placement="top"
                                classes={{
                                    popper: 'BBPTPopper',
                                    tooltip: 'BBPTooltip'
                                }}
                                title={item.biller.length === 0 ? `${tempHeader[3].title} Not Be Empty` : ''}
                            >
                                <Box component="div" className="BBPDTSText BBPDTSErr">
                                    <Box component="div" className="BBPDTSEText">---</Box>
                                    <ErrorIcon fontSize="inherit" />
                                </Box>
                            </Tooltip>;
                        }
                    } else {
                        temTableObj[tempHeader[3].name] = <Box component="div" className={`BBPDTSText ${!item.hasOwnProperty('times') && !item.error ? 'BBPDTSWarn' : ''}`}>Only Commission For Biller</Box>;
                    }

                    let checkCommType = commType.includes(item.commissiontype);
                    if (item.commissiontype.length > 0 && checkCommType) {
                        temObj['comm_type'] = item.commissiontype;
                        temTableObj[tempHeader[4].name] = <Box component="div" className={`BBPDTSText ${!item.hasOwnProperty('times') && !item.error ? 'BBPDTSWarn' : ''}`}>{item.commissiontype}</Box>;
                    } else {
                        error = true;
                        temTableObj[tempHeader[4].name] = <Tooltip
                            placement="top"
                            classes={{
                                popper: 'BBPTPopper',
                                tooltip: 'BBPTooltip'
                            }}
                            title={item.commissiontype.length === 0 ? `${tempHeader[4].title} Not Be Empty` : !checkCommType ? 'Only Enter "Percentage" and "Flat"' : ''}
                        >
                            <Box component="div" className="BBPDTSText BBPDTSErr">
                                <Box component="div" className="BBPDTSEText">{item.commissiontype.length > 0 ? item.commissiontype : '---'}</Box>
                                <ErrorIcon fontSize="inherit" />
                            </Box>
                        </Tooltip>;
                    }
                    let commValCheckNum = (/^\d+$/.test(item.commissionvalue));
                    if (item.commissionvalue.length > 0 && commValCheckNum) {
                        temObj['comm_val'] = item.commissionvalue;
                        temTableObj[tempHeader[5].name] = <Box component="div" className={`BBPDTSText ${!item.hasOwnProperty('times') && !item.error ? 'BBPDTSWarn' : ''}`}>{item.commissionvalue}</Box>;
                    } else {
                        error = true;
                        temTableObj[tempHeader[5].name] = <Tooltip
                            placement="top"
                            classes={{
                                popper: 'BBPTPopper',
                                tooltip: 'BBPTooltip'
                            }}
                            title={item.commissionvalue.length === 0 ? `${tempHeader[5].title} Not Be Empty` : !commValCheckNum ? 'Enter only number' : ''}
                        >
                            <Box component="div" className="BBPDTSText BBPDTSErr">
                                <Box component="div" className="BBPDTSEText">{item.commissionvalue.length > 0 ? item.commissionvalue : '---'}</Box>
                                <ErrorIcon fontSize="inherit" />
                            </Box>
                        </Tooltip>;
                    }
                    temObj['range'] = 'No';
                    if (error || !item.hasOwnProperty('times')) {
                        temTableObj['err'] = true;
                    } else {
                        temTableObj['err'] = error;
                    }
                    tempRowArray.push(temTableObj);
                    if (error) {
                        tempError.push(error);
                    } else {
                        tempData.push(temObj);
                    }
                });


                if (tempError.length > 0) {
                    setTableDataErr(tempError.length);
                } else {
                    setTableDataErr(0);
                }

                const newArrayOfObjects =
                    tempData.reduce((accumulator, object) => {
                        if (!accumulator.find(arrItem => arrItem.cat_id === object.cat_id && arrItem.prov_id === object.prov_id)) {
                            accumulator.push(object);
                        }
                        return accumulator;
                    }, []);
                setApiSendingData(newArrayOfObjects);
                setDuplicateData(tempData.length - newArrayOfObjects.length);

                if (tempRowArray.length === 0) {
                    setNextButton(true);
                    setJsonHeaderError({ err: true, success: false, arr: ['Data not found'] });
                } else {
                    setNextButton(false);
                    setJsonHeaderError({ err: false, success: true, arr: [] });
                }
                setTableData(tempRowArray);
                setFileProgress(false);
            } else {
                let headerError = [];
                jsonHeader.map((item, index) => item === json.data[0][index] ? '' : headerError.push(item));
                setJsonHeaderError({ err: true, success: false, arr: headerError });
                setNextButton(true);
                setTableData([]);
            }
        } else {
            setJsonHeaderError({ err: false, success: false, arr: [] });
            setNextButton(true);
            setTableData([]);
        }
    };

    const uploadJSON = useCallback(async (data) => {
        try {
            setLoading(true);
            let url = `commissions/bulk`;
            let options = {
                method: 'POST',
                url,
                data: { data: data },
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setApiResponse({
                        duplicated: response.data.duplicated,
                        inserted: response.data.inserted,
                        notFound: response.data.notFound,
                        notFoundLine: 'Biller Not Found.'
                    });
                } else {
                    setApiResponse({});
                    setErrorDialog(true);
                }
                setLoading(false);
            }).catch(err => {
                if (err.response) {
                    setErrorDialog(true);
                    setLoading(false);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setErrorDialog(true);
            setLoading(false);
            // console.error('error', error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleUploadJson = () => {
        uploadJSON(apiSendingData);
    }

    return (
        <Fragment>
            <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
            <UploadCSVDialog
                jsonHeader={jsonHeader}
                handleValidJson={handleValidJson}
                fileProgress={fileProgress}
                setFileProgress={setFileProgress}
                jsonHeaderError={jsonHeaderError}
                uploadFile={uploadFile}
                setUploadFile={setUploadFile}
                nextButton={nextButton}
                setNextButton={setNextButton}
                tableDataErr={tableDataErr}
                tableHeader={tableHeader}
                tableData={tableData}
                duplicateData={duplicateData}
                loading={loading}
                apiResponse={apiResponse}
                handleUploadJson={handleUploadJson}
                backButtonTitle={'Back to Commissions'}
                CSVFile={CommissionCSV}
            />
        </Fragment>
    );
};
export default UploadCSV; 