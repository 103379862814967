import React, { useContext, useState, useEffect, useCallback, Fragment } from 'react';
import { useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import NoData from '../../../Components/NoData';
import SearchBox from '../../../Components/SearchBox';
import NoDataImg from '../../../Assets/Images/no-data.png';
import CategoryBox from './categoryBox';
import { BillerContext } from '../../../Contexts/billerCategories';
import PoweredBy from '../../../Components/PoweredBy';
import BhartBillPay from '../../../Assets/Images/bhartbillpay.png';
import './style.css';

const BillerCategories = () => {

    const categories = useContext(BillerContext);
    const location = useLocation();
    const mobile_number = location.state && location.state.mobile_number;
    const homeLink = location.state && location.state.link;
    const [loading, setLoading] = useState(true);
    const [mainCategories, setMainCategories] = useState([]);
    const [searchCategory, setSearchCategory] = useState('');

    const handleSearchCategory = useCallback((value) => {
        setSearchCategory(value.toLowerCase());
    }, []);

    // Category Search
    useEffect(() => {
        const filteredArray = categories && categories.allCategories.filter(element => element.sub_cats
            .some(subElement => subElement.name.toLowerCase().includes(searchCategory))
        )
            .map(element => {
                let temp = element.sub_cats;
                temp.sort(function (a, b) { return a.order - b.order; });
                let n = Object.assign({}, element, {
                    'sub_cats': temp.filter(
                        subElement => subElement.name.toLowerCase().includes(searchCategory)
                    )
                })
                return n;
            });
        filteredArray.sort(function (a, b) {
            return a.order - b.order;
        });
        setMainCategories(filteredArray || []);
        setLoading(false);
    }, [categories, searchCategory]);

    return (
        <Box component="div" className={'BBPBPBCategories'}>
            <Box component="div" className={'BBPBPBCInner'}>
                <Box component="div" className={'BBPBPBCISearch'}>
                    <Box component="div" className={'BBPBPBCISTitle'}>
                        Biller Categories
                    </Box>
                    <Box component="div" className={'BBPBPBCISInput'}>
                        <SearchBox
                            onSearchChange={handleSearchCategory}
                            placeholder={'Search Category'}
                            searchTooltip={'Searching from Main Category, Sub Category'}
                        />
                    </Box>
                    <Box component="div" className={'BBPBPBCISIcon'}>
                        <img src={BhartBillPay} alt={'Bharat Bill Payment'} />
                    </Box>
                </Box>
                <Box component="div" className={'BBPBPBCICat'}>
                    {loading ?
                        <Box component="div" className={'BBPBPBCICInner'}>
                            {Array.from(Array(5).keys()).map((item) => (
                                <Box component="div" key={item} className={'BBPBPBCList'}>
                                    <Skeleton variant="text" height={22} className={'BBPBPBCLTitle'} />
                                    <Box component="div" className={'BBPBPBCLBoxes'}>
                                        {Array.from(Array(5 - item).keys()).map((subitem) => (
                                            <Box component="div" className={'BBPBPBCLBox'} key={subitem}>
                                                <Skeleton variant="circular" className={'BBPBPBCLBIcon'} width={46} height={30} />
                                                <Skeleton variant="text" height={22} className={'BBPBPBCLBTitle'} />
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                        :
                        mainCategories.length > 0 ?
                            <Box component="div" className={'BBPBPBCICInner'}>
                                {mainCategories.map(cat => (
                                    <Box component="div" key={cat._id} className={'BBPBPBCList'}>
                                        <Box component="div" className={'BBPBPBCLTitle'}>
                                            {cat.name}
                                        </Box>
                                        <Box component="div" className={'BBPBPBCLBoxes'}>
                                            {cat.sub_cats.map((item) => (
                                                <CategoryBox
                                                    item={item}
                                                    key={item._id}
                                                    mobile_number={mobile_number}
                                                    homeLink={homeLink}
                                                />
                                            ))}
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                            :
                            <Fragment>
                                {categories && categories.dataLoading ?
                                    <Box component="div" className={'BBPBPBCICInner'}>
                                        {Array.from(Array(5).keys()).map((item) => (
                                            <Box component="div" key={item} className={'BBPBPBCList'}>
                                                <Skeleton variant="text" height={22} className={'BBPBPBCLTitle'} />
                                                <Box component="div" className={'BBPBPBCLBoxes'}>
                                                    {Array.from(Array(5 - item).keys()).map((subitem) => (
                                                        <Box component="div" className={'BBPBPBCLBox'} key={subitem}>
                                                            <Skeleton variant="circular" className={'BBPBPBCLBIcon'} width={46} height={30} />
                                                            <Skeleton variant="text" height={22} className={'BBPBPBCLBTitle'} />
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                    :
                                    <NoData
                                        image={NoDataImg}
                                        title={'Category could not be found.'}
                                        description={'Please retry with another category.'}
                                    />
                                }
                            </Fragment>
                    }
                </Box>
                <PoweredBy isFinacus={true} isMobiKwik={true} />
            </Box>
        </Box>
    );
};

export default BillerCategories;