import React, { Fragment, useState, useCallback } from 'react';
import validator from "@rjsf/validator-ajv6";
import Form from "@rjsf/core";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ClearIcon from '@mui/icons-material/Clear';
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import SomethingWentWrong from '../SomethingWentWrong';
import Backdrops from '../Backdrops';
import SuccessDialog from '../SuccessDialog';
import './style.css';

const schema = {
    "type": "object",
    "required": ["description"],
    "properties": {
        "reason": {
            "title": "Reason",
            "enum": [
                "Transaction Successful, account not updated",
                "Amount deducted, biller account credited but transaction ID not received",
                "Amount deducted, biller account not credited & transaction ID not received",
                "Amount deducted multiple times",
                "Double payment updateds",
                "Others, provide details in description",
            ],
            "default": "Transaction Successful, account not updated"
        },
        "description": {
            "title": "Description",
            "type": "string"
        },
    },
};

const uiSchema = {
    "ui:submitButtonOptions": {
        "props": {
            "className": "BBPFBtn"
        }
    },
    "reason": {
        "ui:classNames": "BBPFFWidth"
    },
    "description": {
        "ui:widget": "textarea",
        "ui:classNames": "BBPFFWidth"
    }
};

const RegisterComplaint = ({ open, onClose, transactionId }) => {

    function transformErrors(errors) {
        return errors.map(error => {
            let property;
            let matcheSB = (/\[(.*?)\]/).test(error.property);
            let matcheDot = /\./.test(error.property);
            if (matcheDot) {
                property = error.property.replaceAll('.', '');
            }
            if (matcheSB) {
                // eslint-disable-next-line
                property = error.property.replace(/[\[\]']+/g, '');
            }
            if (error.name === 'minLength') {
                error.message = `‘${schema.properties[property].title}’ should NOT be shorter than 10 characters.`;
            } else if (error.name === 'maxLength') {
                error.message = `‘${schema.properties[property].title}’ should NOT be longer than 10 characters.`;
            } else if (error.name === 'pattern') {
                error.message = `‘${schema.properties[property].title}’ is not valid.`;
            } else {
                error.message = `‘${schema.properties[property].title}’ is mandatory.`;
            }
            return error;
        });
    }

    const axiosPrivate = useAxiosPrivate();

    const [liveValidErr, setLiveValidErr] = useState(false);
    const [formData, setFormData] = useState({});
    const [errorDialog, setErrorDialog] = useState(false);
    const [submitDialog, setSubmitDialog] = useState(false);
    const [onSubmitLoading, setOnSubmitLoading] = useState(false);
    const [complaintId, setComplaintId] = useState('');
    const [complaintErr, setComplaintErr] = useState('');

    const handleRegisterComplaint = useCallback(async (data) => {
        try {
            setOnSubmitLoading(true);
            let url = `complaints`;
            let options = {
                method: 'POST',
                url,
                data,
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setSubmitDialog(true);
                    setOnSubmitLoading(false);
                    if (response.data.ComplaintId) {
                        setComplaintId(response.data.ComplaintId)
                    }
                    onClose();
                } else {
                    setOnSubmitLoading(false);
                    setComplaintErr(response.data.message);
                }
                setFormData({});
            }).catch(err => {
                if (err.response) {
                    setOnSubmitLoading(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setOnSubmitLoading(false);
            setErrorDialog(true);
            // console.error('error', error);
        }
        // eslint-disable-next-line
    }, []);

    const handleStatusDialogClose = () => {
        setSubmitDialog(false);
        setComplaintErr('');
    };


    const handleDialogClose = () => {
        setComplaintErr('');
        onClose();
    };

    let yourForm;

    const onSubmitNew = () => {
        yourForm.formElement.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    };

    const onFormSubmit = (formData) => {
        setLiveValidErr(false);
        let tempData = { ...formData };
        tempData.transactionId = transactionId;
        setFormData(formData);
        handleRegisterComplaint(tempData);
    };

    const onError = () => {
        setLiveValidErr(true);
    };

    return (
        <Fragment>
            <SuccessDialog
                open={submitDialog}
                onClose={handleStatusDialogClose}
                title={`Complaint Registered successfully`}
                message={<Fragment>
                    Complaint has been registered successfully. <br /><br /> Complaint ID: <br /> <Box component="strong">{complaintId}</Box>
                </Fragment>}
                buttonTitle={'Close'}
            />
            <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
            <Backdrops
                open={onSubmitLoading}
                title={'Saving'}
            />
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={handleDialogClose}
            >
                <Box component="div" className={'BBPRCDialog'}>
                    <Box component="div" className={'BBPRCDHead'}>
                        <Box component="div" className={'BBPRCDHTitle'}>
                            Register Complaint
                        </Box>
                        <IconButton onClick={handleDialogClose}>
                            <ClearIcon />
                        </IconButton>
                    </Box>
                    <Box component="div" className={'BBPRCDForm'}>
                        <Box component='div' className={'BBPForm'}>
                            <Form
                                schema={schema}
                                uiSchema={uiSchema}
                                formData={formData}
                                validator={validator}
                                showErrorList={false}
                                omitExtraData={true}
                                liveValidate={liveValidErr}
                                transformErrors={transformErrors}
                                onError={onError}
                                onSubmit={({ formData }) => {
                                    onFormSubmit(formData);
                                }}
                                ref={(form) => { yourForm = form; }}
                            />
                        </Box>
                    </Box>
                    {complaintErr && <Box component="div" className={'BBPRCDErr'}>{complaintErr}</Box>}
                    <Box component="div" className={'BBPRCDBtn'}>
                        <Button variant="contained" className={'BBPButton BBPOButton'} onClick={handleDialogClose}>Close</Button>
                        <Button variant="contained" className={'BBPButton'} onClick={onSubmitNew}>Save</Button>
                    </Box>
                </Box>
            </Dialog>
        </Fragment>
    );
};
export default RegisterComplaint;
