import React, { useState } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import RightSidePanel from '../../Components/RightSidePanel';
import BillerCategories from './billPaymentTab/billerCategories';
import Billers from './billPaymentTab/billers';
import ConsumerBill from './billPaymentTab/consumerBill';
import { MENU_SLUG } from '../../Constants/constants';
import { CheckPermissions } from '../../Utils/permissions';
import './style.css';

const BillPayments = () => {

    const [tabsList] = useState([
        {
            name: 'payment',
            title: 'Bill Payment',
            path: '/',
            link: `/${MENU_SLUG.bill_payments}`,
            component: BillerCategories,
            show_tab: CheckPermissions('bill_payment_permissions', 'View'),
            enable_route: CheckPermissions('bill_payment_permissions', 'View')
        },
        {
            name: 'billers',
            title: 'Billers',
            path: '/:sub_cat_id',
            link: `/${MENU_SLUG.bill_payments}`,
            component: Billers,
            show_tab: false,
            enable_route: CheckPermissions('bill_payment_permissions', 'View')
        },
        {
            name: 'consumer-bill',
            title: 'Consumer Bill',
            path: '/:sub_cat_id/:provider_id',
            link: `/${MENU_SLUG.bill_payments}`,
            component: ConsumerBill,
            show_tab: false,
            enable_route: CheckPermissions('bill_payment_permissions', 'View')
        }
    ]);

    return (
        <RightSidePanel>
            <Box component="div" className={'BBPBPPage'}>
                <Box component="div" className={'BBPBPPTabView'}>
                    <Routes>
                        <Route path={'/'} element={<Outlet />}>
                            { // eslint-disable-next-line  
                                tabsList.map((route) => {
                                    if (route.enable_route) {
                                        const { path, component: Component, children, title, permission, ...rest } = route;
                                        return (
                                            <Route {...rest} key={path} path={`${path}`} element={<Component />} />
                                        )
                                    }
                                })}
                        </Route>
                    </Routes>
                </Box>
            </Box>
        </RightSidePanel>
    );
};
export default BillPayments;