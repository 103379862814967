import React, { useState, useEffect, Fragment } from 'react';
import { Routes, NavLink, Route, Outlet, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import PaymentsIcon from '@mui/icons-material/Payments';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AddchartIcon from '@mui/icons-material/Addchart';
import RightSidePanel from '../../Components/RightSidePanel';
import BillerCategories from './billPaymentTab/billerCategories';
import Billers from './billPaymentTab/billers';
import ConsumerBill from './billPaymentTab/consumerBill';
import TransactionsTab from './TransactionsTab';
import ComplaintsTab from './ComplaintsTab';
import BharatBillPay from '../../Assets/Images/bhartbillpay.png';
import BodyView from '../../Components/BodyView';
import { MENU_SLUG } from '../../Constants/constants';

import './style.css';

const BillPayments = () => {

    let location = useLocation();
    let navigate = useNavigate();

    const [pwaAuth, setPWAAuth] = useState('');
    const [pwaMob, setPWAMob] = useState('');

    const [tabsList] = useState([
        {
            path: '/',
            link: `/${MENU_SLUG.pwa}`,
            component: BillerCategories,
        },
        {
            path: '/:sub_cat_id',
            link: `/${MENU_SLUG.pwa}`,
            component: Billers,
        },
        {
            path: '/:sub_cat_id/:provider_id',
            link: `/${MENU_SLUG.pwa}`,
            component: ConsumerBill,
        },
        {
            path: '/transactions',
            link: `/${MENU_SLUG.pwa}`,
            component: TransactionsTab,
        },
        {
            path: '/complaints',
            link: `/${MENU_SLUG.pwa}`,
            component: ComplaintsTab,
        }
    ]);

    useEffect(() => {
        let auth = new URLSearchParams(location.search).get('auth')
        let mob = new URLSearchParams(location.search).get('mob')
        setPWAAuth(auth);
        setPWAMob(mob);
        navigate(`/${MENU_SLUG.pwa}?auth=${auth}&mob=${mob}`, { replace: true, state: { mobile_number: mob, link: `/${MENU_SLUG.pwa}?auth=${auth}&mob=${mob}` } });
        // eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            <Box component="div" className={"BBPApp BBPAPub"}>
                <Box component="div" className={"BBPAInner"}>
                    <BodyView customClass={'pt-0'}>
                        <RightSidePanel fullWidth>
                            <Box component="div" className={'BBPBPPage BBPBPPTILPWA'}>
                                <Box component="div" className={'BBPBPPTabs'}>
                                    <Box component="div" className={'BBPBPPTInner'}>
                                        <Box component="div" className={'BBPBPPTIIcon'}>
                                            <img src={BharatBillPay} alt={'Bharat Bill Payment'} />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPBPPTabView'}>
                                    <Routes>
                                        <Route path={'/'} element={<Outlet />}>
                                            {tabsList.map((route) => {
                                                const { path, component: Component, children, title, permission, ...rest } = route;
                                                return (
                                                    <Route {...rest} key={path} path={`${path}`} element={<Component />} />
                                                )
                                            })}
                                        </Route>
                                        <Route path='*' element={<Navigate to="/dashboard" replace />} />
                                    </Routes>
                                </Box>
                                <Box component="div" className='BBPBPPTVBtmNav'>
                                    <NavLink
                                        to={`/${MENU_SLUG.pwa}?auth=${pwaAuth}&mob=${pwaMob}`}
                                        className={({ isActive }) => isActive ? 'BBPBPPTVBNAct' : ''}
                                        state={{ mobile_number: pwaMob, link: `/${MENU_SLUG.pwa}?auth=${pwaAuth}&mob=${pwaMob}` }}
                                        end={true}
                                    >
                                        <Box component="span" className={'BBPBPPTVBNIcon'}><PaymentsIcon fontSize='inherit' /></Box>
                                        <Box component="span" className={'BBPBPPTVBNTitle'}>Bill Payment</Box>
                                    </NavLink>
                                    <NavLink
                                        to={`/${MENU_SLUG.pwa}/transactions?auth=${pwaAuth}&mob=${pwaMob}`}
                                        className={({ isActive }) => isActive ? 'BBPBPPTVBNAct' : ''}
                                        state={{ mobile_number: pwaMob }}
                                        end={true}
                                    >
                                        <Box component="span" className={'BBPBPPTVBNIcon'}><ReceiptLongIcon fontSize='inherit' /></Box>
                                        <Box component="span" className={'BBPBPPTVBNTitle'}>Transactions</Box>
                                    </NavLink>
                                    <NavLink
                                        to={`/${MENU_SLUG.pwa}/complaints?auth=${pwaAuth}&mob=${pwaMob}`}
                                        className={({ isActive }) => isActive ? 'BBPBPPTVBNAct' : ''}
                                        state={{ mobile_number: pwaMob }}
                                        end={true}
                                    >
                                        <Box component="span" className={'BBPBPPTVBNIcon'}><AddchartIcon fontSize='inherit' /></Box>
                                        <Box component="span" className={'BBPBPPTVBNTitle'}>Complaints</Box>
                                    </NavLink>
                                </Box>
                            </Box>
                        </RightSidePanel>
                    </BodyView>
                </Box>
            </Box>
        </Fragment>
    );
};
export default BillPayments;