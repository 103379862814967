import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import history from './Utils/history';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './Contexts/authProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Router history={history}>
        <AuthProvider>
            <Routes>
                <Route path="/*" element={<App />} />
            </Routes>
        </AuthProvider>
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();