import React, { useState, useCallback, useEffect, useContext, Fragment } from 'react';
import { useNavigate, useParams, } from "react-router-dom";
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import ClearIcon from '@mui/icons-material/Clear';
import Dialog from '@mui/material/Dialog';
import { BillerContext } from '../../Contexts/billerCategories';
import { reformatedOption } from '../../Utils/autocompleteGroup';
import SomethingWentWrong from '../../Components/SomethingWentWrong';
import './style.css';

const ViewBillerModel = () => {
    const navigate = useNavigate();
    let { provider_id } = useParams();
    const axiosPrivate = useAxiosPrivate();
    const categories = useContext(BillerContext);
    const [errorDialog, setErrorDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [providerValue, setProviderValue] = useState({});

    const handleCloseModal = () => {
        navigate(-1);
    };

    const fetchBillerValue = useCallback(async () => {
        try {
            setLoading(true);
            let url = `billers/provider/${provider_id}`;
            let options = {
                method: 'GET',
                url
            };
            await axiosPrivate(options).then(response => {
                setProviderValue(response.data.data);
                setLoading(false);
            }).catch(err => {
                if (err.response) {
                    setErrorDialog(true);
                    setLoading(false);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setErrorDialog(true);
            setLoading(false);
            // console.error('error', error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [provider_id]);

    useEffect(() => {
        fetchBillerValue();
    }, [fetchBillerValue])

    let allOpt = reformatedOption(categories.allCategories, ['name|type']).filter(provider => provider._id === providerValue.cat_id)

    return (
        <Fragment>
            <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={true}
                onClose={handleCloseModal}
            >
                <Box component="div" className={'BBPVBMDialog'}>
                    <Box component="div" className={'BBPVBMDHead'}>
                        <Box component="div" className={'BBPVBMDHTitle'}>
                            Biller Details
                        </Box>
                        <IconButton onClick={handleCloseModal}>
                            <ClearIcon />
                        </IconButton>
                    </Box>
                    {loading ?
                        <Box component="div" className={'BBPVBMDInfo'}>
                            <Box component="div" className={'BBPVBMDIHead'}>
                                <Box component="div" className={'BBPVBMDIHIcon'}>
                                    <Skeleton variant="circular" width={80} height={80} />
                                </Box>
                                <Box component="div" className={'BBPVBMDIHTitle'}>
                                    <Skeleton variant="rounded" width={110} height={32} />
                                </Box>
                            </Box>
                            <Box component="div" className={'BBPVBMDIRow'}>
                                <Box component="div" className={'BBPVBMDIRCol'}>
                                    <Box component="div" className={'BBPVBMDIRCLabel'}>
                                        Biller Category
                                    </Box>
                                    <Box component="div" className={'BBPVBMDIRCTitle'}>
                                        <Skeleton variant="rounded" height={22} />
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPVBMDIRCol'}>
                                    <Box component="div" className={'BBPVBMDIRCLabel'}>
                                        Biller ID
                                    </Box>
                                    <Box component="div" className={'BBPVBMDIRCTitle'}>
                                        <Skeleton variant="rounded" height={22} />
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPVBMDIRCol'}>
                                    <Box component="div" className={'BBPVBMDIRCLabel'}>
                                        API Provider
                                    </Box>
                                    <Box component="div" className={'BBPVBMDIRCTitle'}>
                                        <Skeleton variant="rounded" height={22} />
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPVBMDIRCol'}>
                                    <Box component="div" className={'BBPVBMDIRCLabel'}>
                                        Auto Fetch
                                    </Box>
                                    <Box component="div" className={'BBPVBMDIRCTitle'}>
                                        <Skeleton variant="rounded" height={22} />
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPVBMDIRCol'}>
                                    <Box component="div" className={'BBPVBMDIRCLabel'}>
                                        Fetch On
                                    </Box>
                                    <Box component="div" className={'BBPVBMDIRCTitle'}>
                                        <Skeleton variant="rounded" height={22} />
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPVBMDIRCol'}>
                                    <Box component="div" className={'BBPVBMDIRCLabel'}>
                                        Status
                                    </Box>
                                    <Box component="div" className={'BBPVBMDIRCTitle'}>
                                        <Skeleton variant="rounded" height={22} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        :
                        <Box component="div" className={'BBPVBMDInfo'}>
                            <Box component="div" className={'BBPVBMDIHead'}>
                                <Box component="div" className={'BBPVBMDIHIcon'}>
                                    {providerValue.image &&
                                        <img src={`${providerValue.image.location}`} alt={providerValue.name} />}
                                </Box>
                                <Box component="div" className={'BBPVBMDIHTitle'}>
                                    {providerValue.name}
                                </Box>
                            </Box>
                            <Box component="div" className={'BBPVBMDIRow'}>
                                <Box component="div" className={'BBPVBMDIRCol'}>
                                    <Box component="div" className={'BBPVBMDIRCLabel'}>
                                        Biller Category
                                    </Box>
                                    <Box component="div" className={'BBPVBMDIRCTitle'}>
                                        {allOpt ? allOpt[0] && allOpt[0].name : <Skeleton variant="rounded" height={22} />}
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPVBMDIRCol'}>
                                    <Box component="div" className={'BBPVBMDIRCLabel'}>
                                        Biller ID
                                    </Box>
                                    <Box component="div" className={'BBPVBMDIRCTitle'} style={{ textTransform: 'lowercase' }}>
                                        {providerValue.biller_id}
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPVBMDIRCol'}>
                                    <Box component="div" className={'BBPVBMDIRCLabel'}>
                                        API Provider
                                    </Box>
                                    <Box component="div" className={'BBPVBMDIRCTitle'}>
                                        {providerValue.api_provider}
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPVBMDIRCol'}>
                                    <Box component="div" className={'BBPVBMDIRCLabel'}>
                                        Auto Fetch
                                    </Box>
                                    <Box component="div" className={'BBPVBMDIRCTitle'}>
                                        {providerValue.auto_fetch ? 'Active' : 'Disable'}
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPVBMDIRCol'}>
                                    <Box component="div" className={'BBPVBMDIRCLabel'}>
                                        Fetch On
                                    </Box>
                                    <Box component="div" className={'BBPVBMDIRCTitle'}>
                                        {providerValue.fetch_on}
                                    </Box>
                                </Box>
                                <Box component="div" className={'BBPVBMDIRCol'}>
                                    <Box component="div" className={'BBPVBMDIRCLabel'}>
                                        Status
                                    </Box>
                                    <Box component="div" className={'BBPVBMDIRCTitle'}>
                                        {providerValue.status ? 'Active' : 'Disable'}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    }
                    <Box component="div" className={'BBPVBMDBtn'}>
                        <Button variant="contained" className={'BBPButton'} onClick={handleCloseModal}>Close</Button>
                    </Box>
                </Box>
            </Dialog>
        </Fragment>
    );
};
export default ViewBillerModel;
