import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import './style.css';

const YearField = (props) => {
    const { options, disabled, onChange, id, readonly, label, defaultValue, value } = props;

    const { dropdownOptions } = options;
    const [allOptions, setAllOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('none');
    const handleSelectYear = (e) => {
        let val = e.target.value;
        setSelectedOption(val);
        if (val !== 'none') {
            onChange(`${val}`);
        }
    }

    useEffect(() => {
        if (dropdownOptions) {
            setAllOptions(dropdownOptions);
        }
        // eslint-disable-next-line  
    }, [])

    useEffect(() => {
        if (value) {
            setSelectedOption(value.value);
        }
        // eslint-disable-next-line  
    }, []);

    useEffect(() => {
        if (defaultValue) {
            setSelectedOption(defaultValue.value);
        }
        // eslint-disable-next-line  
    }, []);

    return (
        <Box component="div" className={'BBPCustomDropdown'}>
            <select
                id={id}
                disabled={disabled}
                readOnly={readonly}
                onChange={handleSelectYear}
                value={selectedOption}
            >
                <option value={'none'}>Select {label}</option>
                {allOptions.map((item, index) =>
                    <option value={item.value} key={index}>{item.label}</option>
                )}
            </select>
        </Box>
    );
}

export default YearField;