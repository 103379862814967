
import React, { createContext, useState, useEffect, Fragment } from 'react';
import { useAxiosPrivate } from '../Hooks/useAxiosPrivate';
import SomethingWentWrong from '../Components/SomethingWentWrong';

export const BillerContext = createContext();

export const BillerProvider = ({ children }) => {
    const axiosPrivate = useAxiosPrivate();
    const [dataLoading, setDataLoading] = useState(false);
    const [mainCategories, setMainCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [errorDialog, setErrorDialog] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            setDataLoading(true);

            const mainCat = await axiosPrivate.get(`billers/maincat/`).then(response => response.data.status === 1 ? response.data.data : []).catch(error => {
                setErrorDialog(true);
            });

            const subCat = await axiosPrivate.get(`billers/subcat/`).then(response => response.data.status === 1 ? response.data.data : []).catch(error => {
                setErrorDialog(true);
            });

            const newArr1 = mainCat.map(v => ({ ...v, sub_cats: [] }));
            newArr1.map((item) =>
                subCat.map(sub =>
                    item._id === sub.cat_id ? item.sub_cats.push(sub) : ''
                )
            )

            setMainCategories(mainCat);
            setSubCategories(subCat);
            setAllCategories(newArr1);
            setDataLoading(false);
        };
        fetchData();
        // eslint-disable-next-line 
    }, []);

    return (
        <Fragment>
            <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
            <BillerContext.Provider value={{ dataLoading, mainCategories, subCategories, allCategories }}>{children}</BillerContext.Provider>
        </Fragment>
    );
}