import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { MENU_SLUG } from '../../../Constants/constants';
import './style.css';

const BillerBox = ({ item, sub_cat_id, mobile_number, homeLink }) => {
    let location = useLocation();
    let pathWithoutLastPart = location.pathname.slice(0, location.pathname.lastIndexOf("/"));
    pathWithoutLastPart = pathWithoutLastPart.substring(1);

    return (
        <NavLink
            to={`/${pathWithoutLastPart === `${MENU_SLUG.pwa}` ? MENU_SLUG.pwa : MENU_SLUG.bill_payments}/${sub_cat_id}/${item._id}`}
            className={'BBPBPBBillerBox'}
            state={{ mobile_number: mobile_number, homeLink: homeLink }}
        >
            <Box component="div" className={'BBPBPBBBIcon'}>
                <img
                    loading={'lazy'}
                    src={`${item.image.location}`}
                    alt={item.name}
                />
            </Box>
            <Box component="div" className={'BBPBPBBBTitle'}>
                {item.name}
            </Box>
        </NavLink>
    );
};
export default BillerBox;  