import React, { useState, Fragment, useCallback, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useAxiosPrivate } from '../../../Hooks/useAxiosPrivate';
import PaymentReceipt from '../../../Components/PaymentReceipt';
import { BillerContext } from '../../../Contexts/billerCategories';
import { reformatedOption } from '../../../Utils/autocompleteGroup';
import { MENU_SLUG } from '../../../Constants/constants';
import { fetchIp } from '../../../Utils/index';
import PageChangeDialog from '../../../Components/PageChangeDialog';
import SomethingWentWrong from '../../../Components/SomethingWentWrong';
import BharatBillPay from '../../../Assets/Images/bhartbillpay.png';
import PaymentSuccess from './paymentPage/paymentSuccess';
import PaymentProgress from './paymentPage/paymentProgress';
import UpiStatus from './paymentPage/upiStatus';
import UpiTypes from '../../BillPayments/upiTypes';

import './style.css';

const PaymentPage = ({ billDetail, providerDetails, mobileNumber, formData, homeLink }) => {
    const axiosPrivate = useAxiosPrivate();
    let navigate = useNavigate();
    let location = useLocation();
    let pathWithoutLastPart = location.pathname.slice(0, location.pathname.lastIndexOf("/"));
    pathWithoutLastPart = pathWithoutLastPart.slice(0, pathWithoutLastPart.lastIndexOf("/"));

    const categories = useContext(BillerContext);
    let allOpt = reformatedOption(categories.allCategories, ['name|type']).filter(provider => provider._id === providerDetails.cat_id);

    const [changePage, setChangePage] = useState(false);

    const [errorDialog, setErrorDialog] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [paymentProgress, setPaymentProgress] = useState(false);
    const [progressTime, setProgressTime] = useState(0);
    const [selectedTab, setSelectedTab] = useState(0);

    const [upiAddress, setUpiAddress] = useState('');
    const [transactionID, setTransactionID] = useState('');
    const [upiError, setUpiError] = useState(false);

    const [paymentFailMassage, setPaymentFailMassage] = useState('');
    const [payLoader, setPayLoader] = useState(false);
    const [paymentLoader, setPaymentLoader] = useState(false);
    const [transactionDetails, setTransactionDetails] = useState({});

    const [viewReceipt, setViewReceipt] = useState(false);

    const openViewReceipt = () => {
        setViewReceipt(true);
    };

    const closeViewReceipt = () => {
        setViewReceipt(false);
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleCancelDialog = () => {
        setChangePage(true);
    };

    const handleUPIAddress = (e) => {
        setUpiError(false);
        setUpiAddress(e.target.value);
    };

    const handleBackToHome = () => {
        if (pathWithoutLastPart === `/${MENU_SLUG.pwa}`) {
            navigate(homeLink, { replace: true, state: { mobile_number: mobileNumber, link: homeLink } });
            let pwaBtns = document.querySelector('.BBPBPPTILPWA .BBPBPPTVBtmNav');
            pwaBtns.classList.remove("BBPBPPTILPWADisable");
        } else {
            navigate(`/${MENU_SLUG.bill_payments}`, { replace: true });
        }
        setPaymentProgress(false);
    };

    const handleCancelPay = () => {
        handleViewPayStatus();
        setChangePage(false);
        setPaymentSuccess(true);
        setPaymentProgress(false);
    };

    const handleAddTransactions = useCallback(async () => {
        try {
            let tempData = { ...billDetail };
            delete tempData.ResponseCode;
            delete tempData.ResponseMessage;
            setPayLoader(true);
            let ip = await fetchIp();
            let data = JSON.stringify({
                "transaction_provider": providerDetails.api_provider,
                "main_cat": `${allOpt[0].type}`,
                "sub_cat": `${allOpt[0].name}`,
                "biller": `${providerDetails.name}`,
                "biller_id": `${providerDetails.biller_id}`,
                "mobile_num": `${mobileNumber}`,
                "upi_id": `${upiAddress}`,
                "formData": formData,
                "init": tempData,
                "ip": `${ip}`,
            });
            let url = `transactions`;
            let options = {
                method: 'POST',
                url,
                data,
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setTransactionID(response.data.id);
                    setPaymentProgress(true);
                    setPaymentFailMassage('');
                } else {
                    setPaymentFailMassage(response.data.message);
                }
                setPayLoader(false);
            }).catch(err => {
                if (err.response) {
                    setPayLoader(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data)
                }
            });
        } catch (error) {
            setPayLoader(false);
            setErrorDialog(true);
            // console.error('error', error);
        }
        // eslint-disable-next-line
    }, [upiAddress]);

    const handlePWAAddTransactions = useCallback(async (otherUpi, upiAppType) => {
        try {
            let tempData = { ...billDetail };
            delete tempData.ResponseCode;
            delete tempData.ResponseMessage;
            setPayLoader(true);
            let ip = await fetchIp();
            let data;
            if (otherUpi.length > 0) {
                data = JSON.stringify({
                    "transaction_provider": providerDetails.api_provider,
                    "main_cat": `${allOpt[0].type}`,
                    "sub_cat": `${allOpt[0].name}`,
                    "biller": `${providerDetails.name}`,
                    "biller_id": `${providerDetails.biller_id}`,
                    "mobile_num": `${mobileNumber}`,
                    "upi_id": `${otherUpi}`,
                    "formData": formData,
                    "init": tempData,
                    "ip": `${ip}`,
                });
            } else {
                data = JSON.stringify({
                    "transaction_provider": providerDetails.api_provider,
                    "main_cat": `${allOpt[0].type}`,
                    "sub_cat": `${allOpt[0].name}`,
                    "biller": `${providerDetails.name}`,
                    "biller_id": `${providerDetails.biller_id}`,
                    "mobile_num": `${mobileNumber}`,
                    "formData": formData,
                    "init": tempData,
                    "ip": `${ip}`,
                });
            }
            let url = `transactions`;
            let options = {
                method: 'POST',
                url,
                data,
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setTransactionID(response.data.id);
                    setPaymentProgress(true);
                    setPaymentFailMassage('');
                    if (otherUpi.length === 0) {
                        let upiUrl = `upi://pay?pa=${process.env.REACT_APP_BBPS_ICICI_MERCHANT_VPA}&pn=${process.env.REACT_APP_BBPS_ICICI_MERCHANT_NAME}&tr=${response.data.qrID}&am=${response.data.amount}&cu=INR&mc=${process.env.REACT_APP_BBPS_ICICI_SUB_TERMINAL_ID}`;
                        console.log('upiUrl:', upiUrl)
                        console.log('upiAppType:', upiAppType)
                        window.location.href = upiUrl;
                    }
                } else {
                    setPaymentFailMassage(response.data.message);
                }
                setPayLoader(false);
            }).catch(err => {
                if (err.response) {
                    setPayLoader(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data)
                }
            });
        } catch (error) {
            setPayLoader(false);
            setErrorDialog(true);
            // console.error('error', error);
        }
        // eslint-disable-next-line
    }, [upiAddress]);

    const checkPayStatus = useCallback(async () => {
        try {
            let url = `transactions/status/${transactionID}`;
            let options = {
                method: 'GET',
                url,
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 2) {
                    // Pending
                    setPaymentProgress(true);
                } else {
                    // Success || Failed
                    handleViewPayStatus();
                    setPaymentProgress(false);
                    setPaymentSuccess(true);
                }
                setPayLoader(false);
            }).catch(err => {
                if (err.response) {
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data)
                }
            });
        } catch (error) {
            setErrorDialog(true);
            // console.error('error', error);
        }
        // eslint-disable-next-line
    }, [transactionID]);

    const handleViewPayStatus = useCallback(async () => {
        try {
            setPaymentLoader(true);
            let url = `transactions/viewPayStatus/${transactionID}`;
            let options = {
                method: 'GET',
                url,
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setTransactionDetails(response.data.data);
                } else {
                    setErrorDialog(true);
                    // console.error('err.res', response.data)
                }
                setPaymentLoader(false);
            }).catch(err => {
                if (err.response) {
                    setPaymentLoader(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data)
                }
            });
        } catch (error) {
            setPaymentLoader(false);
            setErrorDialog(true);
            // console.error('error', error);
        }
        // eslint-disable-next-line
    }, [transactionID]);

    return (
        <Fragment>
            <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
            <PageChangeDialog showDialog={changePage} setShowDialog={setChangePage} handleConfirm={handleCancelPay} />
            <PaymentReceipt
                open={viewReceipt}
                transactionId={transactionID}
                onClose={closeViewReceipt}
                handleCancel={closeViewReceipt}
            />
            <Box component="div" className={'BBPBPPaymentPage'}>
                <Box component="div" className={'BBPBPPPInner'}>
                    {pathWithoutLastPart !== `/${MENU_SLUG.pwa}` &&
                        <Box component="div" className={'BBPBPPPITitle'}>
                            Payment Details
                            <Box component="div" className={'BBPBPPPILogo'}>
                                <img src={BharatBillPay} alt={'Bharat Bill Payment'} />
                            </Box>
                        </Box>
                    }
                    <Box component="div" className={'BBPBPPPITypes'}>
                        <Box component="div" className={'BBPBPPPITInner'}>
                            {pathWithoutLastPart !== `/${MENU_SLUG.pwa}` &&
                                <Box component="div" className={'BBPBPPPITCol1'}>
                                    <Box component="div" className={'BBPBPPPTabs'}>
                                        <Tabs
                                            value={selectedTab}
                                            onChange={handleTabChange}
                                            orientation="vertical"
                                            variant="scrollable"
                                            scrollButtons
                                            allowScrollButtonsMobile
                                            classes={{
                                                root: 'BBPBPPPTBtns',
                                                flexContainer: 'BBPBPPPTBScroller',
                                                scrollButtons: 'BBPBPPPTBSBtn',
                                                indicator: 'BBPBPPPTBSBIndicator'
                                            }}
                                        >
                                            <Tab label="BHIM UPI" classes={{ root: 'BBPBPPPTBtn' }} />
                                        </Tabs>
                                    </Box>
                                </Box>
                            }
                            <Box component="div" className={'BBPBPPPITCol2'}>
                                <Box component="div" className={'BBPBPPPTPanel'}>
                                    {paymentSuccess ?
                                        <PaymentSuccess
                                            paymentUpdateLoader={paymentLoader}
                                            transactionDetails={transactionDetails}
                                            openViewReceipt={openViewReceipt}
                                            handleBackToHome={handleBackToHome}
                                        />
                                        :
                                        <Fragment>
                                            {paymentProgress ?
                                                <PaymentProgress
                                                    handleCancelPayment={handleCancelDialog}
                                                    paymentProgress={paymentProgress}
                                                    checkPayStatus={checkPayStatus}
                                                    progressTime={progressTime}
                                                    setProgressTime={setProgressTime}
                                                    setPaymentSuccess={setPaymentSuccess}
                                                    handleViewPayStatus={handleViewPayStatus}
                                                />
                                                :
                                                <Fragment>
                                                    {pathWithoutLastPart === `/${MENU_SLUG.pwa}` ?
                                                        <UpiTypes
                                                            billDetail={billDetail}
                                                            handleAddTransactions={handlePWAAddTransactions}
                                                            paymentFailMassage={paymentFailMassage}
                                                            payLoader={payLoader}
                                                        />
                                                        :
                                                        <UpiStatus
                                                            providerDetails={providerDetails}
                                                            billDetail={billDetail}
                                                            allOpt={allOpt}
                                                            upiAddress={upiAddress}
                                                            handleUPIAddress={handleUPIAddress}
                                                            upiError={upiError}
                                                            paymentFailMassage={paymentFailMassage}
                                                            handleBackToHome={handleBackToHome}
                                                            payLoader={payLoader}
                                                            handleAddTransactions={handleAddTransactions}
                                                        />
                                                    }
                                                </Fragment>
                                            }
                                        </Fragment>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Fragment>
    );
};
export default PaymentPage;
