import React, { Fragment, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import PhonePeIcon from '../../../Components/Icons/PhonePeIcon';
import GooglePayIcon from '../../../Components/Icons/GooglePayIcon';
import AmazonPayIcon from '../../../Components/Icons/AmazonPayIcon';
import BHIMUPIIcon from '../../../Components/Icons/BHIMUPIIcon';
import UpiIcon from '../../../Components/Icons/UpiIcon';
import FormTextField from '../../../Components/FormFields/TextField';
import './style.css';

const UPITypes = ({ billDetail, paymentFailMassage, handleAddTransactions, payLoader }) => {

    const [value, setValue] = useState('');
    const [otherUpi, setOtherUpi] = useState('');
    const handleOtherUpi = (event) => {
        setOtherUpi(event.target.value);
    };
    const handleChange = (event) => {
        setValue(event.target.value);
        setOtherUpi('');
    };

    return (
        <Fragment>
            <Box component="div" className={"BBPPWAUPITypes"}>
                <Box component="div" className={"BBPPWAUPITTitle"}>
                    Payment Method
                </Box>
                <Box component="div" className={"BBPPWAUPIInfo"}>
                    <RadioGroup
                        className={"BBPPWAUPIIGroup"}
                        value={value}
                        onChange={handleChange}
                    >
                        <FormControlLabel
                            value="phonepe"
                            control={<Radio className={"BBPPWAUPIIRadio"} />}
                            label={
                                <Fragment>
                                    <Box component="strong">PhonePe</Box>
                                    <Box component="span"><PhonePeIcon /></Box>
                                </Fragment>
                            }
                            classes={{
                                root: 'BBPPWAUPIIRoot',
                                label: 'BBPPWAUPIILable'
                            }}
                        />
                        <FormControlLabel
                            value="googlepay"
                            control={<Radio className={"BBPPWAUPIIRadio"} />}
                            label={
                                <Fragment>
                                    <Box component="strong">Google Pay</Box>
                                    <Box component="span"><GooglePayIcon /></Box>
                                </Fragment>
                            }
                            classes={{
                                root: 'BBPPWAUPIIRoot',
                                label: 'BBPPWAUPIILable'
                            }}
                        />
                        <FormControlLabel
                            value="amazonpay"
                            control={<Radio className={"BBPPWAUPIIRadio"} />}
                            label={
                                <Fragment>
                                    <Box component="strong">Amazon Pay</Box>
                                    <Box component="span"><AmazonPayIcon /></Box>
                                </Fragment>
                            }
                            classes={{
                                root: 'BBPPWAUPIIRoot',
                                label: 'BBPPWAUPIILable'
                            }}
                        />
                        <FormControlLabel
                            value="bhimupi"
                            control={<Radio className={"BBPPWAUPIIRadio"} />}
                            label={
                                <Fragment>
                                    <Box component="strong">BHIM UPI</Box>
                                    <Box component="span"><BHIMUPIIcon /></Box>
                                </Fragment>
                            }
                            classes={{
                                root: 'BBPPWAUPIIRoot',
                                label: 'BBPPWAUPIILable'
                            }}
                        />
                        <FormControlLabel
                            value="otherupiapp"
                            control={<Radio className={"BBPPWAUPIIRadio"} />}
                            label={
                                <Fragment>
                                    <Box component="strong">Other UPI App</Box>
                                    <Box component="span"><UpiIcon /></Box>
                                </Fragment>
                            }
                            classes={{
                                root: 'BBPPWAUPIIRoot',
                                label: 'BBPPWAUPIILable'
                            }}
                        />
                        <FormControlLabel
                            value="other"
                            control={<Radio className={"BBPPWAUPIIRadio"} />}
                            label={
                                <Fragment>
                                    <Box component="strong">Other</Box>
                                    {value === "other" &&
                                        <FormTextField
                                            value={otherUpi}
                                            fullWidth={true}
                                            autoFocus={true}
                                            placeholder={'Enter VPA / UPI ID'}
                                            fieldClass={'BBPPWAUPIIOField'}
                                            onChange={handleOtherUpi}
                                        />
                                    }
                                </Fragment>
                            }
                            classes={{
                                root: `BBPPWAUPIIRoot ${value === "other" && 'BBPPWAUPIIOth'}`,
                                label: 'BBPPWAUPIILable'
                            }}
                        >
                        </FormControlLabel>
                    </RadioGroup>
                </Box>
                {paymentFailMassage.length > 0 ?
                    <Box component="div" className={'BBPPWAUPIError'}>
                        {paymentFailMassage}
                    </Box>
                    : ''}
                <Box component="div" className={"BBPPWAUPIBtn"}>
                    <Button
                        variant="contained"
                        disabled={value.length === 0 || payLoader}
                        fullWidth
                        className={"BBPButton"}
                        onClick={() => handleAddTransactions(otherUpi, value)}
                    >
                        Pay {`₹${billDetail.billAmount || billDetail.BillAmount}`}
                    </Button>
                </Box>
            </Box>
        </Fragment>
    );
};
export default UPITypes; 