import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function MunicipalTaxIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 64 64" fontSize="inherit">
            <g transform="translate(-852 -2340)">
                <g transform="translate(863.156 2353.9)">
                    <path d="M32.971,38.7a8.278,8.278,0,0,1,0-16.555.69.69,0,1,1,0,1.38,6.9,6.9,0,1,0,4.878,2.02.69.69,0,1,1,.975-.975A8.278,8.278,0,0,1,32.971,38.7ZM21.924,6.249H17.785V3.49h4.139L20.741,4.87ZM19.865,24.872H15.727a.69.69,0,0,1-.69-.69V20.044a.69.69,0,0,1,.69-.69h4.139a.69.69,0,0,1,.69.69v4.139A.69.69,0,0,1,19.865,24.872Zm-3.449-1.38h2.759V20.733H16.416ZM7.449,31.781H4.69a.69.69,0,0,1-.69-.69V28.332a.69.69,0,0,1,.69-.69H7.449a.69.69,0,0,1,.69.69v2.759A.69.69,0,0,1,7.449,31.781ZM5.38,30.4h1.38v-1.38H5.38Zm2.069-4.139H4.69a.69.69,0,0,1-.69-.69V22.814a.69.69,0,0,1,.69-.69H7.449a.69.69,0,0,1,.69.69v2.759A.69.69,0,0,1,7.449,26.263ZM5.38,24.883h1.38V23.5H5.38Zm7.588,6.9H10.208a.69.69,0,0,1-.69-.69V28.332a.69.69,0,0,1,.69-.69h2.759a.69.69,0,0,1,.69.69v2.759A.69.69,0,0,1,12.967,31.781ZM10.9,30.4h1.38v-1.38H10.9Zm8.278,5.365H16.416a.69.69,0,0,1-.69-.69V28.323a.69.69,0,0,1,.69-.69h2.759a.69.69,0,0,1,.69.69v6.754A.69.69,0,0,1,19.175,35.767Zm-2.069-1.38h1.38V29.012h-1.38Z" transform="translate(0.139 -2.799)" fill="#175783" />
                    <path d="M16.278,9.887a.69.69,0,0,1-.69-.69V3.679a.69.69,0,0,1,1.38,0V9.2A.69.69,0,0,1,16.278,9.887Zm2.069,19.172H14.208a.69.69,0,1,1,0-1.38h4.139a.69.69,0,1,1,0,1.38Zm-6.9-2.606H8.69a.69.69,0,0,1-.69-.69V23a.69.69,0,0,1,.69-.69h2.759a.69.69,0,0,1,.69.69v2.759A.69.69,0,0,1,11.449,26.453ZM9.38,25.073h1.38v-1.38H9.38Z" transform="translate(1.658 -2.989)" fill="#175783" />
                    <path d="M22.106,16.642H9.69a.69.69,0,0,1-.552-1.1l6.208-8.278a.714.714,0,0,1,1.1,0l6.208,8.278a.686.686,0,0,1-.552,1.1Zm-11.037-1.38h9.657L15.9,8.823l-4.829,6.439ZM22.106,37.335H9.69a.69.69,0,0,1-.69-.69V33.886a.69.69,0,0,1,.69-.69H22.106a.69.69,0,0,1,.69.69v2.759A.69.69,0,0,1,22.106,37.335ZM10.38,35.956H21.416v-1.38H10.38ZM33.833,22.88a.69.69,0,0,1-.69-.69V17.362a.69.69,0,0,1,1.38,0V22.19A.69.69,0,0,1,33.833,22.88Z" transform="translate(2.037 -1.466)" fill="#175783" />
                    <path d="M29.259,17.21a.693.693,0,0,1-.488-.2L26.7,14.939a.69.69,0,1,1,.975-.975l2.069,2.069a.69.69,0,0,1-.487,1.177Z" transform="translate(8.68 1.1)" fill="#175783" />
                    <path d="M31.384,17.5a.69.69,0,0,1-.488-1.177l2.069-2.069a.69.69,0,1,1,.975.975L31.871,17.3A.687.687,0,0,1,31.384,17.5ZM20.347,32.3H10.69a.69.69,0,0,1-.69-.69V13.679a.69.69,0,0,1,.69-.69h9.657a.69.69,0,0,1,.69.69V31.613A.69.69,0,0,1,20.347,32.3Zm-8.967-1.38h8.278V14.369H11.38Z" transform="translate(2.417 0.807)" fill="#175783" />
                    <path d="M27.522,34.3H2.69a.69.69,0,0,1-.69-.69V15.679a.69.69,0,0,1,1.38,0V32.924H27.522a.69.69,0,1,1,0,1.38Z" transform="translate(-0.62 1.566)" fill="#175783" />
                    <path d="M25.143,36.2H1.69a.69.69,0,1,1,0-1.38H25.143a.69.69,0,0,1,0,1.38ZM25.5,11.369H22.384a.69.69,0,1,1,0-1.38H25.5a.69.69,0,1,1,0,1.38Zm-11.394,6.9H1.69a.688.688,0,0,1-.64-.946l2.759-6.9a.69.69,0,0,1,.64-.433H15.486a.69.69,0,0,1,0,1.38H4.915L2.708,16.887h11.4a.69.69,0,0,1,0,1.38Z" transform="translate(-1 -0.332)" fill="#175783" />
                    <path d="M29.106,14.818a.69.69,0,0,1-.64-.433L27.26,11.369H16.69a.69.69,0,1,1,0-1.38H27.727a.688.688,0,0,1,.64.433l1.38,3.449a.687.687,0,0,1-.64.946Zm-2.759,3.449H18.069a.69.69,0,0,1,0-1.38h8.278a.69.69,0,0,1,0,1.38Zm4.829,6.238H27.037a.69.69,0,1,1,0-1.38h4.139a.69.69,0,0,1,0,1.38Zm0,2.78H25.657a.69.69,0,0,1,0-1.38h5.518a.69.69,0,0,1,0,1.38Zm-1.38,6.9a.693.693,0,0,1-.488-.2l-4.139-4.139a.69.69,0,1,1,.975-.975l4.139,4.139a.69.69,0,0,1-.487,1.177Z" transform="translate(4.694 -0.332)" fill="#175783" />
                    <path d="M24.569,26.409H23.19a.69.69,0,1,1,0-1.38h1.38a2.069,2.069,0,0,0,0-4.139H23.19a.69.69,0,1,1,0-1.38h1.38a3.449,3.449,0,0,1,0,6.9Z" transform="translate(7.162 3.283)" fill="#175783" />
                </g>
            </g>
        </SvgIcon>
    );
}