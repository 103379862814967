import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function SubscriptionIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 63 64" fontSize="inherit">
            <g transform="translate(10.309 10.828)">
                <path d="M1.7,50.183H9.83a4.869,4.869,0,1,0,5.092-6.9v-2.84a3.484,3.484,0,0,0-3.48-3.48H10.155a3.828,3.828,0,1,0-4.385,0H4.48A3.484,3.484,0,0,0,1,40.438v9.049A.7.7,0,0,0,1.7,50.183Zm12.529-5.568a3.48,3.48,0,1,1-3.48,3.48,3.48,3.48,0,0,1,3.48-3.48Zm-8.7-10.789a2.436,2.436,0,1,1,2.436,2.436,2.436,2.436,0,0,1-2.436-2.436ZM2.392,40.438A2.088,2.088,0,0,1,4.48,38.35h6.961a2.088,2.088,0,0,1,2.088,2.088v2.84a4.825,4.825,0,0,0-4.121,5.513H2.392Z" transform="translate(-1 -9.812)" fill="#175783" />
                <path d="M17.406,41.337a.7.7,0,0,0,.984,0l2.953-2.953-.984-.984L17.9,39.86l-.985-.985-.984.984ZM27.811,21.186h9.411a6.259,6.259,0,1,0,9.383-8.136V3.784A2.784,2.784,0,0,0,43.821,1H27.811a2.784,2.784,0,0,0-2.784,2.784V18.4A2.784,2.784,0,0,0,27.811,21.186Zm19.49-3.48a4.872,4.872,0,1,1-4.872-4.872A4.872,4.872,0,0,1,47.3,17.705ZM26.419,3.784a1.392,1.392,0,0,1,1.392-1.392H43.821a1.392,1.392,0,0,1,1.392,1.392v.7H26.419Zm0,2.088H45.213V12.1a6.249,6.249,0,0,0-8.683,7.691H27.811A1.392,1.392,0,0,1,26.419,18.4Z" transform="translate(-5.538 -1)" fill="#175783" />
                <rect width="2.088" height="1.392" transform="translate(27.146 7.657)" fill="#175783" />
                <rect width="2.088" height="1.392" transform="translate(31.323 7.657)" fill="#175783" />
                <rect width="2.088" height="1.392" transform="translate(35.499 7.657)" fill="#175783" />
                <rect width="2.088" height="1.392" transform="translate(27.146 11.137)" fill="#175783" />
                <rect width="2.088" height="1.392" transform="translate(22.97 11.137)" fill="#175783" />
                <rect width="2.088" height="1.392" transform="translate(27.146 14.617)" fill="#175783" />
                <rect width="2.088" height="1.392" transform="translate(22.97 14.617)" fill="#175783" />
                <path d="M14.81,9.138l.381,1.094a.7.7,0,0,0,.534.456.732.732,0,0,0,.124.011.7.7,0,0,0,.535-.251l2.6-3.119a.7.7,0,0,0-.4-1.127l-3.975-.824a.7.7,0,0,0-.8.911L14.339,7.8A18.8,18.8,0,0,0,5.727,19.568l1.355.319A17.408,17.408,0,0,1,14.81,9.138Zm2.359-1.81L16.1,8.612,15.534,6.99ZM42.3,28.24l-1.355-.319A17.392,17.392,0,0,1,27.959,40.846l-.308-1.38a.7.7,0,0,0-1.159-.352l-2.942,2.8a.7.7,0,0,0,.26,1.165l3.852,1.279a.706.706,0,0,0,.22.035.7.7,0,0,0,.679-.848l-.3-1.337A18.784,18.784,0,0,0,42.3,28.24ZM25.35,42.119l1.21-1.15.374,1.676Z" transform="translate(-2.437 -2.326)" fill="#175783" />
                <g transform="translate(34.615 13.536)">
                    <g transform="translate(0 0)">
                        <g >
                            <line x1="3.821" transform="translate(0.036)" fill="none" stroke="#175783" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                            <line x1="3.821" transform="translate(0.036 1.91)" fill="none" stroke="#175783" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                            <path d="M10,6h.771A1.928,1.928,0,0,1,12.7,7.928h0a1.928,1.928,0,0,1-1.928,1.928H10l3.085,3.085" transform="translate(-10 -5.937)" fill="none" stroke="#175783" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}