import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function GoldIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 64 64" fontSize="inherit">
            <g transform="translate(9.25 10.334)">
                <path d="M44.5,35.924H1.221c-.74-2.959.555-5.549,1.295-8.138a24.3,24.3,0,0,1,.925-2.774H7.509c.925-2.774,1.85-5.364,2.774-8.323h4.069c.925-2.774,1.85-5.364,2.774-8.323H27.854c.925,2.589,1.85,5.179,2.959,8.138H34.7c.925,2.774,2.034,5.549,2.959,8.323h3.884c.74,2.034,1.295,3.884,2.034,5.919.185.555.185,1.295.925,1.48Zm-25.524-24.6c-.74,1.85-1.295,3.514-2.034,5.364h11.1c-.555-1.85-1.11-3.7-1.665-5.364Zm-6.843,8.138c-.74,1.665-1.295,3.514-2.035,5.364H21.2c-.555-1.85-1.11-3.514-1.665-5.364Zm22.934,5.364c-.555-1.85-1.11-3.7-1.665-5.364H26c-.74,1.85-1.295,3.514-2.034,5.364ZM12.688,27.786H5.1c-.74,1.85-1.295,3.514-1.85,5.179a55.616,55.616,0,0,0,11.1,0C13.8,31.3,13.243,29.635,12.688,27.786Zm13.687,0h-7.4c-.74,1.85-1.295,3.514-1.849,5.179a55.615,55.615,0,0,0,11.1,0A42.675,42.675,0,0,1,26.375,27.786Zm6.288,0c-.74,1.665-1.295,3.514-2.034,5.364a55.547,55.547,0,0,0,11.1,0c-.555-1.665-1.11-3.514-1.665-5.179C37.657,27.786,35.252,27.786,32.663,27.786Z" transform="translate(0 5.408)" fill="#175783" />
                <path d="M20.305,10.6,19.011,9.3a1.236,1.236,0,0,1,0-1.85A34.178,34.178,0,0,1,21.6,4.866,26.357,26.357,0,0,1,23.819,6.9C22.71,8.195,21.415,9.49,20.305,10.6Z" transform="translate(14.947 2.435)" fill="#175783" />
                <path d="M3.719,7.185,5.939,4.966A7.95,7.95,0,0,1,7.788,6.63a16.675,16.675,0,0,0,1.85,1.665L7.049,10.884C5.939,9.589,4.829,8.295,3.719,7.185Z" transform="translate(2.31 2.519)" fill="#175783" />
                <path d="M15.064,2.121V7.485c-.925.185-2.034,0-3.144.185V2.306A5.954,5.954,0,0,1,15.064,2.121Z" transform="translate(9.276)" fill="#175783" />
            </g>
        </SvgIcon>
    );
}