export const MENU_SLUG = {
    login: 'login',
    dashboard: 'dashboard',
    pwa: 'pwa',
    bill_payments: 'bill-payments',
    complaints_status: 'complaint-status',
    analytics: 'analytics',
    reports: 'reports',
    billers: 'billers',
    users: 'users',
    commissions: 'commissions',
    complaints: 'complaints',
    settings: 'settings',
};
