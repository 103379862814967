import React, { Fragment, useState, useRef, useCallback, useLayoutEffect, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { format } from 'date-fns';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import Skeleton from '@mui/material/Skeleton';
import TransactionsInfo from './transactionsInfo';
import { useAxiosPrivate } from '../../../Hooks/useAxiosPrivate';
import SomethingWentWrong from '../../../Components/SomethingWentWrong';
import NoData from '../../../Components/NoData';
import NoDataImg from '../../../Assets/Images/no-data.png';
import './style.css';

const TransactionsTab = () => {

    let location = useLocation();
    const axiosPrivate = useAxiosPrivate();

    const mobile_number = location.state && location.state.mobile_number;

    const boxHeightRef = useRef(null);
    const [transactionInfoDialog, setTransactionsInfoDialog] = useState(false);
    const [transactionInfo, setTransactionsInfo] = useState({});
    const [allTransactions, setAllTransactions] = useState([]);
    const [totalData, setSetTotalData] = useState(0);
    const [selectedPage, setSelectedPage] = useState(1);
    const [limitData] = useState(20);
    const [apiParams] = useState({ limit: limitData });
    const [hasMoreData, setHasMoreData] = useState(true);
    const [loading, setLoading] = useState(true);
    const [boxHeigh, setBoxHeigh] = useState(null);
    const [errorDialog, setErrorDialog] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);

    const handleTransactionInfoOpen = (info) => {
        setTransactionsInfoDialog(true);
        setTransactionsInfo(info);
    }

    const handleTransactionInfoClose = () => {
        setTransactionsInfoDialog(false);
        setTransactionsInfo({});
    }

    const fetchTransaction = useCallback(async (params) => {
        try {
            let urlParams = '';
            if (params) {
                Object.keys(params).forEach(function (key, index) {
                    urlParams += (index === 0 ? '?' : '&') + key + '=' + params[key];
                });
            }
            let url = `transactions/${mobile_number}/${urlParams}`;
            let options = {
                method: 'GET',
                url
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setAllTransactions(allTransactions.concat(Array.from(response.data.data)));
                    setSelectedPage(parseInt(params.page + 1));
                    setSetTotalData(response.data.total);
                } else {
                    setAllTransactions([]);
                }
                setLoading(false);
            }).catch(err => {
                if (err.response) {
                    setLoading(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setLoading(false);
            setErrorDialog(true);
            // console.error('error', error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allTransactions]);

    const handleFetch = useCallback(() => {
        let prm = apiParams;
        prm.page = selectedPage;
        if (allTransactions.length !== totalData) {
            fetchTransaction(prm, false);
        } else {
            setHasMoreData(false);
        }
    }, [allTransactions.length, apiParams, fetchTransaction, selectedPage, totalData]);

    useEffect(() => {
        if (firstLoad) {
            fetchTransaction({ limit: limitData, page: selectedPage });
            setFirstLoad(false);
        }
    }, [fetchTransaction, firstLoad, limitData, selectedPage]);

    useLayoutEffect(() => {
        /**
         * To measure box height, Using ref [useRef hooks] object to measure height and
         * setting it into states which is being used to set height of infinite scroll bar
         */
        function updateSize() {
            if (boxHeightRef.current) {
                const height = boxHeightRef.current.getBoundingClientRect().height;
                setBoxHeigh(height);
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [boxHeightRef, boxHeigh]);


    return (
        <Fragment>
            <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
            {Object.keys(transactionInfo).length > 0 &&
                <TransactionsInfo
                    info={transactionInfo}
                    open={transactionInfoDialog}
                    handleClose={handleTransactionInfoClose}
                />}
            <Box component="div" className={"BBPPWATransTab"}>
                <Box component="div" className={"BBPPWATTTitle"}>
                    All Transactions
                </Box>
                <Box component="div" className={"BBPPWATBody"} ref={boxHeightRef}>
                    {loading ?
                        <Box component="div" className={"BBPPWATBList"}>
                            {Array.from(Array(15).keys()).map((item) => (
                                <Box component="div" className={'BBPPWATBLItem'} key={item} >
                                    <Box component="div" className={'BBPPWATBLIInner'}>
                                        <Box component="div" className={'BBPPWATBLIInfo'}>
                                            <Box component="div" className={'BBPPWATBLIITitle'}>
                                                <Box component="div" className={'BBPPWATBLIIText'}>
                                                    <Skeleton component="div" variant="rounded" height={22} />
                                                </Box>
                                                <Box className={'BBPPWATBLIIPrice'}>
                                                    <Skeleton component="div" variant="rounded" height={22} />
                                                </Box>
                                            </Box>
                                            <Box component="div" className={'BBPPWATBLIIDV'}>
                                                <Box component="div" className={'BBPPWATBLIIDate'}>
                                                    <Skeleton component="div" variant="rounded" height={22} />
                                                </Box>
                                                <Box component="div" className={'BBPPWATBLIIStatus'}>
                                                    <Skeleton component="div" variant="rounded" height={22} />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                        :
                        <Fragment>
                            {allTransactions.length > 0 ?
                                <InfiniteScroll
                                    className={'BBPPWATBList'}
                                    dataLength={allTransactions.length}
                                    next={handleFetch}
                                    hasMore={hasMoreData}
                                    loader={
                                        <Fragment>
                                            {Array.from(Array(15).keys()).map((item) => (
                                                <Box component="div" className={'BBPPWATBLItem'} key={item} >
                                                    <Box component="div" className={'BBPPWATBLIInner'}>
                                                        <Box component="div" className={'BBPPWATBLIInfo'}>
                                                            <Box component="div" className={'BBPPWATBLIITitle'}>
                                                                <Box component="div" className={'BBPPWATBLIIText'}>
                                                                    <Skeleton component="div" variant="rounded" height={22} />
                                                                </Box>
                                                                <Box className={'BBPPWATBLIIPrice'}>
                                                                    <Skeleton component="div" variant="rounded" height={22} />
                                                                </Box>
                                                            </Box>
                                                            <Box component="div" className={'BBPPWATBLIIDV'}>
                                                                <Box component="div" className={'BBPPWATBLIIDate'}>
                                                                    <Skeleton component="div" variant="rounded" height={22} />
                                                                </Box>
                                                                <Box component="div" className={'BBPPWATBLIIStatus'}>
                                                                    <Skeleton component="div" variant="rounded" height={22} />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Fragment>
                                    }
                                    height={boxHeigh ? boxHeigh : 400}
                                >
                                    {allTransactions.map((item, index) =>
                                        <ListItemButton className={`BBPPWATBLItem ${item.status}`} key={index} onClick={() => { handleTransactionInfoOpen(item); }}>
                                            <Box component="div" className={"BBPPWATBLIInner"}>
                                                <Box component="div" className={"BBPPWATBLIInfo"}>
                                                    <Box component="div" className={"BBPPWATBLIITitle"}>
                                                        <Box component="div" className={"BBPPWATBLIIText"}>Paid to {`${item.main_cat} -> ${item.sub_cat} -> ${item.biller}`}</Box>
                                                        <Box component="div" className={"BBPPWATBLIIPrice"}>{`₹${item.billAmount}`}</Box>
                                                    </Box>
                                                    <Box component="div" className={"BBPPWATBLIIDV"}>
                                                        <Box component="div" className={"BBPPWATBLIIDate"}>{format(new Date(item.createdAt), "dd-MM-yyyy")}</Box>
                                                        <Box component="div" className={"BBPPWATBLIIStatus"}>{item.status}</Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </ListItemButton>
                                    )}
                                </InfiniteScroll>
                                :
                                <Box component="div" className={'BBPPWATNot'}>
                                    <NoData
                                        image={NoDataImg}
                                        title={'No Transaction'}
                                        description={'Reference site about Lorem Ipsum, giving information on its origins, as well as a random Ipsum generator.'}
                                    />
                                </Box>
                            }
                        </Fragment>
                    }
                </Box>
            </Box>
        </Fragment>
    );
};
export default TransactionsTab; 