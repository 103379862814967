import React, { Fragment, useContext } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import ErrorIcon from '../../../Components/Icons/ErrorIcon';
import { BillerContext } from '../../../Contexts/billerCategories';
import { reformatedOption } from '../../../Utils/autocompleteGroup';
import { dateFormatter } from '../../../Utils/index';
import './style.css';

const FinacusBillDetails = ({ billDetail, allOpt, providerDetails }) => {
    return (
        <Fragment>
            {billDetail.ResponseCode === '000' ?
                <Box component="div" className={'BBPBPBillDetails'}>
                    <Box component="div" className={'BBPBPBDTitle'}>
                        Bill Details
                    </Box>
                    <Box component="div" className={'BBPBPBDInfo'}>
                        {providerDetails ?
                            <Box component="div" className={'BBPBPBDIBox'}>
                                <Box component="div" className={'BBPBPBDIBTitle'}>
                                    Bill Category
                                </Box>
                                <Box component="div" className={'BBPBPBDIBSTitle'}>
                                    {allOpt ? allOpt[0].name : ''}
                                </Box>
                            </Box> : ''}
                        {providerDetails ?
                            <Box component="div" className={'BBPBPBDIBox'}>
                                <Box component="div" className={'BBPBPBDIBTitle'}>
                                    Biller Name
                                </Box>
                                <Box component="div" className={'BBPBPBDIBSTitle'}>
                                    {providerDetails.name}
                                </Box>
                            </Box> : ''}
                        {billDetail.BillPeriod ?
                            <Box component="div" className={'BBPBPBDIBox'}>
                                <Box component="div" className={'BBPBPBDIBTitle'}>
                                    Bill Period
                                </Box>
                                <Box component="div" className={'BBPBPBDIBSTitle'}>
                                    {billDetail.BillPeriod}
                                </Box>
                            </Box> : ''}
                        {billDetail.BillDate ?
                            <Box component="div" className={'BBPBPBDIBox'}>
                                <Box component="div" className={'BBPBPBDIBTitle'}>
                                    Bill Date
                                </Box>
                                <Box component="div" className={'BBPBPBDIBSTitle'}>
                                    {dateFormatter(billDetail.BillDate)}
                                </Box>
                            </Box> : ''}
                        {billDetail.BillAmount ?
                            <Box component="div" className={'BBPBPBDIBox'}>
                                <Box component="div" className={'BBPBPBDIBTitle'}>
                                    Bill Amount
                                </Box>
                                <Box component="div" className={'BBPBPBDIBSTitle'}>
                                    {billDetail.BillAmount}
                                </Box>
                            </Box> : ''}
                        {billDetail.BillDueDate ?
                            <Box component="div" className={'BBPBPBDIBox'}>
                                <Box component="div" className={'BBPBPBDIBTitle'}>
                                    Due Date
                                </Box>
                                <Box component="div" className={'BBPBPBDIBSTitle'}>
                                    {dateFormatter(billDetail.BillDueDate)}
                                </Box>
                            </Box> : ''}
                        {billDetail.BillNumber ?
                            <Box component="div" className={'BBPBPBDIBox'}>
                                <Box component="div" className={'BBPBPBDIBTitle'}>
                                    Bill Number
                                </Box>
                                <Box component="div" className={'BBPBPBDIBSTitle'}>
                                    {billDetail.BillNumber}
                                </Box>
                            </Box> : ''}
                        {billDetail.AccountNumber ?
                            <Box component="div" className={'BBPBPBDIBox'}>
                                <Box component="div" className={'BBPBPBDIBTitle'}>
                                    Account Number
                                </Box>
                                <Box component="div" className={'BBPBPBDIBSTitle'}>
                                    {billDetail.AccountNumber}
                                </Box>
                            </Box> : ''}
                        {billDetail.CustomerName ?
                            <Box component="div" className={'BBPBPBDIBox'}>
                                <Box component="div" className={'BBPBPBDIBTitle'}>
                                    Account Holder
                                </Box>
                                <Box component="div" className={'BBPBPBDIBSTitle'}>
                                    {billDetail.CustomerName}
                                </Box>
                            </Box> : ''}
                    </Box>
                </Box>
                :
                <Box component="div" className={'BBPBPCBIVError'}>
                    <Box component="div" className={'BBPBPCBIVEInner'}>
                        <Box component="div" className={'BBPBPCBIVEIIcon'}>
                            <ErrorIcon />
                        </Box>
                        <Box component="div" className={'BBPBPCBIVEIMsg'}>
                            {billDetail.ResponseMessage}
                        </Box>
                    </Box>
                </Box>
            }
        </Fragment>
    );
}

const MobikwikBillDetails = ({ billDetail, allOpt, providerDetails }) => {
    return (
        <Fragment>
            <Box component="div" className={'BBPBPBillDetails'}>
                <Box component="div" className={'BBPBPBDTitle'}>
                    Bill Details
                </Box>
                <Box component="div" className={'BBPBPBDInfo'}>
                    {providerDetails ?
                        <Box component="div" className={'BBPBPBDIBox'}>
                            <Box component="div" className={'BBPBPBDIBTitle'}>
                                Bill Category
                            </Box>
                            <Box component="div" className={'BBPBPBDIBSTitle'}>
                                {allOpt ? allOpt[0].name : ''}
                            </Box>
                        </Box> : ''}
                    {providerDetails ?
                        <Box component="div" className={'BBPBPBDIBox'}>
                            <Box component="div" className={'BBPBPBDIBTitle'}>
                                Biller Name
                            </Box>
                            <Box component="div" className={'BBPBPBDIBSTitle'}>
                                {providerDetails.name}
                            </Box>
                        </Box> : ''}
                    {billDetail.billNumber ?
                        <Box component="div" className={'BBPBPBDIBox'}>
                            <Box component="div" className={'BBPBPBDIBTitle'}>
                                Bill Number
                            </Box>
                            <Box component="div" className={'BBPBPBDIBSTitle'}>
                                {billDetail.billNumber}
                            </Box>
                        </Box> : ''}
                    {billDetail.billAmount ?
                        <Box component="div" className={'BBPBPBDIBox'}>
                            <Box component="div" className={'BBPBPBDIBTitle'}>
                                Bill Amount
                            </Box>
                            <Box component="div" className={'BBPBPBDIBSTitle'}>
                                {billDetail.billAmount}
                            </Box>
                        </Box> : ''}
                    {billDetail.billnetamount ?
                        <Box component="div" className={'BBPBPBDIBox'}>
                            <Box component="div" className={'BBPBPBDIBTitle'}>
                                Bill Net Amount
                            </Box>
                            <Box component="div" className={'BBPBPBDIBSTitle'}>
                                {billDetail.billnetamount}
                            </Box>
                        </Box> : ''}
                    {billDetail.dueFrom ?
                        <Box component="div" className={'BBPBPBDIBox'}>
                            <Box component="div" className={'BBPBPBDIBTitle'}>
                                Due From
                            </Box>
                            <Box component="div" className={'BBPBPBDIBSTitle'}>
                                {billDetail.dueFrom}
                            </Box>
                        </Box> : ''}
                    {billDetail.dueTo ?
                        <Box component="div" className={'BBPBPBDIBox'}>
                            <Box component="div" className={'BBPBPBDIBTitle'}>
                                Due To
                            </Box>
                            <Box component="div" className={'BBPBPBDIBSTitle'}>
                                {billDetail.dueTo}
                            </Box>
                        </Box> : ''}
                    {billDetail.billdate ?
                        <Box component="div" className={'BBPBPBDIBox'}>
                            <Box component="div" className={'BBPBPBDIBTitle'}>
                                Bill Date
                            </Box>
                            <Box component="div" className={'BBPBPBDIBSTitle'}>
                                {dateFormatter(billDetail.billdate)}
                            </Box>
                        </Box> : ''}
                    {billDetail.dueDate ?
                        <Box component="div" className={'BBPBPBDIBox'}>
                            <Box component="div" className={'BBPBPBDIBTitle'}>
                                Due Date
                            </Box>
                            <Box component="div" className={'BBPBPBDIBSTitle'}>
                                {dateFormatter(billDetail.dueDate)}
                            </Box>
                        </Box> : ''}
                    {billDetail.userName ?
                        <Box component="div" className={'BBPBPBDIBox'}>
                            <Box component="div" className={'BBPBPBDIBTitle'}>
                                Consumer Name
                            </Box>
                            <Box component="div" className={'BBPBPBDIBSTitle'}>
                                {billDetail.userName}
                            </Box>
                        </Box> : ''}
                    {billDetail.cellNumber ?
                        <Box component="div" className={'BBPBPBDIBox'}>
                            <Box component="div" className={'BBPBPBDIBTitle'}>
                                Consumer Number
                            </Box>
                            <Box component="div" className={'BBPBPBDIBSTitle'}>
                                {billDetail.cellNumber}
                            </Box>
                        </Box> : ''}
                </Box>
            </Box>
        </Fragment>
    );
}

const BillDetails = ({ loading, billDetail, providerDetails }) => {
    const categories = useContext(BillerContext);
    let allOpt = reformatedOption(categories.allCategories, ['name|type']).filter(provider => provider._id === providerDetails.cat_id);

    const viewBillDetails = (provider) => {
        switch (provider) {
            case 'Finacus':
                return <FinacusBillDetails
                    providerDetails={providerDetails}
                    billDetail={billDetail}
                    allOpt={allOpt}
                />
            case 'Mobikwik':
                return <MobikwikBillDetails
                    providerDetails={providerDetails}
                    billDetail={billDetail}
                    allOpt={allOpt}
                />
            default:
                break;
        }
    }

    return (
        <Fragment>
            {loading ?
                <Box component="div" className={'BBPBPBillDetails'}>
                    <Skeleton className={'BBPBPBDTitle'} variant="text" height={42} />
                    <Box component="div" className={'BBPBPBDInfo'}>
                        {Array.from(Array(8).keys()).map((item, index) => (
                            <Box component="div" key={index} className={'BBPBPBDIBox'}>
                                <Skeleton className={'BBPBPBDIBTitle'} variant="text" height={32} />
                                <Skeleton className={'BBPBPBDIBSTitle'} variant="text" height={25} />
                            </Box>
                        ))}
                    </Box>
                    <Skeleton className={'BBPBPBDTitle'} variant="text" height={42} />
                    <Box component="div" className={'BBPBPBDInfo'}>
                        {Array.from(Array(4).keys()).map((item, index) => (
                            <Box component="div" key={index} className={'BBPBPBDIBox'}>
                                <Skeleton className={'BBPBPBDIBTitle'} variant="text" height={32} />
                                <Skeleton className={'BBPBPBDIBSTitle'} variant="text" height={25} />
                            </Box>
                        ))}
                    </Box>
                </Box>
                :
                Object.keys(billDetail).length > 0 ? viewBillDetails(providerDetails.api_provider) : ''

            }
        </Fragment>
    );
};
export default BillDetails;
