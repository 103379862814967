import React, { useState, useCallback, Fragment, useEffect } from 'react';
import validator from "@rjsf/validator-ajv6";
import Form from "@rjsf/core";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ClearIcon from '@mui/icons-material/Clear';
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import SomethingWentWrong from '../SomethingWentWrong';
import Backdrops from '../Backdrops';
import SuccessDialog from '../SuccessDialog';
import './style.css';

const schema = {
    "type": "object",
    "required": ["feedback"],
    "properties": {
        "status": {
            "title": "Status",
            "enum": [
                "Completed",
                "Under Process",
            ],
            "default": "Under Process"
        },
        "feedback": {
            "title": "Feedback",
            "type": "string"
        },
    },
};

const uiSchema = {
    "ui:submitButtonOptions": {
        "props": {
            "className": "BBPFBtn"
        }
    },
    "status": {
        "ui:classNames": "BBPFFWidth"
    },
    "feedback": {
        "ui:widget": "textarea",
        "ui:classNames": "BBPFFWidth"
    }
};

const UpdateComplaint = ({ open, onClose, info }) => {

    function transformErrors(errors) {
        return errors.map(error => {
            let property;
            let matcheSB = (/\[(.*?)\]/).test(error.property);
            let matcheDot = /\./.test(error.property);
            if (matcheDot) {
                property = error.property.replaceAll('.', '');
            }
            if (matcheSB) {
                // eslint-disable-next-line
                property = error.property.replace(/[\[\]']+/g, '');
            }
            if (error.name === 'minLength') {
                error.message = `‘${schema.properties[property].title}’ should NOT be shorter than 10 characters.`;
            } else if (error.name === 'maxLength') {
                error.message = `‘${schema.properties[property].title}’ should NOT be longer than 10 characters.`;
            } else if (error.name === 'pattern') {
                error.message = `‘${schema.properties[property].title}’ is not valid.`;
            } else {
                error.message = `‘${schema.properties[property].title}’ is mandatory.`;
            }
            return error;
        });
    }

    const axiosPrivate = useAxiosPrivate();

    const [liveValidErr, setLiveValidErr] = useState(false);
    const [formData, setFormData] = useState({});
    const [errorDialog, setErrorDialog] = useState(false);
    const [onSubmitLoading, setOnSubmitLoading] = useState(false);
    const [submitDialog, setSubmitDialog] = useState(false);

    const handleUpdateComplaint = useCallback(async (data, id) => {
        try {
            setOnSubmitLoading(true);
            let url = `complaints/${id}`;
            let options = {
                method: 'PUT',
                url,
                data,
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setSubmitDialog(true);
                } else {
                    setErrorDialog(true);
                    // console.error('err.res', response.data)
                }
                setOnSubmitLoading(false);
            }).catch(err => {
                if (err.response) {
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data)
                }
            });
        } catch (error) {
            setErrorDialog(true);
            // console.error('error', error);
        }
        // eslint-disable-next-line
    }, []);

    const handleSubmitDialogClose = () => {
        setSubmitDialog(false);
        onClose();
    };

    let yourForm;

    const onSubmitNew = () => {
        yourForm.formElement.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    };

    const onFormSubmit = (formData) => {
        setLiveValidErr(false);
        setFormData(formData);
        handleUpdateComplaint(formData, info.id);
    };

    const onError = () => {
        setLiveValidErr(true);
    };

    useEffect(() => {
        if (Object.keys(info).length) {
            let temData = {};
            if (info.status !== "Pending") {
                temData.status = info.status;
                temData.feedback = info.feedback;
            }
            setFormData(temData);
        }
    }, [info]);

    return (
        <Fragment>
            <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
            <Backdrops
                open={onSubmitLoading}
                title={'Updating'}
            />
            <SuccessDialog
                open={submitDialog}
                onClose={handleSubmitDialogClose}
                title={'Complaint updated successfully'}
                message={'Complaint has been updated successfully.'}
                buttonTitle={'Close'}
            />
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={onClose}
            >
                <Box component="div" className={'BBPRCDialog'}>
                    <Box component="div" className={'BBPRCDHead'}>
                        <Box component="div" className={'BBPRCDHTitle'}>
                            Update Complaint
                        </Box>
                        <IconButton onClick={onClose}>
                            <ClearIcon />
                        </IconButton>
                    </Box>
                    <Box component="div" className={'BBPRCDForm'}>
                        <Box component='div' className={'BBPForm'}>
                            <Form
                                schema={schema}
                                uiSchema={uiSchema}
                                formData={formData}
                                validator={validator}
                                showErrorList={false}
                                omitExtraData={true}
                                liveValidate={liveValidErr}
                                transformErrors={transformErrors}
                                onError={onError}
                                onSubmit={({ formData }) => {
                                    onFormSubmit(formData);
                                }}
                                ref={(form) => { yourForm = form; }}
                            />
                        </Box>
                    </Box>
                    <Box component="div" className={'BBPRCDBtn'}>
                        <Button variant="contained" className={'BBPButton BBPOButton'} onClick={onClose}>Close</Button>
                        <Button variant="contained" className={'BBPButton'} onClick={onSubmitNew}>Save</Button>
                    </Box>
                </Box>
            </Dialog>
        </Fragment>
    );
};
export default UpdateComplaint;
