import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function CommissionsIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24" fontSize="inherit">
            <g transform="translate(1327 -344)">
                <rect width="24" height="24" transform="translate(-1327 344)" fill="none" />
                <path d="M458.627,132.428v1.321a.4.4,0,0,1-.414.414h-2.175a4.839,4.839,0,0,1-1.67,3.03,6.565,6.565,0,0,1-3.573,1.424q2.162,2.3,5.943,6.94a.354.354,0,0,1,.052.44.376.376,0,0,1-.375.233h-2.525a.382.382,0,0,1-.324-.155q-3.962-4.752-6.448-7.393A.388.388,0,0,1,447,138.4v-1.644a.42.42,0,0,1,.414-.414h1.45a5.844,5.844,0,0,0,2.751-.557,2.53,2.53,0,0,0,1.327-1.618h-5.529a.4.4,0,0,1-.414-.414v-1.321a.4.4,0,0,1,.414-.414h5.347q-.738-1.463-3.47-1.463h-1.877a.42.42,0,0,1-.414-.414v-1.722a.4.4,0,0,1,.414-.414h10.772a.4.4,0,0,1,.414.414v1.321a.4.4,0,0,1-.414.414H455.17a4.478,4.478,0,0,1,.829,1.864h2.214a.4.4,0,0,1,.414.414Z" transform="translate(-1767.813 218.885)" fill="#7e84a3" />
            </g>
        </SvgIcon>
    );
}