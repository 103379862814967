import React, { useState, useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import validator from "@rjsf/validator-ajv6";
import Form from "@rjsf/core";
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import './style.css';

const schema = {
    "type": "object",
    "required": ["amt"],
    "properties": {
        "amt": {
            "title": "Amount",
            "type": "number"
        },
    },
};

const uiSchema = {
    "ui:submitButtonOptions": {
        "props": {
            "className": "BBPFBtn"
        }
    },
    "amt": {
        "ui:classNames": "BBPFFWidth"
    }
};

const RefundDialog = ({ open, onClose, title, transactionId, transactionAmt }) => {

    function transformErrors(errors) {
        return errors.map(error => {
            let property;
            let matcheSB = (/\[(.*?)\]/).test(error.property);
            let matcheDot = /\./.test(error.property);
            if (matcheDot) {
                property = error.property.replaceAll('.', '');
            }
            if (matcheSB) {
                // eslint-disable-next-line
                property = error.property.replace(/[\[\]']+/g, '');
            }
            if (error.name === 'minLength') {
                error.message = `‘${schema.properties[property].title}’ should NOT be shorter than 10 characters.`;
            } else if (error.name === 'maxLength') {
                error.message = `‘${schema.properties[property].title}’ should NOT be longer than 10 characters.`;
            } else if (error.name === 'pattern') {
                error.message = `‘${schema.properties[property].title}’ is not valid.`;
            } else {
                error.message = `‘${schema.properties[property].title}’ is mandatory.`;
            }
            return error;
        });
    }

    function customValidate(formData, errors) {
        if (formData.amt > transactionAmt) {
            errors.amt.addError(`Amount should be less than ${transactionAmt} amount.`);
        }
        return errors;
    }

    const axiosPrivate = useAxiosPrivate();

    const [onSubmitLoading, setOnSubmitLoading] = useState(false);
    const [errMessage, setErrMessage] = useState('');
    const [refundSuccess, setRefundSuccess] = useState(false);
    const [formData, setFormData] = useState({});
    const handleRefund = useCallback(async (data, id) => {
        try {
            setOnSubmitLoading(true);
            let url = `transactions/refund/${id}`;
            let options = {
                method: 'POST',
                url,
                data,
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setErrMessage('');
                    setRefundSuccess(true);
                } else {
                    setErrMessage(response.data.message);
                }
                setOnSubmitLoading(false);
            }).catch(err => {
                if (err.response) {
                    setOnSubmitLoading(false);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setOnSubmitLoading(false);
            // console.error('error', error);
        }
        // eslint-disable-next-line
    }, []);

    let yourForm;

    const onSubmitNew = () => {
        yourForm.formElement.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    };

    const onFormSubmit = (formData) => {
        setFormData(formData);
        handleRefund(formData, transactionId);
    };

    useEffect(() => {
        if (open) {
            setFormData({});
            setErrMessage('');
        }
    }, [open])

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={open}
            onClose={onClose}
        >
            <Box component="div" className={'BBPRefundDialog'}>
                <Box component="div" className={'BBPRDHead'}>
                    <Box component="div" className={'BBPRDHTitle'}>
                        {title}
                    </Box>
                    <IconButton onClick={onClose}>
                        <ClearIcon />
                    </IconButton>
                </Box>
                <Box component="div" className={'BBPRDBody'}>
                    <Box component='div' className={'BBPForm'}>
                        <Form
                            disabled={refundSuccess}
                            schema={schema}
                            uiSchema={uiSchema}
                            formData={formData}
                            validator={validator}
                            showErrorList={false}
                            omitExtraData={true}
                            transformErrors={transformErrors}
                            customValidate={customValidate}
                            onSubmit={({ formData }) => {
                                onFormSubmit(formData);
                            }}
                            ref={(form) => { yourForm = form; }}
                        />
                    </Box>
                </Box>
                {errMessage && <Box component="div" className={'BBPRDESMsg'} color={'#ee2505'}>{errMessage}</Box>}
                {refundSuccess && <Box component="div" className={'BBPRDESMsg'} color={'#2e7d32'}>{refundSuccess}</Box>}
                <Box component="div" className={'BBPRDBtn'}>
                    <Button
                        className={'BBPButton BBPOButton'}
                        variant="contained"
                        disabled={onSubmitLoading}
                        onClick={onClose}>Close</Button>
                    {!refundSuccess &&
                        <Button
                            className={'BBPButton'}
                            variant="contained"
                            disabled={onSubmitLoading}
                            onClick={onSubmitNew} >Submit</Button>}
                </Box>
            </Box>
        </Dialog>
    );
};
export default RefundDialog;
