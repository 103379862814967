import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import RightSidePanel from '../../Components/RightSidePanel';
import Overview from './overview';
import LineChart from './lineChart';
import BarChart from './barChart';
import { CheckPermissions } from '../../Utils/permissions';

import './style.css';

const DashboardPage = () => {

    return (
        <Fragment>
            <RightSidePanel removeBg>
                <Box component="div" className={'BBPDashboardPage'}>
                    {CheckPermissions('dashboard_permissions', 'View') ?
                        <Fragment>
                            <Overview />
                            <LineChart />
                            <BarChart />
                        </Fragment>
                        : 'No Access'}
                </Box>
            </RightSidePanel>
        </Fragment>
    );
};

export default DashboardPage;
