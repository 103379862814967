import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function HeartIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 64 64" fontSize="inherit">
            <rect width="64" height="64" fill="none" />
            <path d="M27.261,47.295c-3.45-3.45-7.146-6.653-10.843-10.1-.986-.986-2.464-1.725-3.45-2.711-1.479-1.725-3.2-3.45-4.436-5.175a31.486,31.486,0,0,1-3.45-5.175C3.851,21.42,2.618,18.463,3.111,15.26c.493-2.711.986-5.421,3.45-7.393C8.04,6.635,9.025,4.91,11,4.417c1.232-.493,2.711-.493,3.943-.986a3.8,3.8,0,0,1,3.943.246c3.7,0,6.161,2.711,9.118,4.436a21.246,21.246,0,0,1,4.436-3.45c1.971-.986,4.189-.986,6.407-1.479.986-.246,4.189.986,5.421,1.479,3.943,1.479,5.668,4.682,6.9,8.378A12,12,0,0,1,51.411,16c.493,4.436-1.232,7.886-3.7,11.336-3.2,4.929-7.393,8.625-11.582,12.568-1.725,1.725-3.7,3.45-5.421,4.929C29.725,45.57,28.493,46.309,27.261,47.295Zm0-17L16.172,19.2c1.479-.986,2.218-2.464,3.7-3.2l3.943,3.943a52.275,52.275,0,0,0,3.7,3.7C32.436,18.71,37.364,13.781,42.293,9.1V8.36a8.735,8.735,0,0,0-2.711-.739,21.2,21.2,0,0,0-3.943.246c-1.725.493-3.7,2.218-5.421,3.943a10.5,10.5,0,0,1-2.957,2.711,45.536,45.536,0,0,1-3.45-3.45,9.3,9.3,0,0,0-8.132-3.45,9.8,9.8,0,0,0-5.421,2.218,8.022,8.022,0,0,0-2.464,5.668c0,1.479-.246,2.957.246,4.189a30.3,30.3,0,0,0,5.668,8.871c4.436,4.189,8.625,8.378,13.061,12.321.739-.493,1.725-.986,2.464-1.479,2.711-2.464,5.175-5.175,7.886-7.393,1.479-1.232,2.464-2.957,3.943-4.436,2.218-1.971,3.45-4.436,4.682-7.146s1.725-5.421.246-7.886c-1.479-.739-1.971.246-2.464.986C38.35,19.2,32.929,24.624,27.261,30.292Z" transform="translate(4.754 6.852)" fill="#175783" />
        </SvgIcon>
    );
}