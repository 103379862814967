import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function CreditCardIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 64 64" fontSize="inherit">
            <g transform="translate(8 16)">
                <path d="M48.447,15.008a5,5,0,0,0-4.99-4.99h-37a5,5,0,0,0-4.99,4.99V34.934a5,5,0,0,0,4.99,4.99h37a5,5,0,0,0,4.99-4.99Zm-2,19.926a3,3,0,0,1-2.994,2.994h-37a3,3,0,0,1-2.994-2.994V15.008a3,3,0,0,1,2.994-2.994h37a3,3,0,0,1,2.994,2.994Z" transform="translate(-1.461 -10.018)" fill="#175783" />
                <path d="M17.426,14.37a6.255,6.255,0,0,0-2.655.594,6.3,6.3,0,1,0,0,11.414,6.3,6.3,0,1,0,2.66-12.009Zm-9.6,6.307a4.311,4.311,0,0,1,4.3-4.313,4.37,4.37,0,0,1,.667.056,6.264,6.264,0,0,0,0,8.5,4.374,4.374,0,0,1-.663.055A4.3,4.3,0,0,1,7.822,20.677Zm9.6,4.3a4.307,4.307,0,1,1,4.306-4.306A4.311,4.311,0,0,1,17.426,24.979Zm5.3,6.678H7.311a1,1,0,0,0,0,2H22.73a1,1,0,0,0,0-2ZM41.078,26.75H31.264a1,1,0,0,0,0,2h9.814a1,1,0,0,0,0-2Zm0,4.907H31.264a1,1,0,0,0,0,2h9.814a1,1,0,0,0,0-2Z" transform="translate(-1.47 -10.026)" fill="#175783" />
            </g>
        </SvgIcon>
    );
}