import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SearchBox from '../../Components/SearchBox';
import FileAddIcon from '../../Components/Icons/FileAddIcon';
import FileUploadIcon from '../../Components/Icons/FileUploadIcon';
import DevTable from '../../Components/DevTable';
import AlertDialog from '../../Components/AlertDialog';
import { MENU_SLUG } from '../../Constants/constants';
import Backdrops from '../../Components/Backdrops';
import { CheckPermissions } from '../../Utils/permissions';
import SomethingWentWrong from '../../Components/SomethingWentWrong';
import WebPageTitle from '../../Components/WebPageTitle';

import './style.css';

const BillerTable = () => {

    let navigate = useNavigate();
    let location = useLocation();
    let editPermissions = CheckPermissions('billers_permissions', 'Edit');
    let deletePermissions = CheckPermissions('billers_permissions', 'Delete');
    let addPermissions = CheckPermissions('billers_permissions', 'Add');
    let uploadPermissions = CheckPermissions('billers_permissions', 'Bulk Upload');

    const axiosPrivate = useAxiosPrivate();
    const [errorDialog, setErrorDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [onDeleteLoading, setOnDeleteLoading] = useState(false);
    const [deleteItem, setDeleteItem] = useState({});
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [totalData, setTotalData] = useState(0);
    const [selectedPage, setSelectedPage] = useState(1);
    const [limitData] = useState(20);

    const [rowData, setRowData] = useState([]);

    const [apiParams] = useState({ limit: limitData });

    const [mainColumns, setMainColumns] = useState({
        columns: [
            {
                name: 'category',
                title: 'Biller Category',
                width: 200,
                sorting: true,
                direction: ''
            },
            {
                name: 'name',
                title: 'Biller Name',
                width: 300,
                sorting: true,
                direction: ''
            },
            {
                name: 'api_provider',
                title: 'Api Provider',
                width: 150,
                sorting: false,
                direction: ''
            },
            {
                name: 'auto_fetch',
                title: 'Auto Fetch',
                width: 150,
                sorting: true,
                direction: ''
            },
            {
                name: 'fetch_on',
                title: 'Fetch On',
                width: 150,
                sorting: true,
                direction: ''
            },
            {
                name: 'status',
                title: 'Status',
                width: 150,
                sorting: true,
                direction: ''
            },
            {
                name: 'action',
                title: 'Action',
                width: 150,
                sorting: false,
            },
        ]
    });

    const fetchBillers = useCallback(async (params) => {
        try {
            let urlParams = '';
            if (params) {
                Object.keys(params).forEach(function (key, index) {
                    urlParams += (index === 0 ? '?' : '&') + key + '=' + params[key];
                });
            }
            let url = `billers/providers${urlParams}`;
            let options = {
                method: 'GET',
                url
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setRowData(response.data.data);
                    setTotalData(response.data.total);
                    setLoading(false);
                } else {
                    setRowData([]);
                    setTotalData(0);
                    setLoading(false);
                }
            }).catch(err => {
                if (err.response) {
                    setLoading(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setLoading(false);
            setErrorDialog(true);
            // console.error('error', error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearch = useCallback((value) => {
        setLoading(true);
        if (value.length > 0) {
            let prm = apiParams;
            prm.page = 1;
            prm.search = value;
            setSelectedPage(1);
            fetchBillers(prm);
        } else {
            let prm = apiParams;
            prm.page = 1;
            delete prm.search;
            setSelectedPage(1);
            fetchBillers(prm);
        }
    }, [apiParams, fetchBillers])

    const handlePaginate = (value) => {
        let prm = apiParams;
        prm.page = value;
        setSelectedPage(value);
        setLoading(true);
        fetchBillers(prm);
    };

    const handlePreviewBiller = (item) => {
        navigate(`/${MENU_SLUG.billers}/view/${item._id}`, { state: { background: location } });
    }

    const handleEditBiller = (item) => {
        navigate(`/${MENU_SLUG.billers}/edit/${item._id}`, { replace: true });
    }

    const handleSetDeleteBiller = (item) => {
        setDeleteItem(item);
        setDeleteAlert(true);
    }

    const handleDeleteAlertClose = () => {
        setDeleteAlert(false);
        setDeleteItem({});
    }

    const handleSorting = (name, index) => {
        let prm = apiParams;
        let tempCol = mainColumns.columns;
        if (tempCol[index].direction === '') {
            tempCol.map((column) => (column.name === name ? column.direction = 'ASC' : column.direction = ''));
            prm.sort_by = name;
            prm.order = 1;
            prm.page = 1;
        } else if (tempCol[index].direction === 'ASC') {
            tempCol.map((column) => (column.name === name ? column.direction = 'DSC' : column.direction = ''));
            prm.sort_by = name;
            prm.order = -1;
            prm.page = 1;
        } else if (tempCol[index].direction === 'DSC') {
            tempCol.map((column) => column.direction = '');
            prm.page = 1;
            delete prm.sort_by;
            delete prm.order;
        }
        setLoading(true);
        setMainColumns({ columns: tempCol });
        setSelectedPage(1);
        fetchBillers(prm);
    }

    function generateRows(tempArray) {
        const tempRowArray = [];
        if (tempArray) {
            tempArray.map((item) =>
                tempRowArray.push({
                    category: (<Box component="div" className="BBPDTSText">{item.cat.name}</Box>),
                    name: (<Box component="div" className="BBPDTSImgTitle"><Box component="div" className="BBPDTSITImg"><img src={`${item.image.location}`} alt={item.name} /></Box><Box component="div" className="BBPDTSITTitle">{item.name}</Box></Box>),
                    api_provider: (<Box component="div" className="BBPDTSText">{item.api_provider}</Box>),
                    auto_fetch: (<Box component="div" className="BBPDTSText">{item.auto_fetch ? 'Active' : 'Disable'}</Box>),
                    fetch_on: (<Box component="div" className="BBPDTSText">{item.fetch_on}</Box>),
                    status: (<Box component="div" className="BBPDTSText">{item.status ? 'Active' : 'Disable'}</Box>),
                    action: (<Box component="div" className="BBPDTIBtns">
                        <Tooltip
                            placement="top"
                            classes={{
                                popper: 'BBPTPopper',
                                tooltip: 'BBPTooltip'
                            }}
                            title={'Biller Details'}
                        >
                            <IconButton size="small" className="BBPDTIBIcon" onClick={() => { handlePreviewBiller(item) }}>
                                <RemoveRedEyeOutlinedIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                        {editPermissions ?
                            <Tooltip
                                placement="top"
                                classes={{
                                    popper: 'BBPTPopper',
                                    tooltip: 'BBPTooltip'
                                }}
                                title={'Edit Biller'}
                            >
                                <IconButton size="small" className="BBPDTIBIcon" onClick={() => { handleEditBiller(item) }}>
                                    <EditOutlinedIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                            : ''}
                        {deletePermissions ?
                            <Tooltip
                                placement="top"
                                classes={{
                                    popper: 'BBPTPopper',
                                    tooltip: 'BBPTooltip'
                                }}
                                title={'Delete Biller'}
                            >
                                <IconButton size="small" className="BBPDTIBIcon" onClick={() => { handleSetDeleteBiller(item) }}>
                                    <DeleteOutlinedIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                            : ''}
                    </Box>),
                }),
            );
        }
        return tempRowArray;
    }

    const handleDeleteBiller = useCallback(async () => {
        try {
            setDeleteAlert(false);
            setOnDeleteLoading(true);
            let url = `billers/provider/${deleteItem._id}`;
            let options = {
                method: 'DELETE',
                url
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setDeleteItem({});
                    setLoading(false);
                    setOnDeleteLoading(false);
                    fetchBillers(apiParams);
                } else {
                    setLoading(false);
                }
            }).catch(err => {
                if (err.response) {
                    setLoading(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setLoading(false);
            setErrorDialog(true);
            // console.error('error', error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteItem, fetchBillers]);


    useEffect(() => {
        fetchBillers(apiParams);
        // eslint-disable-next-line 
    }, [])

    return (
        <Fragment>
            <WebPageTitle title="Billers" />
            <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
            <Backdrops
                open={onDeleteLoading}
                title={'Deleting'}
            />
            <AlertDialog
                open={deleteAlert}
                onClose={handleDeleteAlertClose}
                title={'Are you sure ?'}
                message={'Do you really want to delete these record ?'}
                buttons={
                    <Fragment>
                        <Button variant="contained" className={'BBPButton'} onClick={handleDeleteAlertClose}>Cancel</Button>
                        <Button variant="contained" className={'BBPButton BBPBRed'} onClick={handleDeleteBiller}>Delete</Button>
                    </Fragment>
                }
            />
            <Box component='div' className={'BBPBillers'}>
                <Box component='div' className={'BBPBHead'}>
                    <SearchBox
                        onSearchChange={handleSearch}
                        placeholder={'Search Billers'}
                        searchTooltip={'Searching from Biller Name, Category Name'}
                    />
                    <Box component='div' className={'BBPBHBtn'}>
                        {addPermissions ?
                            <Link to={`/${MENU_SLUG.billers}/add`} className={'BBPTableBtn'}>
                                <Box component='span'><FileAddIcon /></Box>
                                Add
                            </Link>
                            : ''}
                        {uploadPermissions ?
                            <Link to={`/${MENU_SLUG.billers}/upload`} className={'BBPTableBtn'}>
                                <Box component='span'><FileUploadIcon /></Box>
                                Upload
                            </Link>
                            : ''}
                    </Box>
                </Box>
                <Box component='div' className={'BBPBBody'}>
                    <DevTable
                        rows={generateRows(rowData)}
                        columns={mainColumns.columns}
                        selectedPage={selectedPage}
                        handlePagination={handlePaginate}
                        loading={loading}
                        handleSort={handleSorting}
                        limitData={limitData}
                        totalData={totalData}
                        noDataTitle={'Not found'}
                        noDataDes={'Biller could Not be found'}
                    />
                </Box>
            </Box>
        </Fragment>
    );
};

export default BillerTable;
