import axios from "axios";
import { intersection } from 'lodash';
import BillerCategories from '../json/billerCategories.json';
import roles from '../Config/roles';
import { Buffer } from 'buffer';
import moment from 'moment';

/**
 * Array With Length
 * @param {arr} Array
 *
 * ******* */
export function isArrayWithLength(arr) {
    return (Array.isArray(arr) && arr.length)
}

/**
 * get Allowed Routes
 * @param {routes} Array
 *
 * ******* */
export function getAllowedRoutes(routes) {
    return routes.filter(({ permission }) => {
        if (!permission) return true;
        else if (!isArrayWithLength(permission)) return true;
        else return intersection(permission, roles).length;
    });
}

/**
 * Create Unique key of length
 * @param {Number} length
 *
 * ******* */
export const makeId = (length = 8) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

/**
 * Unique Array 
 * @param {arr} Array
 * @param {keyProps} Keys
 *
 * ******* */
export const uniqueArray = (arr, keyProps) => {
    return Object.values(arr.reduce((uniqueMap, entry) => {
        const key = keyProps.map(k => entry[k]).join('|');
        if (!(key in uniqueMap)) uniqueMap[key] = entry;
        return uniqueMap;
    }, {}));
}


/**
 * Fetch Ip Address 
 *
 * ******* */
// export const fetchIp = async () => {
//     let url = `https://geolocation-db.com/json/`;
//     let options = { method: 'GET', url };
//     let ipData = await axios(options).then(response => response.data);
//     return ipData.IPv4;
// }

export const fetchIp = async () => {
    let url = `https://api.ipify.org?format=json`;
    let options = { method: 'GET', url };
    let ipData = await axios(options).then(response => response.data);
    return ipData.ip;
}

/**
 * Convert Provider Field Value Array into String val1|val2|val3|.. 
 ** @param {values} Array 
 * ******* */
export const inputValueString = (values) => {
    let tempString = '';

    Object.entries(values).map(([key, value], index) => {
        if (index === 0) {
            return tempString += value;
        } else {
            return tempString += '|' + value;
        }
    })
    return tempString;
}

/**
 * Get Category By ID or Name
 ** @param {value} Id
 * ******* */
export const getBillerCategory = (id) => {
    let filteredArray = BillerCategories.filter(cat => cat.Id === id);
    if (filteredArray.length > 0) {
        return filteredArray[0].Name;
    } else {
        return false;
    }
}

/**
 * Get File By Data Url
 ** @param {dataUrl} base64 url
 ** @param {filename} filename
 * ******* */
export const dataUrlToFile = (dataUrl, filename) => {
    const arr = dataUrl.split(',');
    if (arr.length < 2) { return undefined; }
    const mimeArr = arr[0].match(/:(.*?);/);
    if (!mimeArr || mimeArr.length < 2) { return undefined; }
    const mime = mimeArr[1];
    const buff = Buffer.from(arr[1], 'base64');
    return new File([buff], filename, { type: mime });
}

/**
 * Array Equals
 ** @param {a} array
 ** @param {b} array
 * ******* */
export const arrayEquals = (a, b) => {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}

/**
 * Array Equals
 ** @param {str} string 
 * ******* */
export const toCapitalize = (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
}

/**
 * Date Formatter
 * @param {string} date - The date string to be formatted.
 * @param {string} [strFormat] - Optional format string to specify the desired output format.
 * @returns {string} The formatted date string or an empty string if the date is invalid.
 */
export const dateFormatter = (date, strFormat) => {
    if (!date) {
        return '';
    }

    if (date.includes('-') && !date.includes(':')) {
        if (moment(date, 'DD-MM-YYYY', true).isValid()) {
            return moment(date, 'DD-MM-YYYY').format(strFormat ? strFormat : "DD-MM-YYYY");
        } else if (moment(date, 'YYYY-MM-DD', true).isValid()) {
            return moment(date, 'YYYY-MM-DD').format(strFormat ? strFormat : "DD-MM-YYYY");
        } else {
            return moment(date, 'DD-MMM-YYYY').format(strFormat ? strFormat : "DD-MM-YYYY");
        }
    } else if (date.includes('-') && date.includes(':') && date.includes(' ')) {
        return date;
    } else if (date.includes('/') && date.includes(':') && date.includes(' ')) {
        if (moment(date, 'DD/MM/YYYY hh:mm A', true).isValid()) {
            return moment(date, 'DD/MM/YYYY hh:mm A').format(strFormat ? strFormat : "DD-MM-YYYY");
        }
    } else {
        return moment(date).format(strFormat ? strFormat : "DD-MM-YYYY");
    }
}

