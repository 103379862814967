import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function BillersIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24" fontSize="inherit">
            <g transform="translate(1247 -339)">
                <rect width="24" height="24" transform="translate(-1247 339)" fill="none" />
                <path d="M10.13,14.3H11.5v4.462c0,.418,0,.774.027,1.029a1.387,1.387,0,0,0,.1.414.648.648,0,0,0,1.047.231,1.389,1.389,0,0,0,.264-.334c.132-.22.281-.543.457-.922l.014-.029,2.731-5.9.017-.037a12.465,12.465,0,0,0,.638-1.528,1.51,1.51,0,0,0-.095-1.294,1.51,1.51,0,0,0-1.134-.629A12.475,12.475,0,0,0,13.911,9.7H12.5V5.238c0-.418,0-.774-.027-1.029a1.387,1.387,0,0,0-.1-.414.648.648,0,0,0-1.047-.231,1.39,1.39,0,0,0-.264.334c-.132.22-.281.543-.457.922l-.014.029-2.731,5.9-.017.037a12.477,12.477,0,0,0-.638,1.528A1.51,1.51,0,0,0,7.3,13.608a1.511,1.511,0,0,0,1.134.629,12.487,12.487,0,0,0,1.654.063Z" transform="translate(-1247 339)" fill="none" stroke="#7e84a3" strokeWidth="2" />
            </g>
        </SvgIcon>
    );
}