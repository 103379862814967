import React, { useState } from 'react';
import { Route, Routes, Outlet, useLocation } from 'react-router-dom';
import RightSidePanel from '../../Components/RightSidePanel';
import TransactionTable from './transactionTable';
import CommissionTable from './commissionTable';
import ICICITable from './iciciTable';
// import UpiReports from './UpiReports';
import { CheckPermissions } from '../../Utils/permissions';
import './style.css';

const Reports = () => {

    let location = useLocation();

    const background = location.state ? location.state.background : location;

    const [pages] = useState([
        {
            path: '/',
            component: TransactionTable,
            enable_route: CheckPermissions('reports_permissions', 'Transaction')
        },
        {
            path: '/commission',
            component: CommissionTable,
            enable_route: CheckPermissions('reports_permissions', 'Commission')
        },
        // {
        //     path: '/upi/*',
        //     component: UpiReports,
        //     enable_route: CheckPermissions('reports_permissions', 'UPI')
        // },
        {
            path: '/icici',
            component: ICICITable,
            enable_route: CheckPermissions('reports_permissions', 'ICICI')
        },
    ]);

    return (
        <RightSidePanel removeBg>
            <Routes location={background}>
                <Route path={'/'} element={<Outlet />}>
                    {// eslint-disable-next-line array-callback-return
                        pages.map((route) => {
                            if (route.enable_route) {
                                const { path, component: Component, children, title, permission, ...rest } = route;
                                return (
                                    <Route {...rest} key={path} path={`${path}`} element={<Component />} />
                                )
                            }
                        })}
                </Route>
            </Routes>
        </RightSidePanel>
    );
};

export default Reports;
