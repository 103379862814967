import React, { useState } from 'react';
import { Route, Routes, Outlet, useLocation } from 'react-router-dom';
import RightSidePanel from '../../Components/RightSidePanel';
import AddBiller from './addBiller';
import BillerTable from './billerTable';
import ViewBillerModel from './viewBillerModel';
import UploadCSV from './uploadCSV';
import { CheckPermissions } from '../../Utils/permissions';
import './style.css';

const Billers = () => {

    let location = useLocation();
    const background = location.state ? location.state.background : location;

    const [pages] = useState([
        {
            path: '/',
            component: BillerTable,
            enable_route: CheckPermissions('billers_permissions', 'View')
        },
        {
            path: '/add',
            component: AddBiller,
            enable_route: CheckPermissions('billers_permissions', 'Add')
        },
        {
            path: '/edit/:provider_id',
            component: AddBiller,
            enable_route: CheckPermissions('billers_permissions', 'Edit')
        },
    ]);

    return (
        <RightSidePanel removeBg>
            <Routes location={background}>
                <Route path={'/'} element={<Outlet />}>
                    {
                        // eslint-disable-next-line array-callback-return
                        pages.map((route) => {
                            if (route.enable_route) {
                                const { path, component: Component, children, title, permission, ...rest } = route;
                                return (
                                    <Route {...rest} key={path} path={`${path}`} element={<Component />} />
                                )
                            }
                        })}
                </Route>
            </Routes>
            {background && (
                <Routes>
                    <Route path="/view/:provider_id" element={<ViewBillerModel />} />
                    <Route path="/upload" element={<UploadCSV />} />
                </Routes>
            )}
        </RightSidePanel>
    );
};

export default Billers;
