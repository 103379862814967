import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from '@mui/material/Skeleton';
import MenuItem from '@mui/material/MenuItem';
import useDebounce from '../../Utils/useDebounce';
import NoData from '../../Components/NoData';
import SomethingWentWrong from '../../Components/SomethingWentWrong';
import './style.css';

const ApiAutocomplete = (props) => {
    const axiosPrivate = useAxiosPrivate();
    const { label, options, placeholder, disabled, onChange, id, value, rawErrors } = props;
    const { valueKey, itemLabel, api, cat_id, defaultValue } = options;

    const [errorDialog, setErrorDialog] = useState(false);
    const anchorRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const [width, setWidth] = React.useState('0');
    const [apiUrl, setApiUrl] = React.useState('');

    const [selectedValue, setSelectedValue] = useState(null);

    const [loading, setLoading] = useState(true);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [allProviders, setAllProviders] = useState([]);
    const [limitData] = useState(15);
    const [selectedPage, setSelectedPage] = useState(1);
    const [totalData, setSetTotalData] = useState(0);
    const [apiParams] = useState({ limit: limitData });

    const fetchBillerProviders = useCallback(async (params, isSearch, api) => {
        try {
            let urlParams = '';
            if (params) {
                Object.keys(params).forEach(function (key, index) {
                    urlParams += (index === 0 ? '?' : '&') + key + '=' + params[key];
                });
            }
            let url = `${api}/${urlParams}`;
            let options = {
                method: 'GET',
                url
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    if (isSearch) {
                        setAllProviders(response.data.data);
                    } else {
                        setAllProviders(allProviders.concat(Array.from(response.data.data)));
                    }
                    setSelectedPage(parseInt(params.page + 1));
                    setSetTotalData(response.data.total);
                    setLoading(false);
                } else {
                    setAllProviders([]);
                    setLoading(false);
                }
            }).catch(err => {
                if (err.response) {
                    setLoading(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setLoading(false);
            setErrorDialog(true);
            // console.error('error', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allProviders]);

    const handleFetch = useCallback(() => {
        let prm = apiParams;
        prm.page = selectedPage;
        if (allProviders.length !== totalData) {
            fetchBillerProviders(prm, false, apiUrl);
        } else {
            setHasMoreData(false);
        }
    }, [allProviders.length, apiParams, apiUrl, fetchBillerProviders, selectedPage, totalData]);

    const handleSearch = useCallback((value) => {
        setLoading(true);
        setHasMoreData(true);
        let prm = apiParams;
        prm.page = 1;
        if (value.length > 0) {
            prm.search = value;
        } else {
            delete prm.search;
        }
        fetchBillerProviders(prm, true, apiUrl);
    }, [apiParams, apiUrl, fetchBillerProviders]);

    const doSearch = useCallback(str => {
        handleSearch(str);
    }, [handleSearch]);

    const doSave = useDebounce(doSearch, 500);

    const handleSearchInput = useCallback(value => {
        doSave(value);
    }, [doSave]);

    const handleToggle = (open) => {
        setSelectedPage(1);
        if (open) {
            setLoading(true);
            fetchBillerProviders({ limit: limitData, page: 1 }, true, apiUrl);
        }
        setOpen((previousOpen) => !previousOpen);
        setWidth(anchorRef.current.clientWidth);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleAutocomplete = (newValue) => {
        setSelectedValue(newValue ? newValue[itemLabel] : value);
        onChange(newValue ? newValue[valueKey] : value);
    };

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        setApiUrl(`${api}/${cat_id}`);
        if (defaultValue) {
            setSelectedValue(defaultValue);
        } else {
            setSelectedValue(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cat_id]);

    return (
        <Fragment>
            <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
            <Box component="div" className={'BBPApiAutocomplete'}>
                <Box component='div' className={'BBPAACInner'}>
                    <Button
                        ref={anchorRef}
                        aria-controls={open ? 'composition-select' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={() => { handleToggle(!open) }}
                        fullWidth
                        disabled={disabled}
                        className={`BBPAACILabel ${open ? 'open' : ''} ${rawErrors && rawErrors.length > 0 ? 'BBPAACIError' : ''}`}
                    >
                        {selectedValue ? selectedValue : `Select ${label}`}
                    </Button>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        placement="bottom-start"
                        transition
                        style={{ width: width }}
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom-start' ? 'left top' : 'left bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <Box component="div" className={'BBPAAPSearch'}>
                                            <Box component="div" className={'BBPAAInput'}>
                                                <input id={id} placeholder={placeholder || `Search ${label}`} onChange={(e) => { handleSearchInput(e.target.value) }} autoComplete="off" />
                                            </Box>
                                            {allProviders.length > 0 ?
                                                <InfiniteScroll
                                                    height={260}
                                                    dataLength={allProviders.length}
                                                    next={handleFetch}
                                                    hasMore={hasMoreData}
                                                    pullDownToRefreshThreshold={100}
                                                    loader={
                                                        <Fragment>
                                                            {Array.from(Array(15).keys()).map((item) => (
                                                                <Box component="div" key={item} className={'BBPAAILoader'}>
                                                                    <Skeleton variant="rounded" height={19} />
                                                                </Box>
                                                            ))}
                                                        </Fragment>
                                                    }
                                                >
                                                    {allProviders.map((item, index) => (
                                                        <MenuItem
                                                            component={'div'}
                                                            key={index}
                                                            onClick={(event) => {
                                                                handleAutocomplete(item)
                                                                handleClose(event);
                                                            }}
                                                        >
                                                            {item[itemLabel]}</MenuItem>
                                                    ))}
                                                </InfiniteScroll>
                                                :
                                                loading ?
                                                    <Fragment>
                                                        {Array.from(Array(7).keys()).map((item) => (
                                                            <Box component="div" key={item} className={'BBPAAILoader'}>
                                                                <Skeleton variant="rounded" height={19} />
                                                            </Box>
                                                        ))}
                                                    </Fragment>
                                                    :
                                                    <Box component="div" className={'BBPAAINot'}>
                                                        <NoData type="small" title="Biller Not Found" />
                                                    </Box>
                                            }
                                        </Box>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Box>
            </Box>
        </Fragment>
    );
};
export default ApiAutocomplete;

