import React, { Fragment, useState, useCallback, useContext, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import { customizeValidator } from "@rjsf/validator-ajv6";
import Form from "@rjsf/core";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';

import SuccessDialog from '../../Components/SuccessDialog';
import { BillerContext } from '../../Contexts/billerCategories';
import { reformatedOption } from '../../Utils/autocompleteGroup';
import { MENU_SLUG } from '../../Constants/constants';
import Backdrops from '../../Components/Backdrops';
import NoData from '../../Components/NoData';
import Autocomplete from '../../Components/FormFields/AutocompleteNew';
import FileUpload from '../../Components/FormFields/FileUploadNew';
import SomethingWentWrong from '../../Components/SomethingWentWrong';
import STWWImg from '../../Assets/Images/stww.png';
import { dataUrlToFile, toCapitalize } from '../../Utils/index';

import './style.css';

const schema = {
    "type": "object",
    "required": ["cat_id", "name", "biller_id", "api_provider", "status", "auto_fetch", "fetch_on"],
    "properties": {
        "cat_id": {
            "title": "Biller Category",
            "type": "string"
        },
        "name": {
            "title": "Biller Name",
            "type": "string"
        },
        "biller_id": {
            "title": "Biller ID",
            "type": "string",
            "format": "alphanumeric"
        },
        "api_provider": {
            "title": "API Provider",
            "enum": [
                "Finacus",
                "Mobikwik"
            ],
            "default": "Finacus"
        },
        "status": {
            "title": "Biller Status",
            "enum": [
                "Yes",
                "No"
            ],
            "default": "Yes"
        },
        "auto_fetch": {
            "title": "Enable for Auto Fetch",
            "enum": [
                "Yes",
                "No"
            ],
            "default": "Yes"
        },
        "fetch_on": {
            "title": "Fetch On",
            "enum": [
                "Due Date",
                "Bill Date"
            ],
            "default": "Due Date"
        },
        "file": {
            "title": "Biller Logo",
            "type": "string",
        },
    }
};

const AddBiller = () => {
    const axiosPrivate = useAxiosPrivate();
    const customFormats = {
        'alphanumeric': /^[0-9a-zA-Z]+$/
    };

    function transformErrors(errors) {
        return errors.map(error => {
            let property;
            let matcheSB = (/\[(.*?)\]/).test(error.property);
            let matcheDot = /\./.test(error.property);
            if (matcheDot) {
                property = error.property.replaceAll('.', '');
            }
            if (matcheSB) {
                // eslint-disable-next-line
                property = error.property.replace(/[\[\]']+/g, '');
            }
            if (error.name === 'minLength') {
                error.message = `‘${schema.properties[property].title}’ should NOT be shorter than 10 characters.`;
            } else if (error.name === 'maxLength') {
                error.message = `‘${schema.properties[property].title}’ should NOT be longer than 10 characters.`;
            } else if (error.name === 'pattern') {
                error.message = `‘${schema.properties[property].title}’ is not valid.`;
            } else {
                error.message = `‘${schema.properties[property].title}’ is mandatory.`;
            }
            return error;
        });
    }

    const validator = customizeValidator({ customFormats });

    let navigate = useNavigate();
    const location = useLocation();

    const pathWithoutLastPart = location.pathname.slice(0, location.pathname.lastIndexOf("/"));

    const categories = useContext(BillerContext);

    let widgets = {
        autocomplete: Autocomplete,
        fileUpload: FileUpload,
    }
    const [imageLocation, setImageLocation] = useState('');

    const uiSchema = {
        "ui:submitButtonOptions": {
            "props": {
                "className": "BBPFBtn"
            }
        },
        "cat_id": {
            'ui:widget': 'autocomplete',
            'ui:options': {
                groupByKey: 'type',
                labelKey: 'name',
                name: 'cat_id',
                mainOptions: reformatedOption(categories.allCategories, ['name|type']),
                valueKey: '_id',
                placeholder: 'Select a category',
            }
        },
        "file": {
            "ui:widget": "fileUpload",
            "ui:options": {
                accept: ".png, .jpeg, .jpg",
                imageLocation: imageLocation
            },
        }
    };

    let { provider_id } = useParams();

    const [formData, setFormData] = useState({});
    const [errorDialog, setErrorDialog] = useState(false);
    const [liveValidErr, setLiveValidErr] = useState(false);

    let yourForm;

    const onFormChange = (formData) => {
        setFormData(formData);
    };

    const onFormSubmit = (formData) => {
        setFormData(formData);
        setLiveValidErr(false);
        let tempData = { ...formData };
        if (tempData.file) {
            tempData.file = dataUrlToFile(tempData.file, 'bbp');
        }
        if (pathWithoutLastPart === `/${MENU_SLUG.billers}/edit`) {
            handleUpdateBiller(tempData);
        } else {
            handleCreateBiller(tempData);
        }
    };

    const onError = () => {
        setLiveValidErr(true);
    };

    const onSubmitNew = () => {
        yourForm.formElement.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    };

    const [loading, setSetLoading] = useState(false);
    const [onSubmitLoading, setOnSubmitLoading] = useState(false);
    const [submitDialog, setSubmitDialog] = useState(false);

    const [duplicateBiller, setDuplicateBiller] = useState({ error: false, msg: '' });

    const handleSubmitDialogClose = () => {
        setSubmitDialog(false);
        navigate(`/${MENU_SLUG.billers}`, { replace: true });
    };

    const handleCancel = () => {
        setSubmitDialog(false);
        navigate(`/${MENU_SLUG.billers}`, { replace: true });
    };

    const handleCreateBiller = useCallback(async (data) => {
        try {
            setOnSubmitLoading(true);
            let url = `billers/provider/`;
            let options = {
                method: 'POST',
                url,
                data,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setSubmitDialog(true);
                    setOnSubmitLoading(false);
                    setDuplicateBiller({ error: false, msg: "" });
                } else {
                    setOnSubmitLoading(false);
                    setDuplicateBiller({ error: true, msg: response.data.message });
                }
            }).catch(err => {
                if (err.response) {
                    setOnSubmitLoading(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setOnSubmitLoading(false);
            setErrorDialog(true);
            // console.error('err.res', error);
        }
        // eslint-disable-next-line  
    }, []);

    const handleUpdateBiller = useCallback(async (data) => {
        try {
            setOnSubmitLoading(true);
            let url = `billers/provider/${provider_id}`;
            let options = {
                method: 'PUT',
                url,
                data,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setSubmitDialog(true);
                    setOnSubmitLoading(false);
                    setDuplicateBiller({ error: false, msg: "" });
                } else {
                    setOnSubmitLoading(false);
                    setDuplicateBiller({ error: true, msg: response.data.message });
                }
            }).catch(err => {
                if (err.response) {
                    setErrorDialog(true);
                    setOnSubmitLoading(false);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setErrorDialog(true);
            setOnSubmitLoading(false);
            // console.error('error', error);
        }
        // eslint-disable-next-line  
    }, [provider_id]);

    const fetchBillerValue = useCallback(async () => {
        setSetLoading(true);
        try {
            let url = `billers/provider/${provider_id}`;
            let options = {
                method: 'GET',
                url
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    let tempData = {};
                    tempData.api_provider = response.data.data.api_provider;
                    tempData.auto_fetch = response.data.data.auto_fetch ? "Yes" : "No";
                    tempData.biller_id = response.data.data.biller_id;
                    tempData.cat_id = response.data.data.cat_id;
                    tempData.fetch_on = response.data.data.fetch_on;
                    tempData.file = response.data.data.image;
                    tempData.name = toCapitalize(response.data.data.name);
                    tempData.status = response.data.data.status ? "Yes" : "No";
                    setImageLocation(response.data.data.image.location);
                    setFormData(tempData);
                    setSetLoading(false);
                } else {
                    setSetLoading(false);
                }
            }).catch(err => {
                if (err.response) {
                    setErrorDialog(true);
                    setSetLoading(false);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setErrorDialog(true);
            setSetLoading(false);
            // console.error('error', error);
        }
        // eslint-disable-next-line  
    }, [provider_id]);

    useEffect(() => {
        if (pathWithoutLastPart === `/${MENU_SLUG.billers}/edit`) {
            fetchBillerValue();
        }
        // eslint-disable-next-line 
    }, []);
    return (
        <Fragment>
            <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
            <Backdrops
                open={onSubmitLoading}
                title={pathWithoutLastPart === `/${MENU_SLUG.billers}/edit` ? 'Updating' : 'Saving'}
            />
            <SuccessDialog
                open={submitDialog}
                onClose={handleSubmitDialogClose}
                title={pathWithoutLastPart === `/${MENU_SLUG.billers}/edit` ? 'Biller updated successfully' : 'Biller added successfully'}
                message={pathWithoutLastPart === `/${MENU_SLUG.billers}/edit` ? 'Biller has been updated successfully. Now you can initiate bill payments for the added biller.' : 'Biller has been added successfully. Now you can initiate bill payments for the added biller.'}
                buttonTitle={'Close'}
            />
            <Box component='div' className={'BBPBillerAdd'}>
                <Box component='div' className={'BBPBAHead'}>
                    <Box component='div' className={'BBPBAHTitle'}>
                        {pathWithoutLastPart === `/${MENU_SLUG.billers}/edit` ? 'Edit Biller' : 'Add Biller'}
                    </Box>
                </Box>
                <Box component='div' className={'BBPBABody'}>
                    <Box component='div' className={'BBPBABPannel'}>
                        <Box component='div' className={'BBPBABPInner'}>
                            {categories.dataLoading || loading ?
                                <Box component='div' className={'BBPBABPIFields'}>
                                    {Array.from(Array(4).keys()).map((item) => (
                                        <Box component='div' key={item} className={'BBPBABPIFField'}>
                                            <Skeleton variant="rectangular" className={'BBPLabel'} height={22} />
                                            <Skeleton variant="rectangular" height={55} />
                                        </Box>
                                    ))}
                                </Box>
                                :
                                <Fragment>
                                    {formData ?
                                        <Box component='div' className={'BBPBABPIFields'}>
                                            <Box component='div' className={'BBPForm'}>
                                                <Form
                                                    enctype='multipart/form-data'
                                                    schema={schema}
                                                    uiSchema={uiSchema}
                                                    widgets={widgets}
                                                    formData={formData}
                                                    liveValidate={liveValidErr}
                                                    validator={validator}
                                                    showErrorList={false}
                                                    omitExtraData={true}
                                                    transformErrors={transformErrors}
                                                    onError={onError}
                                                    onChange={({ formData }) => {
                                                        onFormChange(formData);
                                                    }}
                                                    onSubmit={({ formData }) => {
                                                        onFormSubmit(formData);
                                                    }}
                                                    ref={(form) => { yourForm = form; }}
                                                />
                                            </Box>
                                        </Box>
                                        :
                                        <Fragment>
                                            <NoData
                                                image={STWWImg}
                                                title={'Something went wrong'}
                                                description={'Reference site about Lorem Ipsum, giving information on its origins, as well as a random Ipsum generator.'}
                                            />
                                        </Fragment>
                                    }
                                </Fragment>
                            }
                        </Box>
                        {duplicateBiller.error &&
                            <Box component='div' className={'BBPBABPErr'}>
                                {duplicateBiller.msg}
                            </Box>}

                        <Box component='div' className={'BBPBABPBtns'}>
                            <Button className={'BBPButton'} onClick={handleCancel}>Cancel</Button>
                            {formData &&
                                <Fragment>
                                    {pathWithoutLastPart === `/${MENU_SLUG.billers}/edit` ?
                                        <Button disabled={categories.dataLoading} className={'BBPButton'} onClick={onSubmitNew}>Update</Button>
                                        :
                                        <Button disabled={categories.dataLoading} className={'BBPButton'} onClick={onSubmitNew}>Submit</Button>
                                    }
                                </Fragment>}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Fragment>
    );
};

export default AddBiller; 