import React, { Fragment, useState, useCallback, useContext, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import validator from "@rjsf/validator-ajv6";
import Form from "@rjsf/core";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Skeleton from '@mui/material/Skeleton';
import SuccessDialog from '../../Components/SuccessDialog';
import { BillerContext } from '../../Contexts/billerCategories';
import { MENU_SLUG } from '../../Constants/constants';
import { reformatedOption } from '../../Utils/autocompleteGroup';
import Backdrops from '../../Components/Backdrops';
import Autocomplete from '../../Components/FormFields/AutocompleteNew';
import ApiAutocompleteNew from '../../Components/FormFields/ApiAutocompleteNew';
import SomethingWentWrong from '../../Components/SomethingWentWrong';

import './style.css';

const schema = {
    "type": "object",
    "required": ["cat_id"],
    "properties": {
        "commission_for": {
            "title": "Commission For ",
            "type": "string",
            "oneOf": [
                {
                    "const": "Category",
                    "title": "Category"
                },
                {
                    "const": "Provider",
                    "title": "Biller"
                }
            ],
            "default": "Category"
        },
        "cat_id": {
            "title": "Biller Category",
            "type": "string"
        },
        "range": {
            "title": "Range ",
            "enum": [
                "No",
                "Yes"
            ],
            "default": "No"
        }
    },
    "definitions": {
        "range_item": {
            "type": "object",
            "title": "",
            "required": ["from", "to", "comm_val"],
            "properties": {
                "from": {
                    "title": "From",
                    "type": "number"
                },
                "to": {
                    "title": "To",
                    "type": "number"
                },
                "comm_type": {
                    "title": "Commission Type",
                    "enum": [
                        "Flat",
                        "Percentage"
                    ],
                    "default": "Flat"
                }
            },
            "dependencies": {
                "comm_type": {
                    "oneOf": [
                        {
                            "properties": {
                                "comm_type": {
                                    "enum": [
                                        "Flat"
                                    ]
                                },
                                "comm_val": {
                                    "title": "Commission Value",
                                    "type": "number"
                                }
                            }
                        },
                        {
                            "properties": {
                                "comm_type": {
                                    "enum": [
                                        "Percentage"
                                    ]
                                },
                                "comm_val": {
                                    "title": "Commission Value",
                                    "type": "number"
                                }
                            }
                        }
                    ]
                }
            }
        }
    },
    "dependencies": {
        "commission_for": {
            "oneOf": [
                {
                    "properties": {
                        "commission_for": {
                            "enum": [
                                "Category"
                            ]
                        }
                    }
                },
                {
                    "properties": {
                        "commission_for": {
                            "enum": [
                                "Provider"
                            ]
                        },
                        "prov_id": {
                            "title": "Billers",
                            "type": "string"
                        }
                    },
                    "required": ["prov_id"]
                }
            ]
        },
        "range": {
            "oneOf": [
                {
                    "properties": {
                        "range": {
                            "enum": [
                                "No"
                            ]
                        },
                        "comm_type": {
                            "title": "Commission Type",
                            "enum": [
                                "Flat",
                                "Percentage"
                            ],
                            "default": "Flat"
                        }
                    }
                },
                {
                    "properties": {
                        "range": {
                            "enum": [
                                "Yes"
                            ]
                        },
                        "range_val": {
                            "title": "Add Ranges",
                            "type": "array",
                            "minItems": 1,
                            "items": {
                                "$ref": "#/definitions/range_item"
                            }
                        }
                    }
                }
            ]
        },
        "comm_type": {
            "oneOf": [
                {
                    "properties": {
                        "comm_type": {
                            "enum": [
                                "Flat"
                            ]
                        },
                        "comm_val": {
                            "title": "Commission Value",
                            "type": "number"
                        }
                    },
                    "required": ["comm_val"]
                },
                {
                    "properties": {
                        "comm_type": {
                            "enum": [
                                "Percentage"
                            ]
                        },
                        "comm_val": {
                            "type": "number",
                            "title": "Commission Value"
                        }
                    },
                    "required": ["comm_val"]
                }
            ]
        }
    }
};

function AddButton(props) {
    const { icon, iconType, ...btnProps } = props;
    return (
        <Box className='BBPFAB'>
            <Button variant='outlined'  {...btnProps} startIcon={<AddCircleOutlineIcon fontSize="inherit" />} >
                Add New
            </Button>
        </Box>
    );
};

function RemoveButton(props) {
    const { icon, iconType, ...btnProps } = props;
    return (
        <button className='BBPFRB' {...btnProps}>
            <DeleteOutlineIcon fontSize="inherit" />
        </button>
    );
};

const AddCommission = () => {
    const axiosPrivate = useAxiosPrivate();
    const categories = useContext(BillerContext);

    let widgets = {
        autocomplete: Autocomplete,
        apiAutocomplete: ApiAutocompleteNew,
    }
    const [selectedCat, setSelectedCat] = useState('');
    const [selectedProvider, setSelectedProvider] = useState('');

    const uiSchema = {
        "ui:order": [
            "commission_for",
            "cat_id",
            "prov_id",
            "*"
        ],
        "ui:submitButtonOptions": {
            "props": {
                "className": "BBPFBtn"
            }
        },
        "cat_id": {
            'ui:widget': 'autocomplete',
            'ui:options': {
                groupByKey: 'type',
                labelKey: 'name',
                name: 'cat_id',
                mainOptions: reformatedOption(categories.allCategories, ['name|type']),
                valueKey: '_id',
                placeholder: 'Select a category',
            }
        },
        "prov_id": {
            'ui:widget': 'apiAutocomplete',
            'ui:disabled': selectedCat ? false : true,
            'ui:options': {
                itemLabel: 'name',
                valueKey: '_id',
                api: `${process.env.REACT_APP_API_BASE_URL}/billers/providers`,
                cat_id: selectedCat,
                defaultValue: selectedProvider
            },
        },
        "range_val": {
            "ui:options": {
                "orderable": false
            }
        },
    };

    let { commission_id } = useParams();

    let navigate = useNavigate();
    const location = useLocation();

    function transformErrors(errors) {
        return errors.map(error => {
            let property;
            let matcheSB = (/\[(.*?)\]/).test(error.property);
            let matcheDot = /\./.test(error.property);
            if (matcheDot) {
                property = error.property.replaceAll('.', '');
            }
            if (matcheSB) {
                // eslint-disable-next-line
                property = error.property.replace(/[\[\]']+/g, '');
            }
            if (error.name === 'minLength') {
                error.message = `‘${schema.properties[property].title}’ should NOT be shorter than 10 characters.`;
            } else if (error.name === 'maxLength') {
                error.message = `‘${schema.properties[property].title}’ should NOT be longer than 10 characters.`;
            } else if (error.name === 'pattern') {
                error.message = `‘${schema.properties[property].title}’ is not valid.`;
            } else {
                if (schema.properties[property]) {
                    error.message = `‘${schema.properties[property].title}’ is mandatory.`;
                } else {
                    if (property === 'comm_val' || error.params.missingProperty === 'comm_val') {
                        error.message = `‘Commission Value’ is mandatory.`;
                    }
                    if (error.params.missingProperty === 'to') {
                        error.message = `To’ is mandatory.`;
                    }
                    if (error.params.missingProperty === 'from') {
                        error.message = `From’ is mandatory.`;
                    }
                }
            }
            return error;
        });
    }

    const pathWithoutLastPart = location.pathname.slice(0, location.pathname.lastIndexOf("/"));

    const [formData, setFormData] = useState({});

    const [errorDialog, setErrorDialog] = useState(false);
    const [submitDialog, setSubmitDialog] = useState(false);
    const [onSubmitLoading, setOnSubmitLoading] = useState(false);
    const [liveValidErr, setLiveValidErr] = useState(false);

    const [loading, setSetLoading] = useState(false);
    const [duplicateBiller, setDuplicateBiller] = useState({ error: false, msg: '' });

    const handleCancel = () => {
        navigate(`/${MENU_SLUG.commissions}`, { replace: true });
    };

    let yourForm;

    const onFormChange = (formData) => {
        if (formData.cat_id) {
            setSelectedCat(formData.cat_id);
        } else {
            formData.prov_id = null;
            setSelectedCat('');
            setSelectedProvider('');
        }
        setFormData(formData);
    };

    const onFormSubmit = (formData) => {
        setFormData(formData);
        setLiveValidErr(false);
        if (pathWithoutLastPart === `/${MENU_SLUG.commissions}/edit`) {
            handleUpdateBiller(formData)
        } else {
            handleCreateBiller(formData);
        }
    };

    const onError = () => {
        setLiveValidErr(true);
    };

    const onSubmitNew = () => {
        yourForm.formElement.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    };

    const handleSubmitDialogClose = () => {
        setSubmitDialog(false);
        navigate(`/${MENU_SLUG.commissions}`, { replace: true });
    };

    const handleCreateBiller = useCallback(async (data) => {
        try {
            setOnSubmitLoading(true);
            let url = `commissions/`;
            let options = {
                method: 'POST',
                url,
                data,
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setSubmitDialog(true);
                    setOnSubmitLoading(false);
                    setDuplicateBiller({ error: false, msg: '' });
                } else {
                    setOnSubmitLoading(false);
                    setDuplicateBiller({ error: true, msg: response.data.message });
                }
            }).catch(err => {
                if (err.response) {
                    setOnSubmitLoading(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setOnSubmitLoading(false);
            setErrorDialog(true);
            // console.error('error', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleUpdateBiller = useCallback(async (data) => {
        try {
            setOnSubmitLoading(true);
            let url = `commissions/${commission_id}`;
            let options = {
                method: 'PUT',
                url,
                data,
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setSubmitDialog(true);
                    setOnSubmitLoading(false);
                    setDuplicateBiller({ error: false, msg: '' });
                } else {
                    setOnSubmitLoading(false);
                    setDuplicateBiller({ error: true, msg: response.data.message });
                }
            }).catch(err => {
                if (err.response) {
                    setOnSubmitLoading(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setOnSubmitLoading(false);
            setErrorDialog(true);
            // console.error('error', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commission_id]);

    const fetchCommissionValue = useCallback(async () => {
        setSetLoading(true);
        try {
            let url = `commissions/${commission_id}`;
            let options = {
                method: 'GET',
                url
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    let tempData = {};
                    tempData.commission_for = response.data.data.commission_for;
                    tempData.cat_id = response.data.data.cat._id;
                    tempData.range = response.data.data.range ? "Yes" : "No";
                    if (response.data.data.prov) {
                        tempData.prov_id = response.data.data.prov._id;
                        setSelectedProvider(response.data.data.prov.name);
                    }
                    if (response.data.data.comm_type) {
                        tempData.comm_type = response.data.data.comm_type;
                        tempData.comm_val = response.data.data.comm_val;
                    }
                    if (response.data.data.range_val) {
                        tempData.range_val = response.data.data.range_val;
                    }
                    setFormData(tempData);
                    setSetLoading(false);
                } else {
                    setSetLoading(false);
                }
            }).catch(err => {
                if (err.response) {
                    setOnSubmitLoading(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data)
                }
            });
        } catch (error) {
            setOnSubmitLoading(false);
            setErrorDialog(true);
            // console.error('error', error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commission_id]);

    useEffect(() => {
        if (pathWithoutLastPart === `/${MENU_SLUG.commissions}/edit`) {
            fetchCommissionValue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
            <Backdrops
                open={onSubmitLoading}
                title={pathWithoutLastPart === `/${MENU_SLUG.commissions}/edit` ? 'Updating' : 'Saving'}
            />
            <SuccessDialog
                open={submitDialog}
                onClose={handleSubmitDialogClose}
                title={pathWithoutLastPart === `/${MENU_SLUG.commissions}/edit` ? 'Commissions updated successfully' : 'Commissions added successfully'}
                message={pathWithoutLastPart === `/${MENU_SLUG.commissions}/edit` ? 'Commissions has been updated successfully. Now you can initiate Commissions for the added biller.' : 'Commissions has been added successfully. Now you can initiate Commissions for the added biller.'}
                buttonTitle={'Close'}
            />
            <Box component='div' className={'BBPCommissionAdd'}>
                <Box component='div' className={'BBPCAHead'}>
                    <Box component='div' className={'BBPCAHTitle'}>
                        {pathWithoutLastPart === `/${MENU_SLUG.commissions}/edit` ? 'Edit Commissions' : 'Add Commissions'}
                    </Box>
                </Box>
                <Box component='div' className={'BBPCABody'}>
                    <Box component='div' className={'BBPCABPannel'}>
                        <Box component='div' className={'BBPCABPInner'}>
                            {categories.dataLoading || loading ?
                                <Box component='div' className={'BBPCABPIFields'}>
                                    {Array.from(Array(4).keys()).map((item) => (
                                        <Box component='div' key={item} className={'BBPCABPIFField'}>
                                            <Skeleton variant="rectangular" className={'BBPLabel'} height={22} />
                                            <Skeleton variant="rectangular" height={55} />
                                        </Box>
                                    ))}
                                </Box>
                                :
                                <Box component='div' className={'BBPCABPIFields'}>
                                    <Box component='div' className={'BBPForm'}>
                                        <Form
                                            schema={schema}
                                            uiSchema={uiSchema}
                                            widgets={widgets}
                                            formData={formData}
                                            validator={validator}
                                            showErrorList={false}
                                            omitExtraData={true}
                                            liveValidate={liveValidErr}
                                            transformErrors={transformErrors}
                                            onError={onError}
                                            templates={{ ButtonTemplates: { AddButton, RemoveButton } }}
                                            onChange={({ formData }) => {
                                                onFormChange(formData);
                                            }}
                                            onSubmit={({ formData }) => {
                                                onFormSubmit(formData);
                                            }}
                                            ref={(form) => { yourForm = form; }}
                                        />
                                    </Box>
                                </Box>
                            }
                        </Box>
                        {duplicateBiller.error &&
                            <Box component='div' className={'BBPBABPErr'}>
                                {duplicateBiller.msg}
                            </Box>}
                        <Box component='div' className={'BBPBABPBtns'}>
                            <Button className={'BBPButton'} onClick={handleCancel}>Cancel</Button>
                            {pathWithoutLastPart === `/${MENU_SLUG.commissions}/edit` ?
                                <Button disabled={categories.dataLoading} className={'BBPButton'} onClick={onSubmitNew}>Update</Button>
                                :
                                <Button disabled={categories.dataLoading} className={'BBPButton'} onClick={onSubmitNew}>Submit</Button>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Fragment>
    );
};

export default AddCommission; 