import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import './style.css';

const MonthYear = (props) => {
    const { options, disabled, onChange, id, readonly, defaultValue, value } = props;

    const { dateformat } = options;
    const [allYears, setAllYears] = useState([]);
    const [allMonths] = useState(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']);
    const [monthDisabled, setMonthDisabled] = useState(true);
    const [selectedMonth, setSelectedMonth] = useState('none');
    const [selectedYear, setSelectedYear] = useState('none');

    const handleSelectYear = (e) => {
        if (e.target.value === 'none') {
            setSelectedYear('');
            setMonthDisabled(true);
        } else {
            setSelectedYear(e.target.value);
            setMonthDisabled(false);
        }
    }

    const handleSelectMonth = (e) => {
        let month = e.target.value;
        setSelectedMonth(`${month}`);
        if (month !== 'none') {
            if (dateformat === 'yyyy-mm') {
                onChange(`${selectedYear}-${month}`);
            } else {
                onChange(`${month}-${selectedYear}`);
            }
        }
    }

    useEffect(() => {
        let tempYear = [];
        let endYear = new Date().getFullYear();
        for (var year = endYear; year >= 1900; year--) {
            tempYear.push(year.toString());
        }
        setAllYears(tempYear);
    }, [])

    useEffect(() => {
        if (value) {
            let month = value.substr(0, value.indexOf('-'));
            let year = value.substring(value.indexOf('-') + 1);
            if ((month.toString().length) === 1) {
                setSelectedMonth(`0${month}`);
            } else {
                setSelectedMonth(`${month}`);
            }
            setSelectedYear(year.toString());
            setMonthDisabled(false);
        }
        // eslint-disable-next-line  
    }, []);

    useEffect(() => {
        if (defaultValue) {
            let month = defaultValue.substr(0, defaultValue.indexOf('-'));
            let year = defaultValue.substring(defaultValue.indexOf('-') + 1);
            // console.log('month', month.toString())
            if ((month.toString().length) === 1) {
                setSelectedMonth(`0${month}`);
            } else {
                setSelectedMonth(`${month}`);
            }
            setSelectedYear(year);
            setMonthDisabled(false);
        }
        // eslint-disable-next-line  
    }, []);

    return (
        <Box component="div" className={'BBPMonthYear'}>
            <select
                id={id}
                disabled={disabled}
                readOnly={readonly}
                onChange={handleSelectYear}
                value={selectedYear}
            >
                <option value={'none'}>Select Year</option>
                {allYears.map((year) =>
                    <option value={year} key={year}>{year}</option>
                )}
            </select>
            <select
                disabled={disabled || monthDisabled}
                readOnly={readonly}
                onChange={handleSelectMonth}
                value={selectedMonth}
            >
                <option value={'none'}>Select Month</option>
                {allMonths.map((month, i) =>
                    <option
                        key={i}
                        value={(i + 1) < 10 ? `0${(i + 1)}` : `${(i + 1)}`}
                    >{month}</option>
                )}
            </select>
        </Box>
    );
}

export default MonthYear;