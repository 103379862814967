import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import { customizeValidator } from "@rjsf/validator-ajv6";
import Form from "@rjsf/core";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import SuccessDialog from '../../Components/SuccessDialog';
import { MENU_SLUG } from '../../Constants/constants';
import Backdrops from '../../Components/Backdrops';
import FileUpload from '../../Components/FormFields/FileUploadNew';
import SomethingWentWrong from '../../Components/SomethingWentWrong';
import { dataUrlToFile } from '../../Utils/index';
import { useAuth } from '../../Hooks/useAuth';

import './style.css';

const AddUser = () => {

    const { auth } = useAuth();
    let { user_id } = useParams();

    const schema = {
        "type": "object",
        "required": ["email", "password", "name", "phone", "dob"],
        "properties": {
            "email": {
                "title": "Email ID",
                "type": "string",
                "format": "email"
            },
            "password": {
                "title": "Password",
                "type": "string"
            },
            "name": {
                "title": "Full Name",
                "type": "string"
            },
            "phone": {
                "title": "Mobile Number",
                "type": "string",
                "format": 'Number',
                "minLength": 10,
                "maxLength": 10
            },
            "dob": {
                "title": "Date of Birth",
                "type": "string",
                "format": "date",
            },
            "status": {
                "title": "Status",
                "enum": [
                    "Yes",
                    "No"
                ],
                "default": "Yes"
            },
            "file": {
                "title": "Profile Image",
                "type": "string",
            },
            "upi_login": auth.role.includes('Admin') ? {
                "type": "object",
                "title": "For Upi Report",
                "properties": {
                    "username": {
                        "title": "User Name",
                        "type": "string",
                    },
                    "password": {
                        "title": "Password",
                        "type": "string"
                    },
                },
            } : {},
            "permissions": {
                "type": "object",
                "title": "User Permissions",
                "properties": {
                    "dashboard_permissions": {
                        "type": "array",
                        "title": "Dashboard",
                        "items": {
                            "type": "string",
                            "enum": [
                                "View"
                            ]
                        },
                        "uniqueItems": true
                    },
                    "bill_payment_permissions": {
                        "type": "array",
                        "title": "Bill Payment",
                        "items": {
                            "type": "string",
                            "enum": [
                                "View"
                            ]
                        },
                        "uniqueItems": true
                    },
                    "reports_permissions": {
                        "type": "array",
                        "title": "Reports",
                        "items": auth.role.includes('Admin') ? {
                            "type": "string",
                            "enum": [
                                "Transaction",
                                "Commission",
                                "UPI",
                                "ICICI"
                            ]
                        } : {
                            "type": "string",
                            "enum": [
                                "Transaction",
                                "Commission",
                            ]
                        },
                        "uniqueItems": true
                    },
                    "billers_permissions": {
                        "type": "array",
                        "title": "Billers",
                        "items": {
                            "type": "string",
                            "enum": [
                                "View",
                                "Add",
                                "Edit",
                                "Delete",
                                "Bulk Upload"
                            ]
                        },
                        "uniqueItems": true
                    },
                    "users_permissions": {
                        "type": "array",
                        "title": "Users",
                        "items": {
                            "type": "string",
                            "enum": [
                                "View",
                                "Add",
                                "Edit",
                                "Delete",
                                "Change Password"
                            ]
                        },
                        "uniqueItems": true
                    },
                    "commissions_permissions": {
                        "type": "array",
                        "title": "Commissions",
                        "items": {
                            "type": "string",
                            "enum": [
                                "View",
                                "Add",
                                "Edit",
                                "Delete",
                                "Bulk Upload"
                            ]
                        },
                        "uniqueItems": true
                    },
                    "complaints_permissions": {
                        "type": "array",
                        "title": "Complaints",
                        "items": {
                            "type": "string",
                            "enum": [
                                "View",
                                "Edit"
                            ]
                        },
                        "uniqueItems": true
                    }
                },
            }
        },
    };

    const customFormats = {
        'Number': /^[0-9]+$/,
    };

    function transformErrors(errors) {
        return errors.map(error => {
            let property;
            let matcheSB = (/\[(.*?)\]/).test(error.property);
            let matcheDot = /\./.test(error.property);
            if (matcheDot) {
                property = error.property.replaceAll('.', '');
            }
            if (matcheSB) {
                // eslint-disable-next-line
                property = error.property.replace(/[\[\]']+/g, '');
            }
            if (error.name === 'minLength') {
                error.message = `‘${schema.properties[property].title}’ should NOT be shorter than 10 characters.`;
            } else if (error.name === 'maxLength') {
                error.message = `‘${schema.properties[property].title}’ should NOT be longer than 10 characters.`;
            } else if (error.name === 'pattern') {
                error.message = `‘${schema.properties[property].title}’ is not valid.`;
            } else {
                error.message = `‘${schema.properties[property].title}’ is mandatory.`;
            }
            return error;
        });
    }

    const validator = customizeValidator({ customFormats });

    let navigate = useNavigate();
    const location = useLocation();

    const pathWithoutLastPart = location.pathname.slice(0, location.pathname.lastIndexOf("/"));
    const axiosPrivate = useAxiosPrivate();

    let widgets = {
        fileUpload: FileUpload,
    }

    const [imageLocation, setImageLocation] = useState('');
    const [upiLogin, setUpiLogin] = useState(true);
    const [liveValidErr, setLiveValidErr] = useState(false);


    function customValidate(formData, errors) {
        let dobYear = new Date(formData.dob).getFullYear();
        let curYear = new Date().getFullYear();
        if (dobYear <= 1990 && dobYear >= curYear) {
            errors.dob.addError(`Year should be between 1990 to ${new Date().getFullYear()}`);
        }
        if (!upiLogin) {
            if (formData.upi_login.username === undefined) {
                errors.upi_login.username.addError("‘User Name’ Is Mandatory.");
            }
            if (formData.upi_login.password === undefined) {
                errors.upi_login.password.addError("‘Password’ Is Mandatory.");
            }
        }
        return errors;
    }

    const uiSchema = {
        "ui:submitButtonOptions": {
            "props": {
                "className": "BBPFBtn"
            }
        },
        "file": {
            "ui:classNames": "BBPFUTab",
            "ui:widget": "fileUpload",
            "ui:options": {
                accept: ".png, .jpeg, .jpg",
                imageLocation: imageLocation
            },
        },
        "dob": {
            "ui:options": {
                "yearsRange": [
                    1990,
                    2030
                ]
            }
        },
        "password": {
            "ui:widget": pathWithoutLastPart === `/${MENU_SLUG.users}/edit` ? "hidden" : 'password',
        },
        "upi_login": {
            "ui:options": {
                "classNames": "BBPUpiLg",
            },
            'ui:disabled': upiLogin,
            'ui:hideError': upiLogin,
        },
        "permissions": {
            "ui:options": {
                "classNames": "BBPUserPer",
            },
            "dashboard_permissions": {
                "ui:widget": "checkboxes"
            },
            "bill_payment_permissions": {
                "ui:widget": "checkboxes"
            },
            "reports_permissions": {
                "ui:widget": "checkboxes"
            },
            "billers_permissions": {
                "ui:widget": "checkboxes"
            },
            "users_permissions": {
                "ui:widget": "checkboxes"
            },
            "commissions_permissions": {
                "ui:widget": "checkboxes"
            },
            "complaints_permissions": {
                "ui:widget": "checkboxes"
            }
        }
    };

    const [formData, setFormData] = useState({});

    const [errorDialog, setErrorDialog] = useState(false);

    const [submitDialog, setSubmitDialog] = useState(false);
    const [onSubmitLoading, setOnSubmitLoading] = useState(false);

    const [loading, setSetLoading] = useState(false);
    const [duplicateUser, setDuplicateUser] = useState({ error: false, msg: '' });

    const handleCancel = () => {
        navigate(`/${MENU_SLUG.users}`, { replace: true });
    };

    let yourForm;

    const onFormSubmit = (formData) => {
        setFormData(formData);
        setLiveValidErr(false);
        let tempData = { ...formData };
        if (tempData.file) {
            tempData.file = dataUrlToFile(tempData.file, 'bbp');
        }
        if (tempData.upi_login) {
            tempData.upi_login = JSON.stringify(tempData.upi_login);
        }
        if (tempData.permissions) {
            tempData.permissions = JSON.stringify(tempData.permissions);
        }
        if (pathWithoutLastPart === `/${MENU_SLUG.users}/edit`) {
            handleUpdateUser(tempData);
        } else {
            handleCreateUser(tempData);
        }
    };

    const onError = () => {
        setLiveValidErr(true);
    };

    const onFormChange = (formData) => {
        setFormData(formData);
        if (formData.permissions.reports_permissions) {
            let upiPer = formData.permissions.reports_permissions.includes("UPI");
            if (upiPer) {
                setUpiLogin(false);
            } else {
                setUpiLogin(true);
            }
        }
    };

    const handleCreateUser = useCallback(async (data) => {
        try {
            setOnSubmitLoading(true);
            let url = `users`;
            let options = {
                method: 'POST',
                url,
                data,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setSubmitDialog(true);
                    setOnSubmitLoading(false);
                    setDuplicateUser({ error: false, msg: "" });
                } else {
                    setOnSubmitLoading(false);
                    setDuplicateUser({ error: true, msg: response.data.message });
                }
            }).catch(err => {
                if (err.response) {
                    setErrorDialog(true);
                    setOnSubmitLoading(false);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setErrorDialog(true);
            setOnSubmitLoading(false);
            // console.error('error', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleUpdateUser = useCallback(async (data) => {
        try {
            setOnSubmitLoading(true);
            let url = `users/${user_id}`;
            let options = {
                method: 'PUT',
                url,
                data,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setSubmitDialog(true);
                    setOnSubmitLoading(false);
                    setDuplicateUser({ error: false, msg: "" });
                } else {
                    setOnSubmitLoading(false);
                    setDuplicateUser({ error: true, msg: response.data.message });
                    // console.error('err.res', response);
                }
            }).catch(err => {
                if (err.response) {
                    setOnSubmitLoading(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setOnSubmitLoading(false);
            setErrorDialog(true);
            // console.error('error', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user_id]);

    const onSubmitNew = () => {
        yourForm.formElement.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    };

    const fetchUserValue = useCallback(async () => {
        setSetLoading(true);
        try {
            let url = `users/${user_id}`;
            let options = {
                method: 'GET',
                url
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    let tempData = {};
                    tempData.name = response.data.data.name;
                    tempData.email = response.data.data.email;
                    tempData.phone = response.data.data.phone;
                    tempData.dob = response.data.data.dob;
                    tempData.status = response.data.data.status ? "Yes" : "No";
                    if (auth.role.includes('Admin')) {
                        tempData.upi_login = JSON.parse(response.data.data.upi_login);
                    }
                    tempData.permissions = JSON.parse(response.data.data.permissions);
                    setImageLocation(response.data.data.image.location);
                    setFormData(tempData);
                    setSetLoading(false);
                } else {
                    setSetLoading(false);
                }
            }).catch(err => {
                if (err.response) {
                    setSetLoading(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setSetLoading(false);
            setErrorDialog(true);
            // console.error('error', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user_id]);

    const handleSubmitDialogClose = () => {
        setSubmitDialog(false);
        navigate(`/${MENU_SLUG.users}`, { replace: true });
    };

    useEffect(() => {
        if (pathWithoutLastPart === `/${MENU_SLUG.users}/edit`) {
            fetchUserValue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Fragment>
            <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
            <Backdrops
                open={onSubmitLoading}
                title={pathWithoutLastPart === `/${MENU_SLUG.users}/edit` ? 'Updating' : 'Saving'}
            />
            <SuccessDialog
                open={submitDialog}
                onClose={handleSubmitDialogClose}
                title={pathWithoutLastPart === `/${MENU_SLUG.users}/edit` ? 'User updated successfully' : 'User added successfully'}
                message={pathWithoutLastPart === `/${MENU_SLUG.users}/edit` ? 'User has been updated successfully. Now you can initiate User for the added user.' : 'User has been added successfully. Now you can initiate User for the added user.'}
                buttonTitle={'Close'}
            />
            <Box component='div' className={'BBPUserAdd'}>
                <Box component='div' className={'BBPUAHead'}>
                    <Box component='div' className={'BBPUAHTitle'}>
                        {pathWithoutLastPart === `/${MENU_SLUG.users}/edit` ? 'Edit User' : 'Add User'}
                    </Box>
                </Box>
                <Box component='div' className={'BBPUABody'}>
                    <Box component='div' className={'BBPUABPannel'}>
                        <Box component='div' className={'BBPUABPInner'}>
                            {loading ?
                                <Box component='div' className={'BBPUABPIFields'}>
                                    {Array.from(Array(6).keys()).map((item) => (
                                        <Box component='div' key={item} className={'BBPCABPIFField'}>
                                            <Skeleton variant="rectangular" className={'BBPLabel'} height={22} />
                                            <Skeleton variant="rectangular" height={55} />
                                        </Box>
                                    ))}
                                </Box>
                                :
                                <Box component='div' className={'BBPUABPIFields'}>
                                    <Box component='div' className={'BBPForm'}>
                                        <Form
                                            schema={schema}
                                            uiSchema={uiSchema}
                                            formData={formData}
                                            validator={validator}
                                            widgets={widgets}
                                            liveValidate={liveValidErr}
                                            showErrorList={false}
                                            omitExtraData={true}
                                            transformErrors={transformErrors}
                                            customValidate={customValidate}
                                            onError={onError}
                                            onSubmit={({ formData }) => {
                                                onFormSubmit(formData);
                                            }}
                                            onChange={({ formData }) => {
                                                onFormChange(formData);
                                            }}
                                            ref={(form) => { yourForm = form; }}
                                        />
                                    </Box>
                                </Box>
                            }
                        </Box>
                        {duplicateUser.error &&
                            <Box component='div' className={'BBPUABPErr'}>
                                {duplicateUser.msg}
                            </Box>}
                        <Box component='div' className={'BBPUABPBtns'}>
                            <Button className={'BBPButton'} onClick={handleCancel}>Cancel</Button>
                            {pathWithoutLastPart === `/${MENU_SLUG.users}/edit` ?
                                <Button disabled={loading} className={'BBPButton'} onClick={onSubmitNew}>Update</Button>
                                :
                                <Button disabled={loading} className={'BBPButton'} onClick={onSubmitNew}>Submit</Button>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Fragment>
    );
};

export default AddUser; 