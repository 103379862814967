import React, { useState, useCallback, useEffect, Fragment } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { format } from 'date-fns'
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import SearchBox from '../../Components/SearchBox';
import DevTable from '../../Components/DevTable';
import SomethingWentWrong from '../../Components/SomethingWentWrong';
import ViewCompliant from '../../Components/RVEComplaint/viewComplaint';
import UpdateCompliant from '../../Components/RVEComplaint/updateComplaint';
import { CheckPermissions } from '../../Utils/permissions';
import './style.css';

const ComplaintTable = () => {

    const axiosPrivate = useAxiosPrivate();
    let editPermissions = CheckPermissions('complaints_permissions', 'Edit');

    const [errorDialog, setErrorDialog] = useState(false);
    const [viewCompliant, setViewCompliant] = useState(false);
    const [compliantInfo, setCompliantInfo] = useState({});
    const [updateCompliant, setUpdateCompliant] = useState(false);
    const [updateCompliantInfo, setUpdateCompliantInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const [totalData, setTotalData] = useState(0);
    const [selectedPage, setSelectedPage] = useState(1);
    const [limitData] = useState(20);
    const [rowData, setRowData] = useState([]);
    const [apiParams] = useState({ limit: limitData });
    const [mainColumns, setMainColumns] = useState({
        columns: [
            {
                name: 'date',
                title: 'Date',
                width: 100,
                sorting: true,
                direction: ''
            },
            {
                name: 'transaction_provider',
                title: 'Transaction Provider',
                width: 100,
                sorting: true,
                direction: ''
            },
            {
                name: 'ComplaintId',
                title: 'Complaint ID',
                width: 100,
                sorting: true,
                direction: ''
            },
            {
                name: 'mobile_num',
                title: 'Mobile Number',
                width: 150,
                sorting: false,
                direction: ''
            },
            {
                name: 'reason',
                title: 'Reason',
                width: 250,
                sorting: false,
                direction: ''
            },
            {
                name: 'description',
                title: 'Description',
                width: 250,
                sorting: false,
                direction: ''
            },
            {
                name: 'status',
                title: 'Status',
                width: 100,
                sorting: true,
                direction: ''
            },
            {
                name: 'action',
                title: 'Actions',
                width: 150,
                sorting: false,
                direction: ''
            },
        ]
    });

    const fetchComplaints = useCallback(async (params) => {
        try {
            let urlParams = '';
            if (params) {
                Object.keys(params).forEach(function (key, index) {
                    urlParams += (index === 0 ? '?' : '&') + key + '=' + params[key];
                });
            }
            let url = `complaints${urlParams}`;
            let options = {
                method: 'GET',
                url
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setRowData(response.data.data);
                    setTotalData(response.data.total);
                    setLoading(false);
                } else {
                    setRowData([]);
                    setTotalData(0);
                    setLoading(false);
                }
            }).catch(err => {
                if (err.response) {
                    setLoading(false);
                    setErrorDialog(true);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setLoading(false);
            setErrorDialog(true);
            // console.error('error', error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearch = useCallback((value) => {
        setLoading(true);
        let prm = apiParams;
        setSelectedPage(1);
        prm.page = 1;
        if (value.length > 0) {
            prm.search = value;
        } else {
            delete prm.search;
        }
        fetchComplaints(prm);
    }, [apiParams, fetchComplaints]);

    const handlePaginate = (value) => {
        let prm = apiParams;
        prm.page = value;
        setSelectedPage(value);
        setLoading(true);
        fetchComplaints(prm);
    };

    const handleSorting = (name, index) => {
        let prm = apiParams;
        let tempCol = mainColumns.columns;
        if (tempCol[index].direction === '') {
            tempCol.map((column) => (column.name === name ? column.direction = 'ASC' : column.direction = ''));
            prm.sort_by = name;
            prm.order = 1;
            prm.page = 1;
        } else if (tempCol[index].direction === 'ASC') {
            tempCol.map((column) => (column.name === name ? column.direction = 'DSC' : column.direction = ''));
            prm.sort_by = name;
            prm.order = -1;
            prm.page = 1;
        } else if (tempCol[index].direction === 'DSC') {
            tempCol.map((column) => column.direction = '');
            prm.page = 1;
            delete prm.sort_by;
            delete prm.order;
        }
        setLoading(true);
        setMainColumns({ columns: tempCol });
        setSelectedPage(1);
        fetchComplaints(prm);
    }

    const ViewCompliantOpen = (info) => {
        setCompliantInfo(info);
        setViewCompliant(true);
    };

    const ViewCompliantClose = () => {
        setCompliantInfo({});
        setViewCompliant(false);
    };

    const UpdateCompliantOpen = (info) => {
        setUpdateCompliantInfo(info);
        setUpdateCompliant(true);
    };

    const UpdateCompliantClose = () => {
        setUpdateCompliantInfo({});
        setUpdateCompliant(false);
        fetchComplaints(apiParams);
    };

    function generateRows(tempArray) {
        const tempRowArray = [];
        if (tempArray) {
            tempArray.map((item) =>
                tempRowArray.push({
                    date: (<Box component="div" className="BBPDTSText">{format(new Date(item.date), "dd-MM-yyyy")}</Box>),
                    ComplaintId: (<Box component="div" className="BBPDTSText">{item.ComplaintId}</Box>),
                    transaction_provider: (<Box component="div" className="BBPDTSTS">
                        <Box component="div" className="BBPDTSTSText">{item.transaction_provider}</Box>
                        {item.transaction_provider === 'Finacus' ? item.finacus_data.ComplaintStatus && <Box component="div" className="BBPDTSTSS">{item.finacus_data.ComplaintStatus}</Box> : ''}</Box>),
                    mobile_num: (<Box component="div" className="BBPDTSText">{item.mobile_num}</Box>),
                    reason: (<Box component="div" className="BBPDTSText">{item.reason}</Box>),
                    description: (<Box component="div" className="BBPDTSText">{item.description}</Box>),
                    status: (<Box component="div" className="BBPDTChips"><Box component="div" className={"BBPDTCChip " + item.status.replaceAll(/\s/g, '')}>{item.status}</Box></Box>),
                    action: (<Box component="div" className="BBPDTIBtns">
                        <Tooltip
                            placement="top"
                            classes={{
                                popper: 'BBPTPopper',
                                tooltip: 'BBPTooltip'
                            }}
                            title={'View Complaint'}
                        >
                            <IconButton size="small" className="BBPDTIBIcon" onClick={() => { ViewCompliantOpen(item) }}>
                                <RemoveRedEyeOutlinedIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                        {editPermissions &&
                            <Fragment>
                                {item.status !== 'Completed' ?
                                    <Tooltip
                                        placement="top"
                                        classes={{
                                            popper: 'BBPTPopper',
                                            tooltip: 'BBPTooltip'
                                        }}
                                        title={'Update Complaint'}
                                    >
                                        <IconButton size="small" className="BBPDTIBIcon" onClick={() => { UpdateCompliantOpen(item) }}>
                                            <EditOutlinedIcon fontSize="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                    :
                                    <Tooltip
                                        placement="top"
                                        classes={{
                                            popper: 'BBPTPopper',
                                            tooltip: 'BBPTooltip'
                                        }}
                                        title={'Your Complaint is solved.'}
                                    >
                                        <IconButton size="small" className="BBPDTIBIcon BBPDTIBDes">
                                            <EditOutlinedIcon fontSize="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </Fragment>
                        }
                    </Box>),
                }),
            );
        }
        return tempRowArray;
    }

    useEffect(() => {
        fetchComplaints(apiParams);
    }, [apiParams, fetchComplaints])

    return (
        <Fragment>
            <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
            <ViewCompliant
                open={viewCompliant}
                onClose={ViewCompliantClose}
                info={compliantInfo}
            />
            <UpdateCompliant
                open={updateCompliant}
                onClose={UpdateCompliantClose}
                info={updateCompliantInfo}
            />
            <Box component='div' className={'BBPComplaint'}>
                <Box component='div' className={'BBPCHead'}>
                    <SearchBox
                        onSearchChange={handleSearch}
                        placeholder={'Search Complaints'}
                        searchTooltip={'Searching from Transaction Provider, Mobile Number and Complaint ID'}
                    />
                </Box>
                <Box component='div' className={'BBPCBody'}>
                    <DevTable
                        rows={generateRows(rowData)}
                        columns={mainColumns.columns}
                        selectedPage={selectedPage}
                        handlePagination={handlePaginate}
                        loading={loading}
                        handleSort={handleSorting}
                        limitData={limitData}
                        totalData={totalData}
                        noDataTitle={'No complaints raised yet.'}
                        noDataDes={' '}
                    />
                </Box>
            </Box>
        </Fragment>
    );
};

export default ComplaintTable;
