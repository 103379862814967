import React, { Fragment } from 'react';
import { AreaChart, Area, ResponsiveContainer, Tooltip } from 'recharts';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import { format } from 'date-fns'
import './style.css';


const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        let date = new Date(payload[0].payload.date);
        return (
            <Box component="div" className="BBPCustomTooltip">
                <Box component="div" className="BBPCTitle">{format(date, "dd MMM yyyy")}</Box>
                <Box component="div" className="BBPCList">
                    <strong>₹{payload[0].payload.currentAmount}</strong> Current Month
                </Box>
                <Box component="div" className="BBPCList">
                    <strong>₹{payload[0].payload.lastAmount}</strong> Last Month
                </Box>
            </Box>
        );
    }

    return null;
};

const BBPAreaChart = ({ data, color, name, title, number, percentage, type, percentage_text, loading }) => {

    return (
        <Box component="div" className={'BBPAreaChart'}>
            <Box component="div" className={'BBPACMTitle'}>{loading ? <Skeleton variant="rounded" width={200} height={21} /> : title} </Box>
            <Box component="div" className={'BBPACInfo'}>
                <Box component="div" className={'BBPACIDet'}>
                    <Box component="div" className={'BBPACIDTitle'}>

                        {loading ? <Skeleton variant="rounded" width={180} height={32} /> : number}
                    </Box>
                    <Box component="div" className={'BBPACIDSubTitle'}>
                        {loading ? <Skeleton variant="rounded" width={180} height={20} /> :
                            <Fragment>
                                <Box component="div" className={'BBPACIDPer'} style={{ color: type === 'up' ? '#3DD598' : '#F0142F' }}>
                                    {percentage} {type === 'up' ? <NorthIcon fontSize="inherit" /> : <SouthIcon fontSize="inherit" />}
                                </Box>
                                <Box component="div" className={'BBPACIDPTitle'}>
                                    {percentage_text}
                                </Box>
                            </Fragment>}
                    </Box>
                </Box>
                <Box component="div" className={'BBPACIChart'}>
                    {loading ? <Skeleton variant="rounded" component="div" height={52} /> :
                        <ResponsiveContainer width="100%" height="100%">
                            <AreaChart
                                width={500}
                                height={400}
                                data={data}
                                margin={{
                                    top: 5,
                                    right: 5,
                                    left: 5,
                                    bottom: 5
                                }}
                            >
                                <defs>
                                    <linearGradient id={name} x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor={color} stopOpacity={0.8} />
                                        <stop offset="95%" stopColor={color} stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <Area dataKey="currentAmount" name={title} stroke={color} fillOpacity={1} fill={`url(#${name})`} />
                                <Tooltip position={{ y: -85 }} content={<CustomTooltip />} />
                            </AreaChart>
                        </ResponsiveContainer>
                    }
                </Box>
            </Box>
        </Box>
    );
};

export default BBPAreaChart;
