import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import './style.css';
const Confetti = () => {

    return (
        <Fragment>
            <Box component="div" className={'ConfettiWrapper'}>
                <Box component="div" className="confetti-149" />
                <Box component="div" className="confetti-148" />
                <Box component="div" className="confetti-147" />
                <Box component="div" className="confetti-146" />
                <Box component="div" className="confetti-145" />
                <Box component="div" className="confetti-144" />
                <Box component="div" className="confetti-143" />
                <Box component="div" className="confetti-142" />
                <Box component="div" className="confetti-141" />
                <Box component="div" className="confetti-140" />
                <Box component="div" className="confetti-139" />
                <Box component="div" className="confetti-138" />
                <Box component="div" className="confetti-137" />
                <Box component="div" className="confetti-136" />
                <Box component="div" className="confetti-135" />
                <Box component="div" className="confetti-134" />
                <Box component="div" className="confetti-133" />
                <Box component="div" className="confetti-132" />
                <Box component="div" className="confetti-131" />
                <Box component="div" className="confetti-130" />
                <Box component="div" className="confetti-129" />
                <Box component="div" className="confetti-128" />
                <Box component="div" className="confetti-127" />
                <Box component="div" className="confetti-126" />
                <Box component="div" className="confetti-125" />
                <Box component="div" className="confetti-124" />
                <Box component="div" className="confetti-123" />
                <Box component="div" className="confetti-122" />
                <Box component="div" className="confetti-121" />
                <Box component="div" className="confetti-120" />
                <Box component="div" className="confetti-119" />
                <Box component="div" className="confetti-118" />
                <Box component="div" className="confetti-117" />
                <Box component="div" className="confetti-116" />
                <Box component="div" className="confetti-115" />
                <Box component="div" className="confetti-114" />
                <Box component="div" className="confetti-113" />
                <Box component="div" className="confetti-112" />
                <Box component="div" className="confetti-111" />
                <Box component="div" className="confetti-110" />
                <Box component="div" className="confetti-109" />
                <Box component="div" className="confetti-108" />
                <Box component="div" className="confetti-107" />
                <Box component="div" className="confetti-106" />
                <Box component="div" className="confetti-105" />
                <Box component="div" className="confetti-104" />
                <Box component="div" className="confetti-103" />
                <Box component="div" className="confetti-102" />
                <Box component="div" className="confetti-101" />
                <Box component="div" className="confetti-100" />
                <Box component="div" className="confetti-99" />
                <Box component="div" className="confetti-98" />
                <Box component="div" className="confetti-97" />
                <Box component="div" className="confetti-96" />
                <Box component="div" className="confetti-95" />
                <Box component="div" className="confetti-94" />
                <Box component="div" className="confetti-93" />
                <Box component="div" className="confetti-92" />
                <Box component="div" className="confetti-91" />
                <Box component="div" className="confetti-90" />
                <Box component="div" className="confetti-89" />
                <Box component="div" className="confetti-88" />
                <Box component="div" className="confetti-87" />
                <Box component="div" className="confetti-86" />
                <Box component="div" className="confetti-85" />
                <Box component="div" className="confetti-84" />
                <Box component="div" className="confetti-83" />
                <Box component="div" className="confetti-82" />
                <Box component="div" className="confetti-81" />
                <Box component="div" className="confetti-80" />
                <Box component="div" className="confetti-79" />
                <Box component="div" className="confetti-78" />
                <Box component="div" className="confetti-77" />
                <Box component="div" className="confetti-76" />
                <Box component="div" className="confetti-75" />
                <Box component="div" className="confetti-74" />
                <Box component="div" className="confetti-73" />
                <Box component="div" className="confetti-72" />
                <Box component="div" className="confetti-71" />
                <Box component="div" className="confetti-70" />
                <Box component="div" className="confetti-69" />
                <Box component="div" className="confetti-68" />
                <Box component="div" className="confetti-67" />
                <Box component="div" className="confetti-66" />
                <Box component="div" className="confetti-65" />
                <Box component="div" className="confetti-64" />
                <Box component="div" className="confetti-63" />
                <Box component="div" className="confetti-62" />
                <Box component="div" className="confetti-61" />
                <Box component="div" className="confetti-60" />
                <Box component="div" className="confetti-59" />
                <Box component="div" className="confetti-58" />
                <Box component="div" className="confetti-57" />
                <Box component="div" className="confetti-56" />
                <Box component="div" className="confetti-55" />
                <Box component="div" className="confetti-54" />
                <Box component="div" className="confetti-53" />
                <Box component="div" className="confetti-52" />
                <Box component="div" className="confetti-51" />
                <Box component="div" className="confetti-50" />
                <Box component="div" className="confetti-49" />
                <Box component="div" className="confetti-48" />
                <Box component="div" className="confetti-47" />
                <Box component="div" className="confetti-46" />
                <Box component="div" className="confetti-45" />
                <Box component="div" className="confetti-44" />
                <Box component="div" className="confetti-43" />
                <Box component="div" className="confetti-42" />
                <Box component="div" className="confetti-41" />
                <Box component="div" className="confetti-40" />
                <Box component="div" className="confetti-39" />
                <Box component="div" className="confetti-38" />
                <Box component="div" className="confetti-37" />
                <Box component="div" className="confetti-36" />
                <Box component="div" className="confetti-35" />
                <Box component="div" className="confetti-34" />
                <Box component="div" className="confetti-33" />
                <Box component="div" className="confetti-32" />
                <Box component="div" className="confetti-31" />
                <Box component="div" className="confetti-30" />
                <Box component="div" className="confetti-29" />
                <Box component="div" className="confetti-28" />
                <Box component="div" className="confetti-27" />
                <Box component="div" className="confetti-26" />
                <Box component="div" className="confetti-25" />
                <Box component="div" className="confetti-24" />
                <Box component="div" className="confetti-23" />
                <Box component="div" className="confetti-22" />
                <Box component="div" className="confetti-21" />
                <Box component="div" className="confetti-20" />
                <Box component="div" className="confetti-19" />
                <Box component="div" className="confetti-18" />
                <Box component="div" className="confetti-17" />
                <Box component="div" className="confetti-16" />
                <Box component="div" className="confetti-15" />
                <Box component="div" className="confetti-14" />
                <Box component="div" className="confetti-13" />
                <Box component="div" className="confetti-12" />
                <Box component="div" className="confetti-11" />
                <Box component="div" className="confetti-10" />
                <Box component="div" className="confetti-9" />
                <Box component="div" className="confetti-8" />
                <Box component="div" className="confetti-7" />
                <Box component="div" className="confetti-6" />
                <Box component="div" className="confetti-5" />
                <Box component="div" className="confetti-4" />
                <Box component="div" className="confetti-3" />
                <Box component="div" className="confetti-2" />
                <Box component="div" className="confetti-1" />
                <Box component="div" className="confetti-0" />
            </Box>
        </Fragment>
    );
};

export default Confetti; 