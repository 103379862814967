import React, { Fragment, useState, useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import BBPAreaChart from './areaChart';
import SomethingWentWrong from '../../Components/SomethingWentWrong';
import { format, addMonths, addDays } from 'date-fns';

const getPercentage = (current, last) => {
    if (current > last) {
        return (((current - last) / current * 100));
    } else if (current < last) {
        return (((current - last) / last * 100));
    } else {
        return 0;
    }
}

const getStatusChartData = (currentData, lastData) => {
    let startDate = addDays(addMonths(new Date(), -1), 1);
    let endDate = new Date();
    let allDates = [];

    const uniqueCDates = Object.values(
        currentData.reduce((acc, item) => {
            acc[item.date] = acc[item.date]
                ? { ...item, currentAmount: item.currentAmount + acc[item.date].currentAmount }
                : item;
            return acc;
        }, {})
    );

    const uniqueLDates = Object.values(
        lastData.reduce((acc, item) => {
            acc[item.date] = acc[item.date]
                ? { ...item, lastAmount: item.lastAmount + acc[item.date].lastAmount }
                : item;
            return acc;
        }, {})
    );
    for (var day = startDate; day <= endDate; day.setDate(day.getDate() + 1)) {
        let newDate = format(day, "yyyy-MM-dd");
        const cIndex = uniqueCDates.findIndex(object => {
            return object.date === newDate;
        });
        const lIndex = uniqueLDates.findIndex(object => {
            return object.date === newDate;
        });
        allDates.push({
            date: newDate,
            currentAmount: cIndex !== -1 ? uniqueCDates[cIndex].currentAmount : 0,
            lastAmount: lIndex !== -1 ? uniqueLDates[lIndex].lastAmount : 0
        })
    }

    return allDates;
}

const dataByStatus = (arr, amtKey) => {
    let tempData = [];
    // eslint-disable-next-line array-callback-return
    arr.map((item) => {
        let tempDate = format(new Date(item.createdAt), "yyyy-MM-dd");
        tempData.push({ date: tempDate, [amtKey]: parseInt(item.transaction_provider_data.init.BillAmount || item.transaction_provider_data.init.billAmount) });
    });
    return tempData;
}

const Overview = () => {

    const axiosPrivate = useAxiosPrivate();

    const [loading, setLoading] = useState(false);
    const [errorDialog, setErrorDialog] = useState(false);
    const [statusAmount, setStatusAmount] = useState({ canceled: 0, failed: 0, pending: 0, success: 0 });
    const [lastAmount, setLastAmount] = useState({ canceled: 0, failed: 0, pending: 0, success: 0 });
    const [successData, setSuccessData] = useState([]);
    const [pendingData, setPendingData] = useState([]);
    const [failedData, setFailedData] = useState([]);
    // const [canceledData, setCanceledData] = useState([]);

    const fetchTransactionStatus = useCallback(async () => {
        try {
            setLoading(true);
            let url = `dashboard/transactionstatus`;
            let options = {
                method: 'GET',
                url
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {

                    let tempAmt = { ...statusAmount };
                    let tempLAmt = { ...lastAmount };
                    let tempSuccessData = [];
                    let tempPendingData = [];
                    let tempFailedData = [];
                    // let tempCanceledData = [];
                    let tempLSuccessData = [];
                    let tempLPendingData = [];
                    let tempLFailedData = [];
                    // let tempLCanceledData = [];
                    // eslint-disable-next-line array-callback-return
                    response.data.current.map(item => {
                        // if (item._id === 'Canceled') {
                        //     tempAmt.canceled = item.total;
                        //     tempCanceledData = dataByStatus(item.data, 'currentAmount');
                        // }
                        if (item._id === 'Failed') {
                            tempAmt.failed = item.total;
                            tempFailedData = dataByStatus(item.data, 'currentAmount');
                        }
                        if (item._id === 'Pending') {
                            tempAmt.pending = item.total;
                            tempPendingData = dataByStatus(item.data, 'currentAmount');
                        }
                        if (item._id === 'Success') {
                            tempAmt.success = item.total;
                            tempSuccessData = dataByStatus(item.data, 'currentAmount');
                        }
                    });
                    // eslint-disable-next-line array-callback-return
                    response.data.last.map(item => {
                        // if (item._id === 'Canceled') {
                        //     tempLAmt.canceled = item.total;
                        //     tempLCanceledData = dataByStatus(item.data, 'lastAmount');
                        // }
                        if (item._id === 'Failed') {
                            tempLAmt.failed = item.total;
                            tempLFailedData = dataByStatus(item.data, 'lastAmount');
                        }
                        if (item._id === 'Pending') {
                            tempLAmt.pending = item.total;
                            tempLPendingData = dataByStatus(item.data, 'lastAmount');
                        }
                        if (item._id === 'Success') {
                            tempLAmt.success = item.total;
                            tempLSuccessData = dataByStatus(item.data, 'lastAmount');
                        }
                    });

                    setStatusAmount(tempAmt);
                    setLastAmount(tempLAmt);
                    setSuccessData(getStatusChartData(tempSuccessData, tempLSuccessData));
                    setPendingData(getStatusChartData(tempPendingData, tempLPendingData));
                    setFailedData(getStatusChartData(tempFailedData, tempLFailedData));
                    // setCanceledData(getStatusChartData(tempCanceledData, tempLCanceledData));

                } else {
                    setErrorDialog(true);
                    // console.error('err.res', response.data);
                }
                setLoading(false);
            }).catch(err => {
                if (err.response) {
                    setErrorDialog(true);
                    setLoading(false);
                    // console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setErrorDialog(true);
            setLoading(false);
            // console.error('error', error)
        }
        // eslint-disable-next-line
    }, [statusAmount, lastAmount]);

    useEffect(() => {
        fetchTransactionStatus();
        // eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
            <Box component="div" className={'BBPDPTitle'}>
                Overview
            </Box>
            <Box component="div" className={'BBPDPCharts'}>
                <BBPAreaChart
                    data={successData}
                    color={"#82ca9d"}
                    name={'successful'}
                    title={'Successful'}
                    number={`₹${statusAmount.success.toFixed(2)}`}
                    percentage={`${(Math.abs(getPercentage(statusAmount.success, lastAmount.success))).toFixed(2)}%`}
                    type={getPercentage(statusAmount.success, lastAmount.success) >= 0 ? 'up' : 'down'}
                    percentage_text={'Than Last 30 Days'}
                    loading={loading}
                />
                <BBPAreaChart
                    data={pendingData}
                    color={"#FFC700"}
                    name={'pending'}
                    title={'Pending'}
                    number={`₹${statusAmount.pending.toFixed(2)}`}
                    percentage={`${(Math.abs(getPercentage(statusAmount.pending, lastAmount.pending))).toFixed(2)}%`}
                    type={getPercentage(statusAmount.success, lastAmount.success) >= 0 ? 'up' : 'down'}
                    percentage_text={'Than Last 30 Days'}
                    loading={loading}
                />
                <BBPAreaChart
                    data={failedData}
                    color={"#ee2505"}
                    name={'failed'}
                    title={'Failed'}
                    number={`₹${statusAmount.failed.toFixed(2)}`}
                    percentage={`${(Math.abs(getPercentage(statusAmount.failed, lastAmount.failed))).toFixed(2)}%`}
                    type={getPercentage(statusAmount.success, lastAmount.success) >= 0 ? 'up' : 'down'}
                    percentage_text={'Than Last 30 Days'}
                    loading={loading}
                />
                {/* <BBPAreaChart
                    data={canceledData}
                    color={"#d32f2f"}
                    name={'canceled'}
                    title={'Canceled'}
                    number={`₹${statusAmount.canceled}`}
                    percentage={`${Math.abs(getPercentage(statusAmount.canceled, lastAmount.canceled))}%`}
                    type={getPercentage(statusAmount.success, lastAmount.success) >= 0 ? 'up' : 'down'}
                    percentage_text={'Than Last 30 Days'}
                    loading={loading}
                /> */}
            </Box>
        </Fragment>
    );
};

export default Overview;
