import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import './style.css';

const YearField = (props) => {
    const { options, disabled, onChange, id, readonly, defaultValue, value } = props;

    const { yearFormat } = options;
    const [allYears, setAllYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState('none');

    const handleSelectYear = (e) => {
        let year = e.target.value;
        setSelectedYear(year);
        if (year !== 'none') {
            if (yearFormat === 'YYYY-YY') {
                onChange(`${year}-${year.slice(2)}`);
            } else {
                onChange(`${year}`);
            }
        }
    }

    useEffect(() => {
        let tempYear = [];
        let endYear = new Date().getFullYear();
        for (var year = endYear; year >= 1900; year--) {
            tempYear.push(year.toString());
        }
        setAllYears(tempYear);
    }, [])

    useEffect(() => {
        if (value) {
            setSelectedYear(value);
        }
        // eslint-disable-next-line  
    }, []);

    useEffect(() => {
        if (defaultValue) {
            setSelectedYear(defaultValue);
        }
        // eslint-disable-next-line  
    }, []);

    return (
        <Box component="div" className={'BBPYearField'}>
            <select
                id={id}
                disabled={disabled}
                readOnly={readonly}
                onChange={handleSelectYear}
                value={selectedYear}
            >
                <option value={'none'}>Select Year</option>
                {allYears.map((year) =>
                    <option value={year} key={year}>{year}</option>
                )}
            </select>
        </Box>
    );
}

export default YearField;